import { createAuthStore } from "@ski/auth-vue2";
import {
  ConfigurationOptions,
  configurationProvider,
} from "./lib/ConfigurationProvider";
import { tokenProvider } from "./lib/TokenProvider";
import { createAxiosInstance } from "./lib/api/axiosConfiguration";
import { SKIRepository } from "./lib/api/SKIRepository";
import { ApiService } from "./lib/api/APIService";

export async function configure(options: ConfigurationOptions) {
  configurationProvider.options = options;
  const authStore = await createAuthStore({
    ...options.auth,
    onAccessTokenChange: (token: string | null) => {
      tokenProvider.token = token;
    },
  });

  const axios = createAxiosInstance();
  const stamdataApiClient = new SKIRepository(
    new ApiService(options.api.stamdataBaseUrl, axios)
  );
  return { authStore, stamdataApiClient };
}
