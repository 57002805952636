






















































import Vue from "vue";

import Accordion from "@/lib/components/Accordion.vue";
import RichText from "@/lib/components/shared/RichText.vue";
import { formatDate } from "@/lib/dateUtilites";
import { qsParams, isEmpty } from "@/lib/utilities";

export default Vue.extend({
  components: { Accordion, RichText },
  props: {
    statusLabel: { type: String, required: true },
    dateLabel: { type: String, required: true },
    statusUpdates: { type: Array, default: null },
    toggleExpand: { type: Function, required: true },
  },
  data() {
    return { statusId: "" };
  },
  created() {
    this.statusId = qsParams().statusId;
  },
  methods: { formatDate, isEmpty },
});
