


































import Vue from "vue";
import { formatDate } from "@/lib/dateUtilites";
import {isEmpty, generateId } from "@/lib/utilities";
import SkiLink from "@/lib/components/SkiLink.vue";
import SkiTable from "@/lib/components/SkiTable.vue";

interface Data {
  agreements: any[] | null;
  ids: string;
  tableIndex: string;
}

export default Vue.extend({
  components: { SkiLink, SkiTable },
  props: {
    title: { type: String, required: true },
    agreementArray: { type: Array, default: null },
    showMoreLabel: { type: String, required: true },
    showMoreLink: { type: String, required: true },
    showMoreTarget: { type: String, default: null },
    fields: { type: Array, required: true },
    tableVariant: { type: String, required: true },
    filterVariant: { type: String, required: true },
    itemId: { type: String, required: true },
    linkItemUrl: { type: String, required: true },
    id: {type: String, default: ""}
  },
  data(): Data {
    return {
      agreements: null,
      ids: "",
      tableIndex: generateId()
    };
  },
  created() {
    const { relatedAgreements } = this.$config.agreement;
      this.agreements = this.$root.$data.item[relatedAgreements.key];
  },
  methods: {
    isEmpty,
    formatDate,
  }
});
