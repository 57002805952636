import {
  SkiAgreementRequest,
  FaqListViewDto,
  ApiService,
  StatusUpdateViewDto,
  StatusupdateListViewDto,
  AgreementDto,
  CustomerListApplicationViewDto,
  DescriptionListViewDto,
  OrganizationListViewDto,
  AgreementListViewDtoWrapper,
  StatusUpdatesRequest,
  EmployeeListViewDtoWrapper,
  StatusUpdateProgress,
  FileResponse,
} from "@/lib/api/APIService";

class AgreementRepository {
  constructor(private readonly api: ApiService) {}

  public getFAQAgreement(agreementId: string): Promise<FaqListViewDto[]> {
    return this.api.getAgreementFaqs(agreementId);
  }

  public getStatusUpdatesAgreement(
    agreementId: string
  ): Promise<StatusupdateListViewDto> {
    return this.api.getAgreementStatusUpdate(agreementId);
  }

  public get(agreementID: string, reduced = false): Promise<AgreementDto> {
    return this.api.getAgreementById(agreementID, reduced);
  }

  public getAllAgreements(
    status: string | undefined, 
    reduced = false

  ): Promise<AgreementListViewDtoWrapper> {
    return this.api.getAgreements(status, reduced);
  }

  public getCustomers(
    agreementID: string
  ): Promise<CustomerListApplicationViewDto[]> {
    return this.api.getAgreementsCustomerList(agreementID);
  }

  public getDescription(
    agreementId: string
  ): Promise<DescriptionListViewDto[]> {
    return this.api.getAgreementDescriptions(agreementId);
  }
}

class OrganizationtRepositoryID {
  constructor(private readonly api: ApiService) {}

  public get(orgID: string, reduced = false, agreementIds = []): Promise<OrganizationListViewDto> {
    return this.api.getOrganizationById(orgID, reduced, agreementIds);
  }
}

class StatusUpdateRepository {
  constructor(private readonly api: ApiService) {}

  public getStatusUpdatesForTheUser(
    id: string
  ): Promise<AgreementListViewDtoWrapper> {
    return this.api.getStatusUpdateAgreementsByUserId(id);
  }

  public getStatus(id: string): Promise<StatusUpdateProgress> {
    return this.api.getStatus(id, undefined);
  }

  public putStatus(
    id: string,
    subscribtions: StatusUpdatesRequest
  ): Promise<StatusUpdateProgress> {
    return this.api.durableStatusUpdateFunctionHttpStart(id, subscribtions);
  }

  public postStatus(
    id: string,
    subscribtions: SkiAgreementRequest
  ): Promise<void> {
    return this.api.postUserSubscriptionToStatusUpdate(id, subscribtions);
  }

  public getStatusUpdateList(id: string): Promise<StatusupdateListViewDto> {
    return this.api.getAgreementStatusUpdate(id);
  }

  public getStatusUpdate(id: string): Promise<StatusUpdateViewDto> {
    return this.api.getAgreementStatusUpdateById(id);
  }
}

class DocumentRepository {
  constructor(private readonly api: ApiService) {}

  public getDocument(id: string): Promise<FileResponse> {
    return this.api.getDocumentById(id);
  }
}

class EmployeeRepository {
  constructor(private readonly api: ApiService) {}

  public getEmployee(id: string): Promise<EmployeeListViewDtoWrapper[]> {
    return this.api.getEmployeeById(id);
  }

  public getAllEmployees(): Promise<EmployeeListViewDtoWrapper> {
    return this.api.getEmployees();
  }
}

// the one and only used by business logic
export class SKIRepository {
  constructor(private readonly api: ApiService) {}

  public get AgreementsRepository(): AgreementRepository {
    return new AgreementRepository(this.api);
  }

  public get organizationID(): OrganizationtRepositoryID {
    return new OrganizationtRepositoryID(this.api);
  }

  public get StatusUpdateRepository(): StatusUpdateRepository {
    return new StatusUpdateRepository(this.api);
  }

  public get DocumentRepository(): DocumentRepository {
    return new DocumentRepository(this.api);
  }

  public get EmployeeRepository(): EmployeeRepository {
    return new EmployeeRepository(this.api);
  }
}
