














import Vue from "vue";
import SkiModal from "./SkiModal.vue";

export default Vue.extend({
  components: { SkiModal },
  props: {
    show: { type: Boolean, default: false },
  },
  methods: {
    login(): void {
      this.$store.dispatch("signIn", window.location.href);
    },
  },
});
