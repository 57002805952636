import { AuthOptions } from "@ski/auth-vue2";

export interface ConfigurationOptions {
  auth: Omit<AuthOptions<any>, "onAccessTokenChange">;
  api: {
    stamdataBaseUrl: string;
    documentBaseUrl: string;
  };
}

class ConfigProvider {
  private _options?: ConfigurationOptions;
  public set options(options: ConfigurationOptions) {
    this._options = options;
  }

  public get auth(): ConfigurationOptions["auth"] {
    if (this._options && this._options.auth) return this._options.auth;
    throw new Error("Configuration not initialized");
  }

  public get api(): ConfigurationOptions["api"] {
    if (this._options && this._options.api) return this._options.api;
    throw new Error("Configuration not initialized");
  }
}
const configurationProvider = new ConfigProvider();

export { configurationProvider };
