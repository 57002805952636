






















import Vue from "vue";
import {
  replaceHexWithNordicLettersAndWhitespace,
  scrollToAnchorItem,
} from "@/lib/utilities";
import SkiImage from "@/lib/components/SkiImage.vue";
import RichText from "@/lib/components/shared/RichText.vue";
import { resetHashtagValue } from "../urlUtilities";

interface Data {
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagprofileId: string;
}

export default Vue.extend({
  components: { SkiImage, RichText },
  props: {
    imageUrl: { type: String, required: true },
    imageAltText: { type: String, required: true },
    name: { type: String, required: true },
    text: { type: String, required: true },
    bw: { type: Boolean, default: false },
  },
  data(): Data {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(
        window.location.hash
      ),
      hashtagItem: "",
      hashtagprofileId: "#" + this.name.toLowerCase(),
    };
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(
      this.hashtagFromWindow
    );
    if (this.hashtagItem === this.hashtagprofileId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    }
  },
  methods: {
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var profileId = this.hashtagprofileId.split("#")[1];
      scrollToAnchorItem(profileId);
    },
  },
});
