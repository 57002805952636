





















import Vue from "vue";
import Arrow from "@/lib/assets/icon/arrow.svg";

import { isDarkColor } from "@/lib/utilities";

export default Vue.extend({
  components: { Arrow },
  props: {
    variant: { type: String, default: "primary" },
    textVariant: { type: String, default: null },
    opaque: { type: Boolean, default: false },
    to: { type: String, default: "" },
    target: { type: String, default: null },
    left: { type: Boolean, default: false },
    padding: { type: String, default: 'py-3 px-4' },
    nextToAnotherBox: {type: Boolean, default: false}
  },
  computed: {
    computedTextVariant() {
      if (this.textVariant) {
        return `text-${this.textVariant}`;
      }
      return isDarkColor(this.variant) ? "text-white" : "text-light";
    }
  }
});
