







































































import Vue from "vue";
import Arrow from "@/lib/assets/icon/arrow.svg";
import SkiAspect from "@/lib/components/SkiAspect.vue";
import SkiImage from "@/lib/components/SkiImage.vue";

export default Vue.extend({
  components: { Arrow, SkiAspect, SkiImage },
  props: {
    items: { type: Array, required: true }
  }
});
