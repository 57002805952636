































import MySkiRowHeader from "@/lib/partials/my-ski/MySkiRowHeader.vue";
import RichText from "@/lib/components/shared/RichText.vue";
import Vue from "vue";

interface Data {
  showWidget: boolean;
}

export default Vue.extend({
  components: {
    MySkiRowHeader,
    RichText,
  },
  props: {
    title: { type: String, required: true },
    bodyText: { type: String, required: true },
    active: { type: Boolean, required: true },
    allowedUsers: { type: String, required: true },
    stylesheetLink: { type: String, required: true },
    widgetLink: { type: String, required: true },
    apiBaseUrlStamdata: { type: String, required: true },
    apiBaseUrlLevindrap: { type: String, required: true },
    accountantStatementTemplateUrl: { type: String, required: true },
  },
  data(): Data {
    return {
      showWidget: false,
    };
  },
  async created() {
    const orgList = this.allowedUsers.replace(/\s/g, "").split(",");

    if (this.$store.getters.isLoggedIn) {
      const user = this.$store.state.profile;
      if (!user) return;

      this.showWidget =
        this.active === true &&
        !!(orgList.includes(user.organizationId) || this.allowedUsers === "");
    }
  },
  methods: {
    getClientId(): string {
      return this.$authOptions.client_id;
    },
    getAuthority(): string {
      return this.$authOptions.authority;
    },
    getRedirectUrl(): string {
      return this.$authOptions.redirect_uri;
    },
    getRedirectLogoutUrl(): string {
      return this.$authOptions.post_logout_redirect_uri;
    },
  },
});
