


















import Vue from "vue";
import { replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem } from "@/lib/utilities";
import { resetHashtagValue } from "../urlUtilities";

interface Data {
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagInfogramId: string;
}

export default Vue.extend({
  props: {
    dataId: { type: String, required: true },
    dataType: { type: String, required: true },
    dataTitle: { type: String, required: true }
  },
  data(): Data {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagInfogramId: "#" + this.dataTitle.toLowerCase()
    };
  },
  mounted() {
    const t: any = "InfogramEmbeds";
    const val: any = window[t];
    if (val && val.initialized) {
      val.process && val.process();
    } else {
      const script = document.createElement("script");
      script.async = true;
      script.id = "infogram-async";
      script.src = "https://e.infogram.com/js/dist/embed-loader-min.js";
      document.head.appendChild(script);
    }

    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagInfogramId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    }   
  },
  methods: {
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var infogramId = this.hashtagInfogramId.split("#")[1];
      scrollToAnchorItem(infogramId);
    }
  }
});
