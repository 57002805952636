











































































import Vue, { PropType } from "vue";
import { BvEvent } from "bootstrap-vue";
import { pathWhiteHeader } from "@/lib/utilities";

import Topbar from "./Topbar.vue";
import Navbar from "./Navbar.vue";
import { OrgType } from "@/lib/utilities/orgType";
import { IProfile } from '@ski/oidc'

interface Data {
  props: object;
  dropdownOpen: boolean;
  showCollapse: boolean;
  dropdownId: string;
  didScroll: boolean;
  lastScrollPos: number;
  intervalOnScroll?: number;
  isWhiteHeader?: boolean | null;
  anchorScrollDist: number;
  sidebarItems: SidebarArray[];
  isUmbracoPreview: boolean;
}

interface Item {
  url: string;
  whiteHeader?: boolean;
  visible?: boolean;
  items?: Item[];
}

interface SidebarItem {
  url: string;
  name: string;
  items?: SidebarItem[] | null;
}

interface SidebarArray {
  role: string;
  sidebars: SidebarItem[];
}

function formatMenu({ items }: Item) {
  if (!items) return undefined;

  const formatted = items.reduce((res: Item[], item) => {
    if (!item.visible) return res;
    res.push({ ...item, items: formatMenu(item) });
    return res;
  }, []);

  if (!formatted.length) return undefined;

  return formatted;
}

export default Vue.extend({
  components: {
    Topbar,
    Navbar,
  },
  props: {
    menu: { type: Object, required: true },
    customerSidebarContent: {
      type: Array as PropType<SidebarItem[]>,
      default: null,
    },
    supplierSidebarContent: {
      type: Array as PropType<SidebarItem[]>,
      default: null,
    },
    menuLabel: { type: String, required: false },
    mySkiLabel: { type: String, required: false },
    mySkiUrl: { type: String, required: false },
    loginLabel: { type: String, required: true },
    logoutLabel: { type: String, required: true },
    logoutUrl: { type: String, required: true },
    searchLabel: { type: String, required: false },
    searchUrl: { type: String, required: false },
    organizationUrl: { type: String, required: true },
    logo: { type: String, required: true },
    logoWhite: { type: String, required: true },
    logoSm: { type: String, required: true },
    logoSmWhite: { type: String, required: true },
    logoUrl: { type: String, required: false, default: "/" },
    logoAltText: { type: String, required: true },
  },
  data(): Data {
    return {
      isWhiteHeader: this.$root.$data.IsWhiteHeader,
      props: {},
      dropdownOpen: false,
      showCollapse: false,
      dropdownId: "",
      didScroll: false,
      lastScrollPos: 0,
      intervalOnScroll: undefined,
      anchorScrollDist: 0,
      sidebarItems: [],
      isUmbracoPreview: false,
    };
  },
  watch: {
    // VueJS watch on vue-router path (only needed for Vue development)
    "$route.path"(newPath) {
      // Timeout, hence shifting header front and back is done simultaneously
      setTimeout(() => {
        this.setWhiteHeader(newPath);
        this.setWhiteHeaderWrapper(newPath);
      }, 1);
    },

    "$store.state.profile": {
      immediate: true,
      handler(profile) {
        if (profile) {
          this.populateSidebarItemsPerRole((profile as IProfile).orgType);
          this.sortSidebarItems();
        }
      },
    },
  },

  async created() {
    // Set urls and menu to $root
    this.$root.$data.loginUrl = this.mySkiUrl;
    this.$root.$data.logoutLabel = this.logoutLabel;
    this.$root.$data.logoutUrl = this.logoutUrl;
    this.$root.$data.organizationUrl = this.organizationUrl;
    this.$root.$data.menu = this.menu;

    // Set props object
    this.props = {
      logoUrl: this.logoUrl,
      menuLabel: this.menuLabel,
      mySkiLabel: this.mySkiLabel,
      mySkiUrl: this.mySkiUrl,
      loginLabel: this.loginLabel,
      logoutLabel: this.logoutLabel,
      logoutUrl: this.logoutUrl,
      searchLabel: this.searchLabel,
      searchUrl: this.searchUrl,
    };

    // Check for preview cookie in Umbraco; check pathname locally
    this.isUmbracoPreview =
      document.cookie.includes("UMB_PREVIEW") ||
      window.location.pathname.includes("/umbraco/preview");

    // Set White-Header variables
    this.setWhiteHeader(window.location.pathname);
  },
  mounted() {

    window.addEventListener("popstate", this.handleHistoryChange);
    // Set White-Header wrapper
    this.setWhiteHeaderWrapper(window.location.pathname);

    // Set scroll
    window.addEventListener("scroll", this.onScroll);
    this.intervalOnScroll = window.setInterval(() => {
      if (this.didScroll) {
        this.handleScroll();
        this.didScroll = false;
      }
    }, 250);

    // Handle dropdown open/close
    function handleDropdownClick(event: BvEvent) {
      const { target, vueTarget } = event;
      if (target == null || vueTarget == null) return false;
      if (target.closest(".headers") == null) return false;

      if (target.closest(".header-back") != null) {
        const value = target.closest(".dropdown").getAttribute("value");
        const query = `.header-front [value=${value}] .dropdown-toggle`;

        (document.querySelector(query) as HTMLElement).click();
        event.preventDefault();
      }
      return true;
    }

    function scrollTop() {
      setTimeout(() => {
        const headerFront = document.querySelector(".header-front");
        if (headerFront) {
          headerFront.scrollTop = 0;
        }
      }, 1);
    }

    this.$root.$on("bv::dropdown::hide", (event: BvEvent) => {
      const { vueTarget } = event;
      if (!handleDropdownClick(event)) return;

      if (vueTarget && (vueTarget as any).$el.id === this.dropdownId) {
        this.dropdownOpen = false;
      }

      scrollTop();
    });
    this.$root.$on("bv::dropdown::show", (event: BvEvent) => {
      const { vueTarget } = event;
      if (!handleDropdownClick(event)) return;

      this.dropdownOpen = true;
      this.dropdownId = vueTarget && (vueTarget as any).$el.id;

      scrollTop();
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    if (this.intervalOnScroll) clearInterval(this.intervalOnScroll);
  },
  methods: {
    formatMenu,
    populateSidebarItemsPerRole(role: string): void {
      // Clear existing items first to prevent duplicates
      this.sidebarItems = [];
      
      if ([OrgType.Kunde, OrgType.SKI].includes(role)) {
        const customerSidebar: SidebarArray = {
          role: OrgType.Kunde,
          sidebars: this.customerSidebarContent,
        };
        this.sidebarItems.push(customerSidebar);
      }
      if ([OrgType.Leverandoer, OrgType.Konsortium, OrgType.SKI].includes(role)) {
        const supplierSidebar: SidebarArray = {
          role: OrgType.Leverandoer,
          sidebars: this.supplierSidebarContent,
        };
        this.sidebarItems.push(supplierSidebar);
      }
    },
    sortSidebarItems(): void {
      this.sidebarItems.sort((a: SidebarArray, b: SidebarArray) => {
        return a.role < b.role ? -1 : a.role > b.role ? 1 : 0;
      });
    },
    handleHistoryChange() {
      this.anchorScrollDist = window.pageYOffset;
    },
    setWhiteHeader(path: string) {
      const whiteHeader =
        pathWhiteHeader(path, this.menu) ||
        path === "/" ||
        this.isUmbracoPreview;
      this.$root.$data.IsWhiteHeader = whiteHeader;
    },
    setWhiteHeaderWrapper(path: string) {
      this.isWhiteHeader =
        this.$root.$data.IsWhiteHeader &&
        path !== "/" &&
        !this.isUmbracoPreview;
      const wrapper = document.querySelector(".ski-wrapper") as HTMLElement;
      if (!wrapper) return;

      if (this.$root.$data.IsWhiteHeader) {
        wrapper.classList.add("bg-info");
        wrapper.classList.remove("bg-light");
      } else {
        wrapper.classList.add("bg-light");
        wrapper.classList.remove("bg-info");
      }
    },
    onScroll() {
      this.didScroll = true;
    },
    handleScroll() {
      const scrollDelta = 10;
      const scrollPos = window.pageYOffset;
      const scrollDiff = this.lastScrollPos - scrollPos;
      const scrollUp = scrollDiff > 0 && this.anchorScrollDist === 0;

      if (Math.abs(scrollDiff) <= scrollDelta && scrollPos !== 0) return;
      this.lastScrollPos = scrollPos;

      const header = document.querySelector(".headers") as HTMLElement;
      const wrapper = document.querySelector(".ski-wrapper") as HTMLElement;

      const hasSticky = header.classList.contains("sticky");
      const showHeader = header.style.marginTop === "0px";

      if (scrollPos > header.scrollHeight) {
        // Scrolling outside header
        if (hasSticky) {
          header.style.transition = "margin-top 1s";
        } else {
          header.classList.add("sticky");
          wrapper.style.paddingTop = `${header.clientHeight}px`;
        }
        if (scrollUp) {
          header.style.marginTop = "0";
        } else {
          header.style.marginTop = `-${header.scrollHeight}px`;
          this.anchorScrollDist = 0;
        }
      } else if (!showHeader || !scrollUp || scrollPos === 0) {
        // Scrolling down inside header or at top
        header.classList.remove("sticky");
        wrapper.style.paddingTop = "0";
        header.style.marginTop = "0";
        header.style.transition = "none";
      }
    },
  },
});
