import { configure } from "./configure";
import "./lib/styles/app.scss";

// TODO: Update to partials only
// const req = require.context("./lib/partials", true, /\.vue$/);
const req = require.context("./lib", true, /\.vue$/);

const components = {};

req.keys().forEach((key) => {
  const name = key.replace(/^.*[\\/]/, "").replace(/\.[^/.]+$/, "");
  (components as any)[name] = req(key).default;
});

function install(Vue: any, options: any = {}) {
  for (const key in components) {
    const k = options.prefix ? options.prefix + key : key;
    Vue.component(k, (components as any)[key]);
  }
}

// auto install
if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export { install, configure };
