




































import Vue from "vue";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import RichText from "@/lib/components/shared/RichText.vue";
import Close from "@/lib/assets/icon/close.svg";

export default Vue.extend({
  components: {
    SkiDefaultButton,
    RichText,
    Close,
  },
  props: {
    active: { type: Boolean, default: true },
    buttonName: { type: String, default: null },
    buttonUrl: { type: String, default: null },
    buttonTarget: { type: String, default: null },
    text: { type: String, required: true },
    topPlacement: { type: Boolean, default: false },
    variant: { type: String, default: "light" },
    buttonVariant: { type: String, default: "primary" },
  },
  data() {
    return {
      closedByUser: false,
      greenTextSet: this.variant === "lightGrey",
    };
  },
  computed: {
    btnColor(): string {
      if (this.variant === this.buttonVariant) {
        return "info-opacity";
      }
      return `${this.buttonVariant}`;
    },
  },
  created() {
    if (sessionStorage.getItem("userClosedNotification")) {
      this.closedByUser = true;
    }
  },
  methods: {
    close() {
      sessionStorage.setItem("userClosedNotification", "True");
      this.closedByUser = true;
    },
  },
});
