





















import Vue from "vue";
import RichText from "@/lib/components/shared/RichText.vue";
import Arrow from "@/lib/assets/icon/arrow.svg";

export default Vue.extend({
  components: { RichText, Arrow },
  props: {
    id: { type: String, required: true },
    titleChanged: { type: String, required: true },
    textChanged: { type: String, required: true },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.id);
    },
  },
});
