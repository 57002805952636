import dayjs from "dayjs";

export function formatDate(date?: string): string {
  if (!date) return "";

  // Pattern for UTC format  
  const UTCpattern = new RegExp("^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?(Z)?$");

  const yeardatemonthPattern = new RegExp("^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])");

  if (!UTCpattern.test(date) && !yeardatemonthPattern.test(date)) {
    return date;
  }

  const parsedDate = dayjs.utc(date);

  if (!parsedDate.isValid()) {
    return date
  };
  return parsedDate.local().format("D. MMMM YYYY");
}

export function getNow(): Date {
  return new Date();
}

export function isDateAfterToday(date: Date): boolean {
  // Returns true if no date is present; this is interpreted at an undecided time in the future
  return date ? date > getNow() : true;
}
