










import Vue from "vue";
import Arrow from "@/lib/assets/icon/arrow.svg";

export default Vue.extend({
  components: { Arrow },
  props: {
    to: { type: String, default: "" },
    target: { type: String, default: null },
    left: { type: Boolean, default: false }
  },
  data() {
    return {
      hasLink: Boolean(this.to.replace(/#/, ""))
    };
  }
});
