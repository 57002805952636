
























































































































































































































































































































import isEqual from "lodash-es/isEqual";
import xorWith from "lodash-es/xorWith";
import Vue from "vue";

import Close from "@/lib/assets/icon/close.svg";
import Open from "@/lib/assets/icon/open.svg";
import Search from "@/lib/assets/icon/search.svg";
import SmallArrow from "@/lib/assets/smallicon/arrow.svg";
import SmallClose from "@/lib/assets/smallicon/close.svg";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import { isDarkColor, isEmpty } from "@/lib/utilities";
import { AgreementsContainsTrafficlight } from "../agreementUtilities";

interface Data {
  searchQuery: string;
  chosen: any[];
}

export default Vue.extend({
  components: {
    Open,
    Close,
    SmallClose,
    SmallArrow,
    SkiDefaultButton,
    Search,
  },
  props: {
    data: { type: Object, default: null },
    tableVariant: { type: String, default: "" },
    filterVariant: { type: String, default: "" },
    filterOptions: { type: Array, default: null },
    sortOptions: { type: Array, default: null },
    filters: { type: Array, default: null },
    sortBy: { type: String, default: "" },
    setFilter: { type: Function, default: () => {} },
    setSorting: { type: Function, default: () => {} },
    hasSearchInput: { type: Boolean, default: false },
    searchSubmit: { type: Function, default: () => {} },
    searchString: { type: String, default: "" },
    filterIndex: { type: String, default: "" },
  },
  data(): Data {
    return {
      searchQuery: "",
      chosen: [],
    };
  },
  computed: {
    filterData() {
      return this.filterOptions.reduce((res: any, { filter, key }: any) => {
        const options = this.data[key] || [];
        const newOptions = options.map((option: any) => {
          if (typeof option === "string") return { key: filter, value: option };
          return { key: filter, value: option.name || option.value };
        });
        res[key] = newOptions;
        return res;
      }, {});
    },
    includeTrafficLightInFilter(): boolean {
      if (this.data.skiAgreementlist) {
        return AgreementsContainsTrafficlight(this.data.skiAgreementlist); // This part is only triggered on pages with data from GetAllAgreementes, e.g. agreement and tender pages
      }
      else if (this.data.agreement) {
        return AgreementsContainsTrafficlight(this.data.agreement); // This part is only triggered on pages with data from GetOrganizationById, e.g. Mit SKI pages
      }
      return false;
    },
  },
  created() {
    if (!this.isEmpty(this.filters)) {
      this.chosen = this.setChosenFilterValues();
    }
  },
  methods: {
    isDarkColor,
    isEmpty,
    isSelected(value: string): boolean {
      return this.filters.indexOf(value) > 0;
    },
    toggleFilter({ type, ...filter }: any) {
      function beforeFilter(filters: any) {
        if (type === "radio") {
          var radioFilter = filters.filter((f: any) => f.key === filter.key);
          if (radioFilter.length > 0 && radioFilter[0].value === filter.value) {
            return filters;
          } else {
            return filters.filter((f: any) => f.key !== filter.key);
          }
        }
        return filters;
      }
      this.setFilter(xorWith(beforeFilter(this.filters), [filter], isEqual));

      this.$nextTick(() => {
        this.updateChosenValues();
      });
    },
    clearFilter() {
      this.setFilter([]);
      this.chosen = [];
    },
    setChosenFilterValues() {
      var values: any[] = [];
      this.filters.forEach(function (filter: any) {
        values.push(filter.value);
      });
      return values;
    },
    optionsFilter(options: any[]): any[] {
      if (!Array.isArray(options)) return options;
      if (!this.includeTrafficLightInFilter) {
        options = options.filter((i) => !i.customer);
      }
      return options;
    },
    search(event: Event) {
      event.preventDefault();
      this.searchSubmit(this.searchQuery);
    },
    clear(event: Event) {
      event.preventDefault();
      this.searchQuery = "";
      this.searchSubmit(this.searchQuery);
    },
    formattedOptionValue(key: string, value: string) {
      if (
        key === "sourcingPrograms" ||
        key === "sourcingProgram" ||
        key === "locations"
      ) {
        return value;
      } else {
        const val = value.toLowerCase();
        return val.charAt(0).toUpperCase() + val.slice(1);
      }
    },
    updateChosenValues() {
      this.chosen = this.setChosenFilterValues();
    },
  },
});
