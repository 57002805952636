











import Vue from "vue";

export default Vue.extend({
  props: {
    changeConsentText: { type: String, required: true },
    withdrawConsentText: { type: String, required: true }
  },
  data() {
    return {
      consentStateDescription: "Afvis",
      consentWithdrawn: true
    }
  },
  created() {
    window.addEventListener('MonsidoCookieRunBasicScripts', this.updateCookieStatusDescription);
    window.addEventListener('MonsidoCookieRunMarketingScripts', this.updateCookieStatusDescription);
    window.addEventListener('MonsidoCookieRunAnalyticsScripts', this.updateCookieStatusDescription);
    window.addEventListener('MonsidoCookieRunPersonalisationScripts', this.updateCookieStatusDescription);
    window.addEventListener('MonsidoCookieOnLoad', this.updateCookieStatusDescription);
  },    
  beforeDestroy() {
    window.removeEventListener('MonsidoCookieRunBasicScripts', this.updateCookieStatusDescription);
    window.removeEventListener('MonsidoCookieRunMarketingScripts', this.updateCookieStatusDescription);
    window.removeEventListener('MonsidoCookieRunAnalyticsScripts', this.updateCookieStatusDescription);
    window.removeEventListener('MonsidoCookieRunPersonalisationScripts', this.updateCookieStatusDescription);
    window.removeEventListener('MonsidoCookieOnLoad', this.updateCookieStatusDescription);
  },
  methods: {
      withdrawConsent(): void {
        this.consentStateDescription = "Afvis";
        window.monsidoConsentManager.withdrawConsent();
        window.monsidoConsentManager.hideBanner();
        this.consentWithdrawn = true;
      },
      updateCookieStatusDescription(): void {
        if (window.monsidoConsentManager.cookiesConsent.cookies.length <= 1) {
          
          this.consentWithdrawn = true;
          this.consentStateDescription = " Afvis";
        }

        else {
          this.consentWithdrawn = false;
          this.consentStateDescription = (window.monsidoConsentManager.cookiesConsent.cookies.length === 5 ? "Tillad alle" : "Tillad udvalgte");
        }
      }
  }
});
