


































































































import Vue from "vue";
import LinkArrow from "@/lib/assets/icon/link.svg";
import TrafficLight from "@/lib/components/TrafficLight.vue";
import { compareValues, isEmpty } from "@/lib/utilities";

import { isTender } from "@/lib/agreementUtilities";

export default Vue.extend({
  components: { LinkArrow, TrafficLight },
  props: {
    subAgreementsTitle: { type: String, required: true },
    subAgreementsDescription: { type: String, required: true },
  },
  data() {
    return {
      item: this.$root.$data.item,
      renderTrafficLight: false,
    };
  },
  computed: {
    filteredSubAgreements() {
      const { key, number, statusFilter } = this.$config.agreement.subAgreement;

      var subAgreements = this.$root.$data.item[key];

      const filteredSubAgreements = subAgreements.filter(
        (agr: any) =>
          statusFilter.includes(agr.overallStatusName) ||
          (isTender(agr.overallStatusName) && agr.showOnAgreement === true)
      );

      return filteredSubAgreements.concat().sort(compareValues(number));
    },
  },
  async mounted() {
    this.renderTrafficLight = this.item.customerApplication !== undefined || this.item.customerApplication !== null;
  },
  methods: {
    isEmpty,
    isTender,
    getSubAgreementUrl(id: string) {
      return `${window.location.pathname}?id=${id}`;
    },
  },
});
