var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.showWidget && (_vm.showCommitment !== undefined || _vm.showCommitment !== null)
  )?_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('div',{attrs:{"id":"hot-widget"}},[_c('link',{attrs:{"href":_vm.stylesheetLink,"rel":"stylesheet"}}),_c('script',{attrs:{"type":"module","src":_vm.widgetLink}}),_c('hot-component',{attrs:{"message":"","commitment":_vm.showCommitment,"configuration":JSON.stringify({
            apiConfig: {
              stamdataApiBaseUrl: _vm.apiBaseUrlStamdata,
              hearingApiBaseUrl: _vm.apiBaseUrlHot,
            },
            authConfig: {
              client_id: _vm.getClientId(),
              authority: _vm.getAuthority(),
              redirect_uri: _vm.getRedirectUrl(),
              post_logout_redirect_uri:
              _vm.getRedirectLogoutUrl(),
            },
          })},on:{"hotLoaded":_vm.scrollToHotElement}})],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }