








































































import { AgreementsContainsTrafficlight } from "@/lib/agreementUtilities";
import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";
import Arrow from "@/lib/assets/icon/arrow.svg";
import { GetAgreementsAfterDate } from "@/lib/businessLogic/my-ski/agreementFilter";
import AgreementTableButtons from "@/lib/components/AgreementTableButtons.vue";
import SkiTable from "@/lib/components/SkiTable.vue";
import { formatDate, isDateAfterToday } from "@/lib/dateUtilites";
import { OrgAgreementItem } from "@/lib/models/agreementItem.model";
import {
  downloadExcelSheet,
  generateId,
  getValue,
  isEmpty,
  setHighestPriorityCustomerApplication,
} from "@/lib/utilities";
import Vue from "vue";

interface Data {
  isScrolledLeft: boolean;
  gradientVisibility: string;
  elementsMax: number;
  scrollable: boolean;
  linkWidth: number;
  enabledGradient: boolean;
  tableIndex: string;
  excelAgreements: OrgAgreementItem[];
  status: string;
  printing: boolean;
}

interface EmitEvent {
  filteredAgreements: OrgAgreementItem[];
}

export default Vue.extend({
  components: { SkiTable, Arrow, ArrowLeft, AgreementTableButtons },
  props: {
    linkItemUrls: { type: Array, required: true },
    fields: { type: Array, required: true },
    sortOptions: { type: Array, required: true },
    filterOptions: { type: Array, required: true },
    applicationKeys: { type: Array, default: null },
    hasSearchInput: { type: Boolean, default: false },
    tableId: { type: String, required: true },
    tableClass: { type: String, default: "" },
    scrollEnabled: { type: Boolean, default: true },
    hasTableGradient: { type: Boolean, default: false },
    csvFilename: { type: String, default: "" },
    printFunction: { type: Function, default: null },
    hidePrinting: { type: Boolean, default: true },
    hideThisBlock: { type: Function, default: () => {} },
    hideThisOnEmpty: { type: Boolean, default: false },
    maxElements: { type: Number, default: 10 },
    defaultSortBy: { type: String, default: "" },
    dateFilter: { type: String, default: "" },
  },
  data(): Data {
    return {
      isScrolledLeft: true,
      gradientVisibility: this.hasTableGradient ? "visible" : "hidden",
      elementsMax: this.maxElements,
      scrollable: this.scrollEnabled,
      linkWidth: 10000,
      enabledGradient: this.hasTableGradient,
      tableIndex: generateId(),
      excelAgreements: [],
      status: "",
      printing: false,
    };
  },
  updated() {
    if (this.printing) {
      this.$nextTick(() => {
        window.print();
        this.printing = false;
      });
    }
  },
  computed: {
    filteredAgreements(): OrgAgreementItem[] {
      const { key, appKey, appType } = this.$config.organization.agreement;

      const agreements: OrgAgreementItem[] = this.$root.$data.org[key];

      const applicationKeys = this.applicationKeys;

      if (!agreements || !Array.isArray(agreements)) return [];
      var filtered = agreements.filter(
        (agr: OrgAgreementItem) =>
          agr.mainAgreementId === null && isDateAfterToday(agr.terminationDate)
      );
      if (AgreementsContainsTrafficlight(filtered)) {
        filtered = filtered.filter(function (agr: OrgAgreementItem): boolean {
          const customerApp = getValue(appKey, agr);
          if (!customerApp) {
            return false;
          }
          const matchApplication = applicationKeys
            ? customerApp.some((cust: any) =>
                applicationKeys.some(
                  (appli: any) =>
                    cust[appType].toLowerCase() === appli.toLowerCase()
                )
              )
            : true;
          return matchApplication;
        });
      }
      const prioritizedAgreements = JSON.parse(
        JSON.stringify(filtered)
      ) as OrgAgreementItem[];
      setHighestPriorityCustomerApplication(
        this,
        prioritizedAgreements,
        applicationKeys
      );

      return this.dateFilter !== ""
        ? GetAgreementsAfterDate(prioritizedAgreements, this.dateFilter)
        : prioritizedAgreements;
    },
  },
  created() {
    if (this.hideThisOnEmpty && this.filteredAgreements.length === 0) {
      this.hideThisBlock && this.hideThisBlock();
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.setupForTableSize);
      this.setupForTableSize();
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.setupForTableSize);
  },
  methods: {
    isEmpty,
    formatDate,
    scrollTable(event: Event) {
      event.preventDefault();
      var div = this.$refs[`scroll-table-${this.tableId}`] as HTMLDivElement;
      var tableDiv = div.getElementsByClassName("table-responsive")[0];
      if (tableDiv) {
        tableDiv.scrollLeft =
          tableDiv.scrollLeft > 0 ? 0 : tableDiv.getBoundingClientRect().width;
        this.isScrolledLeft = !this.isScrolledLeft;
        this.gradientVisibility =
          this.gradientVisibility !== "hidden" ? "hidden" : "visible";
      }
    },
    downloadExcel() {
      downloadExcelSheet(
        this,
        this.excelAgreements,
        this.fields as any,
        this.csvFilename
      );
    },
    printTable(event: Event) {
      if (event) event.preventDefault();

      if (this.printFunction) {
        this.printFunction();
      } else {
        this.printing = true;
      }
    },
    setFilteredAgreements(e: EmitEvent) {
      this.excelAgreements = e.filteredAgreements;
    },
    setupForTableSize() {
      var div = this.$refs[`scroll-table-${this.tableId}`] as HTMLDivElement;
      if (div) {
        var tableDiv = div.getElementsByClassName("table-responsive")[0];
        var table = div.getElementsByClassName("b-table")[0];
        this.linkWidth = table.clientWidth;
        if (!this.hasTableGradient) {
          return;
        }
        if (tableDiv.clientWidth >= table.clientWidth) {
          this.scrollable = false;
          this.gradientVisibility = "hidden";
        } else {
          this.scrollable = true;
          this.gradientVisibility = "visible";
        }
      }
    },
  },
});
