










































import Vue from "vue";

interface Data {
  speed: number;
  count: number;
  counter: number;
}

export default Vue.extend({
  props: {
    description: { type: String, required: true },
    scrollText: { type: String, required: true },
    link: { type: String, default: "" },
    target: { type: String, default: null }
  },
  data(): Data {
    return {
      speed: 0.8,
      count: 0,
      counter: 0
    };
  },
  beforeDestroy() {
    this.stopCounter();
  },
  methods: {
    startCounter() {
      this.counter = window.setInterval(() => {
        this.count += 1;
      }, 1);
    },
    stopCounter() {
      clearInterval(this.counter);
      this.count = 0;
    },
    preventEmptyHref(link: string, event: Event) {
      if (!link || !link.replace(/#/, "")) {
        event.preventDefault();
        return false;
      }
      return true;
    }
  }
});
