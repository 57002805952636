








































import Vue from "vue";
import Play from "@/lib/assets/icon/play.svg";

export default Vue.extend({
  components: { Play },
  props: {
    videoUrl: { type: String, required: true }
  },
  data() {
    return {
      videoId: this.videoUrl.replace(/.*vimeo.com\//, ""),
      videoParams: "?title=0&byline=0&portrait=0&controls=0&autoplay=0",
      controls: false,
      isVideoRendered: false
    };
  },
  created() {
    window.addEventListener('MonsidoCookieOnHide', this.checkCookieConsent);
  },
  beforeDestroy() {
    window.removeEventListener('MonsidoCookieOnHide', this.checkCookieConsent);
  },
  methods: {
    play() {
      this.videoParams = "?title=0&byline=0&portrait=0&controls=1&autoplay=1";
      this.controls = true;
    },
    checkCookieConsent() {
      const initialConsentList = window.monsidoConsentManager.cookiesConsent.cookies as string[];
      this.isVideoRendered = initialConsentList.includes("analytics");    
    }
  }
});
