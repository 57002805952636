




import Vue from "vue";
import { formatDate } from "@/lib/dateUtilites";

export default Vue.extend({
  props: {
    date: { type: String, default: null }
  },
  methods: {
    formatDate
  }
});
