<template>
  <b-aspect :aspect="aspect">
    <div class="h-100 position-relative overflow-hidden">
      <div :style="{ height: 0 }">
        <slot />
      </div>
      <slot name="relative" />
    </div>
  </b-aspect>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    aspect: { type: String, default: "16:9" }
  }
});
</script>

<style lang="scss"></style>
