
















































import Vue from "vue";
import { replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem } from "@/lib/utilities";
import Arrow from "@/lib/assets/icon/arrow.svg";
import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";
import SkiImage from "@/lib/components/SkiImage.vue";
import { resetHashtagValue } from "../urlUtilities";

interface Data {
  offset: number;
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagimageGalleryId: string;
}

interface GalleryImage {
  alttekst: string;
  caption: string
  imageMode: string;
  url: string;
}

export default Vue.extend({
  components: { Arrow, ArrowLeft, SkiImage },
  props: { images: { type: Array, required: true } },
  data(): Data {
    return {
      offset: 0,
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagimageGalleryId: ""
    };
  },
  computed: {
    translate(): {} {
      return { transform: `translateX(-${this.offset * 100}%)` };
    },
    hasPrevious(): boolean {
      return this.offset > 0;
    },
    hasNext(): boolean {
      return this.offset < this.images.length - 1;
    }

  },
  mounted() {
    this.hashtagimageGalleryId = "#" + this.getInitialCaption();
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagimageGalleryId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    } 
  },
  methods: {
    getInitialCaption() {
      var images = [...this.$props.images] as GalleryImage[];
      return images[0].caption.toLowerCase();
    },
    slideClass(index: number): string {
      const diff = index - this.offset;
      if (!diff) return "slide-current";

      if (Math.abs(diff) === 1) {
        return diff > 0 ? "slide-right" : "slide-left";
      }
      return diff > 0 ? "slide-next" : "slide-previous";
    },
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var imageGalleryId = this.hashtagimageGalleryId.split("#")[1];
      scrollToAnchorItem(imageGalleryId);
    }
  }
});
