<template>
  <b-container
    :id="title.toLowerCase()"
    fluid
    class="py-5 my-5 px-0 overflow-hidden newsletter-frontpage-fix"
    :class="`bg-${variant}`"
  >
    <b-row>
      <b-col
        id="margin-fix-nyhedsbrev"
        xl="5"
        lg="4"
        class="px-5 px-lg-0"
        :class="[isLeftAligned ? 'order-xs-1 order-lg-2' : 'order-xs-1']"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-label="newsletter-svg"
          role="img"
          width="100%"
          height="100%"
        >
          <text
            class="text"
            :text-anchor="isLeftAligned ? 'start' : 'end'"
            font-family="Oswald"
            font-size="200px"
            font-weight="500"
            stroke="currentColor"
            stroke-width="2"
            fill="none"
          >
            <tspan dy="200" :x="isLeftAligned ? '0' : '100%'">NYHEDS</tspan>
            <tspan dy="200" :x="isLeftAligned ? '0' : '100%'">BREV</tspan>
          </text>
        </svg>
      </b-col>
      <b-col xl="1" lg="1"></b-col>
      <b-col xl="4" lg="6">
        <div class="d-flex px-5 pt-3 px-lg-3">
          <div class="newsletter-icon">
            <span
              :class="
                `circle-icon-${variant === 'primary' ? 'light' : 'primary'}`
              "
            >
              <mail class="svg" />
            </span>
          </div>
          <div class="pt-1 ml-lg-3">
            <h3>{{ title }}</h3>
            <div>{{ description }}</div>
            <ski-default-button
              :to="buttonNewsletterUrl"
              :target="buttonNewsletterTarget"
              :variant="computedButtonVariant"
              :opaque="computedButtonVariant == variant"
              class="mt-5 my-xl-5 text-nowrap"
              >{{ buttonNewsletterLabel }}</ski-default-button
            >
          </div>
        </div>
      </b-col>
      <b-col xl="2" lg="1"></b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from "vue";
import { replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem } from "@/lib/utilities";
import { resetHashtagValue } from "@/lib/urlUtilities";

import Mail from "@/lib/assets/icon/mail.svg";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";

export default Vue.extend({
  components: { Mail, SkiDefaultButton },
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    buttonNewsletterLabel: { type: String, required: true },
    buttonNewsletterUrl: { type: String, required: true },
    buttonNewsletterTarget: { type: String, default: null },
    isLeftAligned: { type: Boolean, default: true },
    buttonVariant: { type: String, default: "" },
    variant: { type: String, default: "primary" }
  },
  data() {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagnewsletterId: "#" + this.title.toLowerCase()
    };
  },
  computed: {
    computedButtonVariant() {
      if (this.buttonVariant) {
        return this.buttonVariant;
      }

      switch (this.variant) {
        case "primary":
          return "primary";
        case "secondary":
          return "info";
        case "light":
          return "primary";
        case "dark":
          return "dark";
        default:
          return "primary";
      }
    }
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagnewsletterId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    } 
  },
  methods: {
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var newsletterId = this.hashtagnewsletterId.split("#")[1];
      scrollToAnchorItem(newsletterId);
    }
  }
});
</script>

<style lang="scss">
.newsletter-icon {
  @include media-breakpoint-down(md) {
    position: absolute;
    top: -130px;
  }

  @include media-breakpoint-up(lg) {
    margin-right: $spacer * 5;
  }
}
</style>
