































import InfoBox from "@/lib/components/InfoBox.vue";

import {
  alertMessage,
  isEmpty,
  qsParams,
  setHighestPriorityCustomerApplication,
} from "@/lib/utilities";
import Vue from "vue";

interface Data {
  id: string;
  documentId: string;
  item: {} | null;
  agreementType: string;
  infoDescription: string;
}

export default Vue.extend({
  components: { InfoBox },
  data(): Data {
    return {
      id: "",
      documentId: "",
      item: null,
      agreementType: "",
      infoDescription: "",
    };
  },
  created() {
    this.id = qsParams().id;
    this.documentId = qsParams().documentId;
    this.fetchAndSetAgreementAPIData();
  },
  methods: {
    async fetchAndSetAgreementAPIData() {
      try {
        var agreement = await this.$api.AgreementsRepository.get(this.id);
        const agreementDescriptions =
          await this.$api.AgreementsRepository.getDescription(this.id);
        this.$root.$data.itemDescriptions = agreementDescriptions;
        if (this.redirectToTenderPage(agreement)) return;
        var iteAgreement = agreement;
        if (iteAgreement) {
          if(iteAgreement.customerApplication !== undefined){
            setHighestPriorityCustomerApplication(this, iteAgreement);
          } else {
            iteAgreement[this.$config.agreement.application.activeApplication] =
              null;
          }
        }

        const infoDescriptionRaw = agreementDescriptions.find(
          (x: { agreementDescriptionName: string }) =>
            x.agreementDescriptionName === "Særlig information om aftalen"
        );
        this.infoDescription =
          infoDescriptionRaw !== undefined
            ? infoDescriptionRaw.agreementDescriptionText
            : "";

        this.item = iteAgreement;
        this.$root.$data.item = this.item;
        this.setAgreementType(this.item);
      } catch (err) {
        alertMessage(this);
      }
    },
    redirectToTenderPage(response: any): boolean {
      var { statusField, tenderStatus, tenderPage } = {
        statusField: "overallStatusName",
        tenderStatus: "Kommende",
        tenderPage: "/udbud/se-udbud/",
      };

      if (response[statusField] === tenderStatus) {
        window.location.href = `${tenderPage}?id=${this.id}${
          this.documentId !== undefined ? "&documentId=" + this.documentId : ""
        }`;
        return true;
      }
      return false;
    },
    setAgreementType(item: any) {
      if (item === null) return;

      // TODO: FIX This should be handled by backend API
      if (item.mainAgreementName) {
        this.$root.$data.agreementType = this.agreementType = "subAgreement";
      } else if (!isEmpty(item.subAgreement)) {
        this.$root.$data.agreementType = this.agreementType =
          "mainAgreementWithSubAgreements";
      } else {
        this.$root.$data.agreementType = this.agreementType = "mainAgreement";
      }
    },
    shouldRender(component: string) {
      let hidden: string[] = [];
      switch (this.agreementType) {
        case "mainAgreement":
          hidden = ["agreement-item-sub-agreements"];
          break;
        case "mainAgreementWithSubAgreements":
          hidden = ["timeline", "status"];
          break;
        case "subAgreement":
          hidden = ["agreement-item-sub-agreements"];
          break;
      }
      if (hidden.includes(component)) {
        return false;
      }

      return true;
    },
  },
});
