import { AgreementItem, OrgAgreementItem } from "./models/agreementItem.model";

// Check whether an agreement is in use, is a tender or is expired using the field overallStatusName from the Stamdata API. 
// This value is set in the background process "SKI-aftale: sæt overordnbet status" in Stamdata CRM depending on the specific value for an agreement's status

export const isAgreement = (overallStatusName: string): boolean => {
    return overallStatusName === 'Aktiv';
}

export const isTender = (overallStatusName: string): boolean => {
    return overallStatusName === 'Kommende';
}

export const isExpired = (overallStatusName: string): boolean => {
    return overallStatusName === 'Udløbet';
}

export function AgreementsContainsTrafficlight(agreementList: AgreementItem[] | OrgAgreementItem[]): boolean {
    return agreementList.some(x => x?.customerApplication !== undefined && x?.customerApplication !== null);
  }

export function AgreementContainsTrafficlight(agreement: AgreementItem): boolean {
return AgreementsContainsTrafficlight([agreement]);
}
