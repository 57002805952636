

















import Vue from "vue";

export default Vue.extend({
  props: {
    changeConsentText: { type: String, required: true },
    withdrawConsentText: { type: String, required: true },
  },
  data() {
    return {
      consentStateDescription: "Afvis",
      consentWithdrawn: true,
    };
  },
  created() {
    window.addEventListener(
      "MonsidoCookieRunAnalyticsScripts",
      this.updateCookieStatusDescription
    );
    window.addEventListener(
      "MonsidoCookieOnHide",
      this.updateCookieStatusDescription
    );
  },
  beforeDestroy() {
    window.removeEventListener(
      "MonsidoCookieRunAnalyticsScripts",
      this.updateCookieStatusDescription
    );
    window.removeEventListener(
      "MonsidoCookieOnHide",
      this.updateCookieStatusDescription
    );
  },
  mounted() {
    this.updateCookieStatusDescription();
  },
  methods: {
    withdrawConsent(): void {
      this.consentStateDescription = "Afvis";
      window.monsidoConsentManager.withdrawConsent();
      window.monsidoConsentManager.hideBanner();
      this.consentWithdrawn = true;
    },
    updateCookieStatusDescription(): void {
      var consentedCookieList = window.monsidoConsentManager.cookiesConsent.cookies as string[];
      const numberOfConsentedCookies = consentedCookieList.length;
      if (numberOfConsentedCookies <= 1) {
        this.consentWithdrawn = true;
        this.consentStateDescription = " Afvis";
      } else {
        this.consentWithdrawn = false;
        this.consentStateDescription =
        numberOfConsentedCookies === 5 ? "Tillad alle" : "Tillad udvalgte";
      }
    },
  },
});
