


























import Vue from "vue";

import {qsParams, isEmpty, alertMessage, replaceHexWithNordicLettersAndWhitespace, scrollToItemTop } from "@/lib/utilities";
import Accordion from "@/lib/components/Accordion.vue";

interface Data {
  id: string;
  faqItem: {} | null;
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagFaqId: string;
}

export default Vue.extend({
  components: { Accordion },
  props: {
    faqTitle: { type: String, required: true },
    faqDescription: { type: String, required: true }
  },
  data(): Data {
    return {
      id: "",
      faqItem: null,
      hashtagFromWindow: window.location.hash,
      hashtagItem: "",
      hashtagFaqId: "#faq"
    };
  },
  async created() {
    this.$root.$on("send-hashtag", (hashtag: string) => {
      this.hashtagFromWindow = replaceHexWithNordicLettersAndWhitespace(hashtag);
    });
    this.id = qsParams().id;
    await this.fetchAPIData();

    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagFaqId) {
      await this.scrollToAnchorItem();
    }
  },
  methods: {
    isEmpty,
    async fetchAPIData() {
      try{
        var agreementFAQ = await this.$api.AgreementsRepository.getFAQAgreement(this.id);
        this.faqItem = agreementFAQ;
      }catch(err){
        alertMessage(this);
      }
    },
    async scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var faqId = this.hashtagFaqId.split("#")[1];
      var element = document.getElementById(faqId);
      if (element) {
        scrollToItemTop(element);
      }
    }
  }
});
