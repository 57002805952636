import axios from "axios";
import { configurationProvider } from "@/lib/ConfigurationProvider";
import { replaceAll } from "@/lib/utilities";

export async function downloadDocument(documentId: string): Promise<number> {
  const baseUrl = configurationProvider.api.documentBaseUrl;
  let _url = `${baseUrl}/document/${documentId}`;
  return await axios({
    url: _url,
    method: "GET",
    responseType: "blob", // This is necessary for the browser to download files correctly
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      const fileName =
        extractFileName(response.headers["content-disposition"] as string) ?? "document.pdf";

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url); // Clean up temporary url and link after use
      link.remove(); 
      return response.status;
    })
    .catch((err) => {
      return err.response.status;
    });
}

function extractFileName(contentDisposition: string |undefined): string {
  if (contentDisposition === undefined) {
    return "";
  }

  return replaceAll(
    contentDisposition?.split("filename=")[1]?.split(";")[0],
    '"',
    ""
  );;
}
