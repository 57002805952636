class TokenProvider {
  private _token: string | null = null;
  public set token(token: string | null) {
    this._token = token;
  }
  public get token(): string | null {
    return this._token;
  }
}

const tokenProvider = new TokenProvider();
export { tokenProvider };
