















import Vue from "vue";
import SkiRoundButton from "@/lib/components/SkiRoundButton.vue";
import environment from "@/lib/assets/icon/environment.svg";
import { replaceHexWithNordicLettersAndWhitespace, scrollToItemTop } from "../utilities";
import { isTender } from "@/lib/agreementUtilities";

interface Data {
  shortcutLink: string;
}

export default Vue.extend({
  components: {
    SkiRoundButton,
    environment
  },
  props: {
    shortcutText: { type: String, required: true },
    shortcutHashtag: { type: String, required: true },
    flexStyling: { type: Boolean, default: false }
  },  
  data(): Data {
    return {
      shortcutLink: ""
    };
  },
  mounted() {
    this.setShortcutLink();
    this.anchorHashCheck();
  },
  methods: {
    anchorHashCheck() {
      if (this.$route !== undefined) {
        if (replaceHexWithNordicLettersAndWhitespace(window.location.hash) === this.$route.hash) {
          const el = document.getElementById(this.$route.hash.slice(1))
          if (el) {
            scrollToItemTop(el);
          }
        }
      }
    },
    setShortcutLink() {
      this.shortcutLink = window.location.origin + window.location.pathname + window.location.search + "#" + this.shortcutHashtag;
    },
    isTender
  },
});
