






import Vue from "vue";
import { qsParams } from "@/lib/utilities";

declare global {
  interface Window {
    loc: string;
  }
}

export default Vue.extend({
  props: {
    loc: { type: String, required: true },
    pageId: { type: String, required: true },
    src: { type: String, required: true }
  },
  data() {
    return {
      pId: qsParams().pageId || this.pageId
    };
  },
  mounted() {
    if (!window.loc) {
      window.loc = this.loc;
      const script = document.createElement("script");
      script.setAttribute("src", this.src);
      document.head.appendChild(script);
    }
  }
});
