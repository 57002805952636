















import Vue from "vue";
import { createCacheableImageUrl } from "../urlUtilities";


export default Vue.extend({
  props: {
    aspect: { type: String, default: "16:9" },
    maxWidth: { type: String, default: "auto" },
    imageUrl: { type: String, required: true },
    imageClass: { type: String, default: "" },
    altText: { type: String, default: "" },
    circle: { type: Boolean, default: false },
    bw: { type: Boolean, default: false },
  },
  data() {
    return {
      customStyle: this.createCustomStyle(this.imageUrl),
    };
  },
  watch: {
    imageUrl: {
      handler(newVal: string) {
        // We need to recreate the image background style
        this.customStyle = this.createCustomStyle(newVal);
      }
    }
  },
  methods: {
    createCustomStyle(imgUrl: string): object {
      return {
        backgroundImage: imgUrl !== "" ? `url(${createCacheableImageUrl(imgUrl)})` : "",
        maxWidth: this.maxWidth,
      };
    }
  },
});
