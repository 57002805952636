



















































































import Vue, { PropType } from "vue";

import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import SkiDropDown from "@/lib/components/SkiDropDown.vue";
import SkiSearchButton from "@/lib/components/SkiSearchButton.vue";
import PrintButton from "@/lib/components/PrintButton.vue";
import Search from "@/lib/assets/icon/search.svg";

interface DepartmentItem {
  key: any;
  label: string;
}

interface Data {
  searchQuery: string;
}

export default Vue.extend({
  components: {
    SkiDefaultButton,
    SkiDropDown,
    SkiSearchButton,
    PrintButton,
    Search,
  },
  props: {
    departments: { type: Array as PropType<DepartmentItem[]>, default: [] },
    selectedDepartment: {
      type: Object as PropType<DepartmentItem>,
      required: true,
    },
    printVariant: { type: String, required: true },
    linkLabel: { type: String, default: "" },
    linkUrl: { type: String, default: "" },
    linkTarget: { type: String, default: "" },
  },
  data(): Data {
    return {
      searchQuery: "",
    };
  },
  watch: {
    searchQuery() {
      this.$emit("searchQuery", this.searchQuery);
    },
  },
  methods: {
    emitSelectedDepartment(selectedDepartment: DepartmentItem) {
      this.$emit("select", selectedDepartment);
    },
  },
});
