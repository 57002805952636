























import Vue from "vue";
import SkiImage from "@/lib/components/SkiImage.vue";

export default Vue.extend({
  components: { SkiImage },
  props: {
    to: { type: String, default: null },
    target: { type: String, default: null },
    imageUrl: { type: String, default: null },
    imageAltText: { type: String, default: null },
    isRightAligned: { type: Boolean, default: false }
  }
});
