































import Vue from "vue";
import SkiLink from "@/lib/components/SkiLink.vue";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";

export default Vue.extend({
  components: { SkiLink, SkiDefaultButton },
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
    linkAgreementsUrl: { type: String, required: true },
    linkAgreementsLabel: { type: String, required: true },
    linkTendersUrl: { type: String, required: true },
    linkTendersLabel: { type: String, required: true },
    linkCatalogUrl: { type: String, required: true },
    linkCatalogLabel: { type: String, required: true }
  },
  created() {
    this.$root.$data.IsWhiteHeader = true;
  }
});
