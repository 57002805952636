




























import Vue from "vue";

export default Vue.extend({
  props: {
    text: { type: String, required: true }
  },
  data() {
    return {
      displayText: "",
      speed: 0.2,
      count: 30,
      counter: 0,
      width: Infinity
    };
  },
  created() {
    const text = this.text.toUpperCase() + " ";
    let [pattern, len, times, chars] = [text, text.length, 1, 250];

    while (chars > 0) {
      pattern += text;
      chars -= len;
      times++;
    }

    if (times % 2 !== 0) {
      pattern += text;
    }

    this.displayText = pattern;
  },
  mounted() {
    const text = this.$refs.text as HTMLElement;
    this.width = text.getBoundingClientRect().width / 2 + 20;

    this.counter = window.setInterval(() => {
      this.count += 1;
    }, 1);
  },
  beforeDestroy() {
    clearInterval(this.counter);
  }
});
