






































import { tokenProvider } from "@/lib/TokenProvider";
import { downloadDocument } from "@/lib/businessLogic/documents";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import SkiModal from "@/lib/components/modals/SkiModal.vue";
import MySkiRow from "@/lib/partials/my-ski/MySkiRow.vue";
import { setCookie, CookieNames } from "@/lib/utilities/cookieUtilities";
import { StatusCodes } from "http-status-codes";
import Vue from "vue";

export default Vue.extend({
  components: { SkiDefaultButton, SkiModal, MySkiRow },
  data() {
    return {
      downloadCompleted: false,
      showErrorModal: false,
      showForbiddenErrorModal: false,
      errorOccurred: false,
      documentId: "",
    };
  },
  async created() {
    try {
      // We set the jwt token as a cookie, to enable the Umbraco endpoint for downloading documents to access the jwt token
      setCookie(CookieNames.jwtToken, tokenProvider.token, "/");

      await this.handleDownload();
    } catch (error) {
      this.handleError();
    }
  },
  methods: {
    async handleDownload(): Promise<void> {
      const params = new URLSearchParams(window.location.search);
      const documentId = params.get("documentId");
      if (documentId) {
        this.documentId = documentId;
        const statusCode = await downloadDocument(documentId);

        this.handleStatus(statusCode);
      }
    },
    handleStatus(statusCode: number): void {
      switch (statusCode) {
        case StatusCodes.OK:
          this.downloadCompleted = true;
          break;
        case StatusCodes.UNAUTHORIZED:
          this.handleAuthError();
          break;
        case StatusCodes.FORBIDDEN:
          this.handleFordiddenError();
          break;
        default:
          this.handleError();
      }
    },
    handleError() {
      this.errorOccurred = true;
      this.showErrorModal = true;
    },
    handleAuthError() {
      this.errorOccurred = true;
      this.$store.dispatch("signIn", window.location.href);
    },
    handleFordiddenError() {
      this.errorOccurred = true;
      this.showForbiddenErrorModal = true;
    },
    closeAuthErrorModal(): void {
      this.showForbiddenErrorModal = false;
    },
    closeErrorModal(): void {
      this.showErrorModal = false;
    },
  },
});
