













import Vue from "vue";
import SkiRoundButton from "@/lib/components/SkiRoundButton.vue";
import Print from "@/lib/assets/icon/print.svg";

export default Vue.extend({
  components: {
    SkiRoundButton,
    Print
  },
  props: {
    classes: { type: String, default: "my-4" },
    variant: { type: String, required: true }
  },
  methods: {
    print() {
      window.print();
    }
  }
});
