

















































import Vue from "vue";
import SkiLink from "@/lib/components/SkiLink.vue";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import SkiImage from "@/lib/components/SkiImage.vue";

export default Vue.extend({
  components: { SkiLink, SkiDefaultButton, SkiImage },
  props: {
    imageUrl: { type: String, required: true },
    imageAltText: { type: String, required: true },
    linkAgreementsUrl: { type: String, required: true },
    linkAgreementsTarget: { type: String, default: null },
    linkAgreementsLabel: { type: String, required: true },
    linkTendersUrl: { type: String, required: true },
    linkTendersTarget: { type: String, default: null },
    linkTendersLabel: { type: String, required: true },
    linkCatalogUrl: { type: String, required: true },
    linkCatalogTarget: { type: String, default: null },
    linkCatalogLabel: { type: String, required: true }
  }
});
