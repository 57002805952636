












































import DocumentCard from "@/lib/components/DocumentCard.vue";
import SkiModal from "@/lib/components/modals/SkiModal.vue";
import { configurationProvider } from "@/lib/ConfigurationProvider";

import { compareValues, getFileTypeFromExtension } from "@/lib/utilities";
import {
  DocumentAuthorizationUnauthorizedReason,
  IDocumentAuthorization,
} from "@/lib/api/APIService";
import Vue from "vue";

export default Vue.extend({
  components: { DocumentCard, SkiModal },
  props: {
    contentArray: { type: Array, required: true },
    title: { type: String, required: true },
  },
  data() {
    return {
      apiGroupKeys: this.$config.agreement.descriptions.agreementDocuments,
      documentId: "",
      showErrorModal: false,
      showAuthErrorModal: false,
    };
  },
  computed: {
    documentGroupArray() {
      const documents: any[] = this.contentArray;
      const keys = this.apiGroupKeys;

      const uniqueDocGroups = [
        ...new Set(documents.map((item) => item[keys.docType])),
      ];

      // Creating new document group objects only for those groups that have corresponding documents
      const docGroupObjects = uniqueDocGroups.reduce((accumulator, group) => {
        const docsUnsorted = documents.filter(
          (doc) =>
            doc[keys.docType] === group &&
            (doc.accordionName === this.title ||
              (!doc.accordionName && this.title === "Aftaledokumenter"))
        );

        if (docsUnsorted.length > 0) {
          docsUnsorted.forEach(async (doc) => {
            doc.fileName =
              doc.fileName === null ? keys.nameIfNull : doc.fileName;
            doc._fileType = getFileTypeFromExtension(doc[keys.docFileName]);

            const docProps = this.getDocProps(
              doc[keys.docId],
              doc.userAccess,
              this.$store.getters.isLoggedIn
            );

            doc._url = docProps.url;
            doc._label = docProps.label;
            doc._icon = docProps.icon;
          });

          const obj = {
            docGroupName: group || "",
            docGroupSortKey: documents.find(
              (doc) => doc[keys.docType] === group
            )[keys.docGroupSortKey],
            docs: docsUnsorted.sort(compareValues(keys.docSortKey)),
          };

          accumulator.push(obj);
        }

        return accumulator;
      }, []);

      return docGroupObjects.sort(compareValues(keys.docGroupSortKeyReference));
    },
  },
  methods: {
    getDocProps(
      docId: string,
      userAccess: IDocumentAuthorization,
      isLoggedIn: boolean
    ) {
      // TODO: Revisit this, when Stamdata API is updated to SSO
      const docObj = { url: "", label: "", icon: "" };

      if (userAccess.authorized) {
        let docUrl = configurationProvider.api.documentBaseUrl;
        docUrl += "/" + this.apiGroupKeys.endpoint_id.replace(":id", docId);

        docObj.url = docUrl;
        docObj.label = "Download";
        docObj.icon = "Download";
      } else if (isLoggedIn && !userAccess.authorized) {
        docObj.url = "//";
        docObj.label = this.getErrorMessage(userAccess);
        docObj.icon = "Lock";
      } else if (!isLoggedIn) {
        docObj.url = "//";
        docObj.label = "Log ind for at downloade";
        docObj.icon = "Lock";
      }

      return docObj;
    },
    getErrorMessage(userAccess: IDocumentAuthorization): string {
      switch (userAccess.unauthorizedReason) {
        case DocumentAuthorizationUnauthorizedReason._0:
          return "Mangler adgang. Kontakt din brugeradministrator";
        case DocumentAuthorizationUnauthorizedReason._1:
          return "Kræver kundelogin";
        case DocumentAuthorizationUnauthorizedReason._2:
          return "Kræver kunde- eller leverandørlogin";
        case DocumentAuthorizationUnauthorizedReason._3:
          return "Der opstod en fejl. Kontakt SKI Kundeservice, hvis problemet fortsætter";
        default:
          return "Der opstod en fejl. Kontakt SKI Kundeservice, hvis problemet fortsætter";
      }
    },
    renderAuthErrorModal() {
      this.showAuthErrorModal = true;
    },
    renderErrorModal() {
      this.showErrorModal = true;
    },
    closeAuthErrorModal() {
      this.showAuthErrorModal = false;
    },
    closeErrorModal() {
      this.showErrorModal = false;
    },
  },
});
