











































import Vue from "vue";
import { replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem } from "@/lib/utilities";

import Arrow from "@/lib/assets/icon/arrow.svg";
import Burger from "@/lib/assets/icon/burger.svg";
import Calendar from "@/lib/assets/icon/calendar.svg";
import Checkmark from "@/lib/assets/icon/checkmark.svg";
import Close from "@/lib/assets/icon/close.svg";
import Download from "@/lib/assets/icon/download.svg";
import Environment from "@/lib/assets/icon/environment.svg";
import Event from "@/lib/assets/icon/event.svg";
import Link from "@/lib/assets/icon/link.svg";
import Lock from "@/lib/assets/icon/lock.svg";
import Mail from "@/lib/assets/icon/mail.svg";
import Notification from "@/lib/assets/icon/notification.svg";
import Open from "@/lib/assets/icon/open.svg";
import Play from "@/lib/assets/icon/play.svg";
import Print from "@/lib/assets/icon/print.svg";
import Profile from "@/lib/assets/icon/profile.svg";
import Search from "@/lib/assets/icon/search.svg";
import Status from "@/lib/assets/icon/status.svg";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";

import TeaserImage from "./TeaserImage.vue";
import { resetHashtagValue } from "@/lib/urlUtilities";

interface Data {
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagteaserId: string;
}

export default Vue.extend({
  components: {
    Arrow,
    Burger,
    Calendar,
    Checkmark,
    Close,
    Download,
    Environment,
    Event,
    Link,
    Lock,
    Mail,
    Notification,
    Open,
    Play,
    Print,
    Profile,
    Search,
    Status,
    SkiDefaultButton,
    TeaserImage
  },
  props: {
    variant: { type: String, default: "light" },
    buttonVariant: { type: String, default: "primary" },
    iconVariant: { type: String, default: "primary" },
    isRightAligned: { type: Boolean, default: false },
    title: { type: String, required: true },
    description: { type: String, default: null },
    imageUrl: { type: String, default: null },
    imageAltText: { type: String, default: null },
    icon: { type: String, default: "environment" },
    iconVisible: { type: Boolean, default: false },
    linkLabel: { type: String, default: null },
    linkUrl: { type: String, default: null },
    linkTarget: { type: String, default: null }
  },
  data(): Data {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagteaserId: "#" + this.title.toLowerCase()
    };
  },
  computed: {
    circleIcon(): string {
      if (this.variant === this.iconVariant) {
        return "circle-icon-info-opacity";
      }
      return `circle-icon-${this.iconVariant}`;
    },
    btnColor(): string {
      if (this.variant === this.buttonVariant) {
        return "info-opacity";
      }
      return `${this.buttonVariant}`;
    }
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagteaserId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    } 
  },
  methods: {
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var teaserId = this.hashtagteaserId.split("#")[1];
      scrollToAnchorItem(teaserId);
    }
  }
});
