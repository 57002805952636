

















import Vue from "vue";
import Arrow from "@/lib/assets/icon/arrow.svg";
export default Vue.extend({
  components: {
    Arrow,
  },
  props: {
    path: String,
    type: {
      type: String,
      default() {
        return "button";
      },
    },
    classes: {
      type: String,
      default() {
        return "";
      },
    },
    variant: {
      type: String,
      default() {
        return "primary";
      },
    },
    iconPosition: {
      type: String,
      default() {
        return "right";
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    btnClass() {
      return this.iconPosition === "left" ? "btn-ski-style btn-icon-left" : "btn-ski-style";
    },
  },
});
