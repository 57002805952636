export const getSearchParams = (url: Location): URLSearchParams => {
  return new URL(url.toString()).searchParams;
};

export const getSearchParam = (url: Location, param: string): string => {
  const searchParamm = getSearchParams(url);

  const documentId = searchParamm.get(param);
  return documentId || "";
};

export function cleanupSearchHistory(params: string[]): void {
  const url = new URL(location.href);
  params.forEach((param: string) => {
    url.searchParams.delete(param);
  });
  window.history.replaceState({}, document.title, url.toString());
}

export function resetHashtagValue(): void {
  // We remove the hashtag value after scrolling, as navigation to another page and then using the back button (or similar) otherwise will trigger anchor scrolling again by scrolling to the top of the page and then to the anchor tag.
  // This enables smooth redirect to the scrolled location using the back button by taking advantage of browser's location, rather than reusing hashtag navigation
  // This is used instead of window.location.hash = '', as this is equivalent to #_top and causes the browser to scroll to the top of the window
  history.pushState(
    "",
    document.title,
    window.location.pathname + window.location.search
  );
}

export function createCacheableImageUrl(imageUrl : string) : string {
  return `${imageUrl}?rxy=`; // query string trigger ImageSharp.Web cache-control response header
}

