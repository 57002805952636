import { FilterOption } from "@/lib/models/filterOption.model";
import { SortOption } from "@/lib/models/sortOption.model";

export const STATUS_UPDATE_FILTER_OPTIONS: FilterOption[] = [
  {
    label: "Type",
    key: "agreementTypes",
    filter: "type",
    value: ""
  },
  {
    label: "Aftaleområde",
    key: "skiGroups",
    filter: "group",
    value: ""
  },
  {
    label: "Indkøbsprogram",
    key: "skiSourcingprograms",
    filter: "sourcingPrograms",
    type: "radio",
    value: ""
  },
];

export const STATUS_UPDATE_SORT_OPTIONS: SortOption[] = [
  { label: "Nyeste opdatering", key: "latestStatusUpdateUtc" },
  { label: "Aftaler A-Å", key: "displayNameShort" },
  { label: "Aftaler numerisk", key: "agreementId" },
];

export const AGREEMENT_TYPES: { name: string }[] = [
  { name: "Udbud" },
  { name: "Aftale" },
  { name: "Tidligere aftale" },
]; 