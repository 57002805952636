























import Vue from "vue";
import {
  replaceHexWithNordicLettersAndWhitespace,
  scrollToAnchorItem,
} from "@/lib/utilities";
import { resetHashtagValue } from "@/lib/urlUtilities";
import {
  addMonsidoTrackingToElement,
  getDocumentLinksFromRef,
} from "@/lib/utilities/monsidoUtilities";

export default Vue.extend({
  props: {
    headline: { type: String, default: null },
    removeBottomMargin: { type: Boolean, default: false },
    factboxHeadline: { type: String, default: null },
    showFactbox: { type: Boolean, default: false },
  },
  data() {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(
        window.location.hash
      ),
      hashtagItem: "",
      hashtagFreeTextId: "#" + this.headline.toLowerCase(),
    };
  },
  mounted() {
    const div = this.$refs.htmlContainer as HTMLAnchorElement;
    const docLinks = getDocumentLinksFromRef(div);

    // Add the Monsido tracking event on clicks for each unique link to Umbraco documents, if a link has not been added
    docLinks.forEach((link) => {
      if (link !== undefined) {
        addMonsidoTrackingToElement(
          link,
          "Download",
          "Download af Umbraco-dokument",
          `Download af dokument: ${
            link.title !== null ? link.title : link.text
          }`
        );
      }
    });

    var h4 = document.getElementsByTagName("h4");
    h4.forEach((element) => {
      element.id = element.innerText.toLowerCase();
    });

    var h5 = document.getElementsByTagName("h5");
    h5.forEach((element) => {
      element.id = element.innerText.toLowerCase();
    });

    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(
      this.hashtagFromWindow
    );
    if (this.hashtagItem === this.hashtagFreeTextId) {
      resetHashtagValue();
      this.scrollToAnchorItem(this.hashtagFreeTextId);
    } else {
      h4.forEach((element) => {
        if (element.id === this.hashtagItem.split("#")[1]) {
          resetHashtagValue();
          this.scrollToAnchorItem(this.hashtagItem);
        }
      });

      h5.forEach((element) => {
        if (element.id === this.hashtagItem.split("#")[1]) {
          resetHashtagValue();
          this.scrollToAnchorItem(this.hashtagItem);
        }
      });
    }
  },
  methods: {
    scrollToAnchorItem(freetextId: string) {
      // Remove hashtag from prop to get the id of the element
      freetextId = freetextId.split("#")[1];
      scrollToAnchorItem(freetextId, 0, 24);
    },
  },
});
