





























import Vue from "vue";
import LazyLoadedImage from "@/lib/components/LazyLoadedImage.vue"

export default Vue.extend({
  components: { LazyLoadedImage },
  props: {
    role: { type: String, required: false, default: null },
    roleDescription: { type: String, required: false, default: null },
    name: { type: String, required: true },
    title: { type: String, required: false, default: null },
    email: { type: String, required: false, default: null },
    phone: { type: String, required: false, default: null },
    photoUrl: { type: String, required: false, default: null },
  },
});
