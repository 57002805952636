





































import Vue, { PropType } from "vue";
import ContactCard from "@/lib/components/ContactCard.vue";
import { EmployeeListViewDto } from "@/lib/api/APIService";
import {
  replaceHexWithNordicLettersAndWhitespace,
  scrollToElement,
} from "@/lib/utilities";

interface EmployeeOverviewItem {
  skiDescription: string;
  skiName: string;
  skiOrderNummer: string;
  employees: EmployeeItem[];
}

interface EmployeeItem extends EmployeeListViewDto {
  [key: string]: any;
}

interface Data {
  hashtagFromWindow: string;
}

export default Vue.extend({
  components: {
    ContactCard,
  },
  props: {
    departments: {
      type: Array as PropType<EmployeeOverviewItem[]>,
      required: true,
    },
    employeesLoaded: { type: Boolean, default: false },
  },
  data(): Data {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(
        window.location.hash
      ).toLowerCase(),
    };
  },
  watch: {
    employeesLoaded() {
      if (this.hashtagFromWindow && this.employeesLoaded) {
        this.$nextTick(() => {
          this.scrollToAnchorItem();
        });
      }
    },
  },
  methods: {
    scrollToAnchorItem() {
      // Consider moving this logic (and in the method scrollToElement from utilities to a composable when code is upgraded to Vue 3), to make is generic and reusable for other components

      // Remove hashtag from prop to get the id of the element
      const elementId = this.hashtagFromWindow.split("#")[1].trim();
      const element = document.getElementById(elementId.trim());
      if (element) {
        scrollToElement(element);
      }
    },
  },
});
