












import Vue from "vue";

export default Vue.extend({
  props: {
    requiredRole: { type: String, default: "" },
  },
  data() {
    return {
      hasRequiredRole: !this.requiredRole,
    };
  },
  async created() {
    await this.updateHasRequiredRole();
  },
  methods: {
    async updateHasRequiredRole() {
      if (this.hasRequiredRole) return;

      const roles = this.$store.getters.roles;
      if (roles && Array.isArray(roles)) {
        this.hasRequiredRole = roles.includes(this.requiredRole);
      } else if (roles && typeof roles === "string") {
        this.hasRequiredRole = roles === this.requiredRole;
      }
    },
  },
});
