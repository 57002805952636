var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isEmpty(_vm.filteredSubAgreements))?_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{class:_vm.item.catalogue || _vm.item.showDisButton ? ' pb-5 mb-5 mt-4' : 'pb-5 mb-5'},[_c('b-col',{attrs:{"lg":"2","offset-lg":"1"}},[_c('h3',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.subAgreementsTitle))]),_vm._v(" "+_vm._s(_vm.subAgreementsDescription)+" ")]),_c('b-col',{attrs:{"lg":"7","offset-lg":"1"}},_vm._l((_vm.filteredSubAgreements),function(agreement,index){return _c('div',{key:index,staticClass:"px-2",class:index !== 0 ? 'mt-4' : null},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.getSubAgreementUrl(agreement[_vm.$config.agreement.subAgreement.id])}},[_c('b-row',{class:("bg-light rounded-lg py-3 px-md-5 " + (!_vm.isTender(agreement.overallStatusName) ? 'py-md-5' : '')),style:(_vm.isTender(agreement.overallStatusName)
                ? 'background-color: white !important; color: #63756b !important; border-style: solid; padding-top: 2rem !important; padding-bottom: 2rem !important'
                : '')},[_c('b-col',{staticClass:"text-container md-3",style:(_vm.isTender(agreement.overallStatusName)
                  ? 'padding-top: 1rem !important;'
                  : '')},[_vm._v(" Delaftale "+_vm._s(agreement[_vm.$config.agreement.subAgreement.number])+" "),(_vm.isTender(agreement.overallStatusName))?_c('p',{staticClass:"ml-2",attrs:{"md":"2"}},[_vm._v(" ( Udbud ) ")]):_vm._e()]),_c('b-col',{staticClass:"text-container",attrs:{"md":"6"}},[_vm._v(" "+_vm._s(agreement[_vm.$config.agreement.subAgreement.name])+" ")]),(_vm.renderTrafficLight)?_c('b-col',{staticClass:"align-application-text",style:(_vm.isTender(agreement.overallStatusName)
                  ? 'padding-top: 1rem !important;'
                  : ''),attrs:{"md":"3"}},[_c('traffic-light',{key:agreement.customerApplication[
                    _vm.$config.agreement.application.type
                  ],staticClass:"mr-2",attrs:{"type":agreement.customerApplication[
                    _vm.$config.agreement.application.type
                  ],"is-white-background":false}}),_vm._v(" "+_vm._s(agreement.customerApplication[ _vm.$config.agreement.application.label ])+" ")],1):_vm._e(),_c('b-col',{staticClass:"text-md-right arrow-container",class:_vm.renderTrafficLight ? 'col-md-1' : 'col-md-3'},[_c('link-arrow',{staticClass:"svg arrow-svg"})],1)],1)],1)])}),0)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }