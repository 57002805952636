




























import Vue from "vue";
import SkiImage from "@/lib/components/SkiImage.vue";
import RichText from "@/lib/components/shared/RichText.vue";

export default Vue.extend({
  components: { SkiImage, RichText },
  props: {
    quote: { type: String, required: true },
    imageUrl: { type: String, default: null },
    imageAltText: { type: String, default: null },
    name: { type: String, default: null },
    title: { type: String, default: null },
    bw: { type: Boolean, default: false },
  },
});
