





















import Vue from "vue";
import SkiImage from "@/lib/components/SkiImage.vue";
import { replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem } from "@/lib/utilities";
import { resetHashtagValue } from "../urlUtilities";

interface Data {
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagimageCaptionId: string;
}

export default Vue.extend({
  components: { SkiImage },
  props: {
    imageUrl: { type: String, required: true, default: null },
    imageMode: {
      type: String,
      required: false,
      default: "Landscape - Full width"
    },
    imageCaption: { type: String, required: false, default: null },
    imageAltText: { type: String, required: false, default: "" },
    keys: { type: Object, required: true }
  },
  data(): Data {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagimageCaptionId: "#" + this.imageCaption.toLowerCase()
    };
  },
  computed: {
    aspect(): string {
      return this.imageMode.includes(this.keys.portrait) ? "3:4" : "16:9";
    },
    columns(): {} {
      const columns = { offset: "3", image: "6" };

      if (this.imageMode.includes(this.keys.overflow)) {
        columns.offset = "2";
        columns.image = "8";
      }

      if (this.imageMode.includes(this.keys.underflow)) {
        columns.offset = "4";
        columns.image = "4";
      }

      return columns;
    }
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagimageCaptionId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    } 
  },
  methods: {
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var imageCaptionId = this.hashtagimageCaptionId.split("#")[1];
      scrollToAnchorItem(imageCaptionId);
    }
  }
});
