




import Vue from "vue";
import dayjs from "dayjs";

import SkiTimeline from "@/lib/components/SkiTimeline.vue";
import { formatDate } from "@/lib/dateUtilites";
import { logger } from "@/lib/utilities";

export default Vue.extend({
  components: { SkiTimeline },
  props: {
    agreementType: {type: String, required:true}
  },
  data() {
    return {
      milestones: []
    };
  },
  created() {
    const {
      key,
      id,
      title,
      content,
      date,
      altDate
    } = this.$config.agreement.timeline;
    // Map API data to Timeline
    const items = this.$root.$data.item[key];
    if (!items) {
      logger.error("Timeline incorrect api key:", key);
      return;
    }

    this.milestones = items.map((item: any) => ({
      id: item[id],
      title: item[title],
      content: item[content],
      date: item[altDate] || formatDate(item[date]),
      status: dayjs(item[date]).isBefore(dayjs()) ? "complete" : "upcoming"
    }));
  }
});
