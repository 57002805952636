
















































import Accordion from "@/lib/components/Accordion.vue";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import { downloadTableCSV, isEmpty } from "@/lib/utilities";
import Vue from "vue";
import { isDateAfterToday } from "@/lib/dateUtilites";

interface Data {
  org: any;
  agreements: any[] | null;
  apiGroupKeys: any;
}

export default Vue.extend({
  components: { 
    Accordion, 
    SkiDefaultButton },
  props: {
    itemName: { type: String, required: true },
    itemId: {type: String, required: true},
    itemCustomers: {type: String, required: true},
    customersEndpoint: {type: String, required: true},
    status: { type: String, required: true },
    fields: { type: Array, required: true },
    linkItemUrl: { type: String, required: true }
  },
  data(): Data {
    return {
      org: this.$root.$data.org,
      agreements: null,
      apiGroupKeys: {
        "downloadButtonLabel": "Hent listen til Excel",
        "tableId": "customerList",
        "filename": "Kunder på mine aftaler",
        "orgId": "organizationId"
      }
    };
  },
  created() {
    const { key, status } = this.$config.organization.agreement;
    const agreements = this.org[key];
    const mainAgreementIds: string[] = [];
    // Refactor this filtering method below, using in multiple components, e.g. OrganizationWrapper as filterList
    if (agreements && Array.isArray(agreements)) {
      this.agreements = agreements.filter(
        (agr) => isDateAfterToday(agr.terminationDate)
      );
      
     // Filter out main agreements with subagreements, since they have no customers
      this.agreements.forEach(function(agreement) {
        if (agreement.mainAgreementId!== null){
          mainAgreementIds.push(agreement.mainAgreementId)
        }
      });
      this.agreements = this.agreements.filter(
        (agreement: any ) => !mainAgreementIds.includes(agreement.agreementId)
      )

      this.agreements = this.agreements.sort((a, b) => a.agreementId - b.agreementId);
    }
  },
  methods: {
    isEmpty,
    downloadTableCSV,
    size(list: any) {
      if (isEmpty(list)) return 0;
      return list.length;
    },
    itemLink(item: any): string {
      const { organizationUrl } = this.$root.$data;
      return `${organizationUrl}?id=${item[this.apiGroupKeys.orgId]}`;
    },
    getCustomerList(agreementId: string, index: number){
      setTimeout(() => {
        this.displaySpinner(index, agreementId)
      }, 1000);

      this.fetchCustomers(agreementId, index);
    },
    async fetchCustomers(agreementId: string, index: number) {       
     var customerList = await this.$api.AgreementsRepository.getCustomers(agreementId);
     this.hideSpinner(agreementId);
      
      const list: any[] = [];
      if (customerList != null && Array.isArray(this.agreements)){
        if(!isEmpty(customerList)) { 
          customerList.forEach((application: any) => {
            list.push(...application.customerList)        
          })
        }
        this.$set(
          this.agreements[index],
          this.itemCustomers,
          list
        );
      }
    },
    displaySpinner(index: number, agreementId: string){
      if(Array.isArray(this.agreements)){
        if(this.agreements[index].customerList === undefined){
          const el = document.getElementById(agreementId);
          if(el) el.classList.add("display");
        }
      }
    },
    hideSpinner(agreementId: string){
      const el = document.getElementById(agreementId);
      if(el) el.classList.remove("display");
    }
    
  }
});
