
















import Vue from "vue";
import SkiImage from "@/lib/components/SkiImage.vue";
import { lazyLoading } from "@/lib/IntersectionObserver";
import { createCacheableImageUrl } from "../urlUtilities";

export default Vue.extend({
  components: { SkiImage },
  inject: ["lazyLoadImages"],
  props: {
    aspect: { type: String, default: "16:9" },
    maxWidth: { type: String, default: "auto" },
    imageUrl: { type: String, required: true },
    imageIsBlackAndWhite: { type: Boolean, default: false },
    imageClass: { type: String, default: "" },
    altText: { type: String, default: "" },
    circle: { type: Boolean, default: false },
  },
  data: function () {
    return {
      photoUrl: "",
      browserSupportsLazyLoading: lazyLoading.browserSupportsLazyLoading(),
      observer: {} as IntersectionObserver,
      shouldLazyloadImages: this.lazyLoadImages,
      isLoadingImage: false,
      hasValidImage: false,
    };
  },
  computed: {
    readyToLazyload(): boolean {
      return this.shouldLazyloadImages && this.browserSupportsLazyLoading;
    },
  },
  created() {
    if (this.readyToLazyload) {
      // Create an observer to monitor DOM changes
      this.observer = lazyLoading.setupIntersectionObserver(this.LoadImage, {
        root: null,
        rootMargin: "0% 0% 100% 0%", // SET BOTTOM OF VIEWPORT TO DOUBLE THE ACTUAL SIZE. WILL LOAD IMAGE BEFORE IT ACTUALLY ENTERS THE VIEWPORT
        threshold: 0, //0.5
      });
    } else {
      // Not using lazy loading. Show the image
      this.fetchImage();
    }
  },
  mounted() {
    if (this.readyToLazyload) {
      this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered

        // Set the observer to monitor the component root element
        this.observer.observe(this.$el);
      });
    }
  },
  methods: {
    LoadImage(
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.fetchImage();
          // Stop observing since we have now passed the actual image to show, to the SkiImage component
          observer.unobserve(entry.target);
        }
      });
    },
    GenerateImageUrl(): string {
      return createCacheableImageUrl(this.imageUrl);
    },
    fetchImage() {
      const img = new Image();
      img.onload = () => {
        this.isLoadingImage = false;
        this.photoUrl = this.imageUrl;
        this.hasValidImage = true;
      };
      img.onerror = () => {
        this.isLoadingImage = false;
      };
      this.isLoadingImage = true;
      img.src = this.GenerateImageUrl();
    },
  },
});
