





















import MySkiSidebar from "@/lib/partials/my-ski/MySkiSidebar.vue";

import Vue from "vue";

export default Vue.extend({
  components: { MySkiSidebar },
  props: {
    sidebarContent: { type: Array, required: true },
  },
  data() {
    return {
      org: null,
      showHearingWidget: false,
      showCommitmentWidget: false,
    };
  },
  watch: {
    "$store.state.profile": {
      immediate: true,
      async handler(profile) {
        if (!profile?.organizationId || 
            (this.org && this.org.id === profile.organizationId)) {
          return;
        }
        await this.fetchOrganization(profile.organizationId);
      },
    },
  },
  async mounted() {
    this.$on("show-hearing-widget", (e: boolean) => {
      this.showHearingWidget = e;
    });
    this.$on("show-commitment-widget", (e: boolean) => {
      this.showCommitmentWidget = e;
    });
  },
  methods: {
    async fetchOrganization(orgId: string) {
      var organization = await this.$api.organizationID.get(orgId);
      if (organization) {
        this.org = organization;
      }
      this.$root.$data.org = this.org;
    },
  },
});
