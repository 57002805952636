
















import Vue from "vue";
import { replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem } from "@/lib/utilities";
import { resetHashtagValue } from "../urlUtilities";

interface Data {
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagFactboxId: string;
}

export default Vue.extend({
  props: {
    headline: { type: String, default: null },
    variant: { type: String, default: "lightGrey" }
  },
  data(): Data {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagFactboxId: "#" + this.headline.toLowerCase()
    };
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow);
    if (this.hashtagItem === this.hashtagFactboxId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    }    
  },
  methods: {
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var factboxId = this.hashtagFactboxId.split("#")[1];
      scrollToAnchorItem(factboxId);
    }
  }
});
