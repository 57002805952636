





















































































































































import Vue, { PropType } from "vue";

import SkiFilter from "@/lib/components/SkiFilter.vue";
import TrafficLight from "@/lib/components/TrafficLight.vue";
import Open from "@/lib/assets/icon/open.svg";
import Closed from "@/lib/assets/icon/minus.svg";
import { formatDate } from "@/lib/dateUtilites";
import {
  isDarkColor,
  isEmpty,
  qsParams,
  groupBy,
  getValue,
} from "@/lib/utilities";
import qs from "qs";
import { AgreementItem } from "../models/agreementItem.model";
import { Field } from "@/lib/models/field.model";
import { FilterOption } from "@/lib/models/filterOption.model";
import { SortOption } from "../models/sortOption.model";
import { AgreementsContainsTrafficlight } from "../agreementUtilities";

interface QueryData {
  filters: FilterData[];
  sortBy?: string;
}

interface FilterData {
  key: string;
  value: string;
}

interface Data extends QueryData {
  searchQuery: string;
  filteredAgreements: AgreementItem[];
  displayedAgreements: AgreementItem[];
  sortDesc: boolean;
  displayAllAgreements: boolean;
  displayShowMoreLessButton: boolean;
  oldFilters: { key: string; value: string }[];
  width: number;
  hasGradient: boolean;
}

export default Vue.extend({
  components: {
    SkiFilter,
    TrafficLight,
    Open,
    Closed,
  },
  props: {
    data: { type: Object, default: null },
    tableVariant: { type: String, default: "" },
    filterVariant: { type: String, default: "" },
    itemId: { type: String, default: "" },
    items: { type: Array as PropType<AgreementItem[]>, default: null },
    fields: { type: Array, default: null },
    sortOptions: { type: Array, default: null },
    filterOptions: { type: Array, default: null },
    linkItemUrl: { type: String, default: "" },
    linkItemUrls: { type: Array, default: null },
    linkItemKey: { type: String, default: "" },
    toggledMaxElements: { type: Number, default: 0 },
    hasSearchInput: { type: Boolean, default: false },
    defaultSortBy: { type: String, default: "" },
    tableClass: { type: String, default: "" },
    emptyTableText: { type: String, default: "" },
    addTableGradient: { type: Boolean, default: false },
    tableGradientVisibility: { type: String, default: "" },
    isPrinting: { type: Boolean, default: false },
    linkWidth: { type: Number, default: 10000 },
    tableIndex: { type: String, default: "" },
    willUpdateQueryParams: { type: Boolean, default: true },
  },
  data(): Data {
    return {
      filters: [],
      displayShowMoreLessButton: true,
      searchQuery: "",
      filteredAgreements: this.items,
      displayedAgreements: [],
      displayAllAgreements: false,
      sortBy: "",
      sortDesc: false,
      oldFilters: [],
      width: this.linkWidth,
      hasGradient: this.addTableGradient,
    };
  },
  watch: {
    filters(newFilters) {
      this.updateQueryParams({ filters: newFilters, sortBy: this.sortBy });
    },
    sortBy(newSortBy) {
      this.updateQueryParams({ filters: this.filters, sortBy: newSortBy });
      this.sortDesc = newSortBy === "latestStatusUpdateUtc";
    },
    items: function (newVal, oldVal) {
      this.updateQueryParams({ filters: this.filters, sortBy: this.sortBy });
    },
    fields: function () {
      // TODO this is ugly - fix it when there is time. There should be no field which actually is 'index's)
      this.fields.unshift("index");
    },
    isPrinting: function (newIsPrinting) {
      if (newIsPrinting) {
        this.oldFilters = this.filters;
        this.setFilter([]);
      } else {
        this.setFilter(this.oldFilters);
        this.oldFilters = [];
      }
    },
    linkWidth: function (newWidth) {
      this.width = newWidth;
    },
    addTableGradient: function (newGradient) {
      this.hasGradient = newGradient;
    },
  },
  computed: {
    showTrafficLight(): boolean {
      return AgreementsContainsTrafficlight(this.filteredAgreements);
    },
  },
  async created() {
    // TODO this is ugly - fix it when there is time. There should be no field which actually is 'index'
    this.fields.unshift("index");

    // Use query params filters and sortBy
    if (!isEmpty(qsParams())) {
      const { sortBy, filters } = qsParams();

      if (sortBy) {
        this.sortBy = sortBy;
        this.sortDesc = this.sortBy === "latestStatusUpdateUtc";
      }

      if (filters && typeof filters === "object") {
        this.filters = filters
          .filter((i: FilterOption) => !!i)
          .map((i: string) => {
            return this.getFilter(i);
          });
      } else if (filters && typeof filters == "string") {
        this.filters.push(this.getFilter(filters));
      }
    }

    // Default sortBy
    if (isEmpty(this.sortBy) && this.defaultSortBy) {
      this.sortBy = this.defaultSortBy;
    }

    if (isEmpty(this.sortBy) && !this.defaultSortBy) {
      this.sortBy = (((this.sortOptions || [])[0] || {}) as SortOption).key;

      if (!isEmpty(this.sortOptions)) {
        if (this.showTrafficLight) {
          const customerAppl = (i: SortOption) =>
            i.key === (this.sortOptions as SortOption[])[0].key;
          const applSortBy = (this.sortOptions as SortOption[]).find(
            customerAppl
          );
          if (!isEmpty(applSortBy)) {
            this.sortBy = ((applSortBy || {}) as SortOption).key;
          }
        }
      }
    }

    this.sortDesc = this.sortBy === "latestStatusUpdateUtc";
  },
  methods: {
    formatDate,
    isDarkColor,
    isEmpty,
    cell(key: string) {
      return `cell(${key})`;
    },
    getFilter(filter: string): FilterData {
      const idx = filter.indexOf(".");
      return {
        key: filter.substring(0, idx) || "",
        value: filter.substring(idx + 1) || "",
      } as FilterData;
    },
    setFilter(filters: Data["filters"]) {
      this.filters = filters;
    },
    setSorting(sortBy: Data["sortBy"]) {
      this.sortBy = sortBy;
      this.sortDesc = this.sortBy === "latestStatusUpdateUtc";
    },
    toggleList() {
      this.displayAllAgreements = !this.displayAllAgreements;
      this.reloadDisplayedAgreements();
    },
    onFilter(row: any, filters: Data["filters"]): boolean {
      const filterGroup = groupBy(filters, "key");
      return Object.keys(filterGroup).every((key) => {
        const groupFilters = filterGroup[key];

        return groupFilters.some((filter: FilterOption) => {
          let content = row[filter.key];
          if (content == null) return false;

          if (Array.isArray(content)) {
            if (content.length > 0 && typeof content[0] === "object") {
              content = content.map((i) => i.name || i.value);
            }
            return content.includes(filter.value);
          } else {
            return content === filter.value;
          }
        });
      });
    },
    // TODO: Remove when backend does not send "PREFIX>"
    stripPrefix(value?: string) {
      return (value || "").replace(/.*>/, "");
    },
    fieldsFilter(fields: Field[]): Field[] {
      if (!Array.isArray(fields)) return fields;
      // TODO: Change to check orgType for Kunde, if application role for Trafficlight is not used
      if (!this.showTrafficLight) {
        fields = fields.filter((i) => !i.customer);
      }
      fields = fields.filter((x) => !x.extraFields);
      return fields;
    },
    updateQueryParams({ sortBy, filters: _filters }: QueryData) {
      let filters;
      if (!isEmpty(_filters)) {
        filters = Object.keys(_filters).map(
          (i: any) => _filters[i].key + "." + _filters[i].value
        );
      }
      const qsString = qs.stringify(
        { ...qsParams(), sortBy, filters },
        { addQueryPrefix: true, arrayFormat: "repeat" }
      );
      if (this.willUpdateQueryParams) {
        window.history.replaceState(null, "", qsString); // Don't add each query parameter update to the browser's history
      }
      this.filterAllAgreements();
    },
    filteredItemsCountLargerThanToggleMax(): boolean {
      var filteredItems = this.items.filter((item) =>
        this.onFilter(item, this.filters)
      );
      filteredItems = this.filterByQuery(filteredItems as AgreementItem[]);
      if (filteredItems.length > this.toggledMaxElements) {
        return true;
      }
      return false;
    },
    filterItems(computedItems: AgreementItem[]): AgreementItem[] {
      if (this.hasSearchInput) {
        computedItems = this.filterByQuery(computedItems);
      }

      return computedItems.filter((item) => this.onFilter(item, this.filters));
    },
    sortItems(computedItems: AgreementItem[]): AgreementItem[] {
      return computedItems.sort((a, b) => this.sortingFunction(a, b));
    },
    sliceItems(computedItems: AgreementItem[]): AgreementItem[] {
      return computedItems.slice(0, this.toggledMaxElements);
    },
    reloadDisplayedAgreements() {
      if (this.filteredAgreements.length <= this.toggledMaxElements) {
        this.displayShowMoreLessButton = false;
      }

      if (
        this.toggledMaxElements > 0 &&
        !this.displayAllAgreements &&
        this.displayShowMoreLessButton
      ) {
        this.displayedAgreements = this.filteredAgreements.slice(
          0,
          this.toggledMaxElements
        );
      } else {
        this.displayedAgreements = this.filteredAgreements;
      }
    },
    searchSubmit(searchQuery: Data["searchQuery"]) {
      this.searchQuery = searchQuery;
      this.filterAllAgreements();
    },
    filterByQuery(filteredItems: AgreementItem[]) {
      if (!this.searchQuery) return filteredItems;
      return filteredItems.filter((item: AgreementItem) => {
        return (
          this.itemQueryFiltering(item) || this.itemDisplayNumberCheck(item)
        );
      });
    },
    itemQueryFiltering(item: AgreementItem): boolean {
      var sub = getValue("subAgreementName", item);
      if (sub) {
        return sub.toLowerCase().includes(this.searchQuery.toLowerCase());
      } else {
        // If displayNameShort is null then search in displayNameLong instead
        const displayName = item.displayNameShort
          ? item.displayNameShort
          : item.displayNameLong;
        return displayName
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      }
    },
    itemDisplayNumberCheck(item: AgreementItem): boolean {
      // If displayNumers is null then look for similar numbers in first five characters of displayNameLong
      let number = item.displayNumbers || item.displayNameLong.substring(0, 5);
      if (!this.searchQuery.includes(".")) number = number.replace(".", "");
      return number.includes(this.searchQuery);
    },
    async filterAllAgreements() {
      const { key, type, types } = this.$config.agreement.application;
      if (!Array.isArray(this.items)) return null;

      var login = this.$store.getters.isLoggedIn;
      let mappedAgreements = this.items.map((item: any) => {
        if (login) {
          const application = item[key];
          if (!application) {
            return item;
          }
          application.index = 1000;
          const typeKey = application[type];
          if (!typeKey) return item;
          const typeValue = types[typeKey.toLowerCase()];
          if (!typeValue) return item;
          application.index = typeValue.id;
        }
        return item;
      });
      mappedAgreements = this.sortItems(mappedAgreements);
      mappedAgreements = this.filterItems(mappedAgreements);
      this.$emit("filtered-agreements", {
        filteredAgreements: mappedAgreements,
      });

      this.filteredAgreements = mappedAgreements;
      this.reloadDisplayedAgreements();
    },
    itemLink(item: any): string {
      var itemUrl = this.linkItemUrls.find(
        (stat: any) => stat.key === item[this.linkItemKey]
      );
      if (itemUrl === undefined) {
        itemUrl = this.linkItemUrls.find(
          (stat: any) =>
            stat.key === this.$config.organization.agreement.status.agreement
        );
      }
      return `${(itemUrl as any).url}?id=${item[this.itemId]}`;
    },
    linkStyle(): string {
      return `width: ${this.width}px;`;
    },
    sortingFunction(a: AgreementItem, b: AgreementItem) {
      var valA;
      var valB;
      if ((this.sortBy as string).includes(".")) {
        var sortByLevels = (this.sortBy as string).split(".");
        var tempA = a;
        var tempB = b;
        for (var i = 0; i < sortByLevels.length; i++) {
          tempA = getValue(sortByLevels[i], tempA);
          tempB = getValue(sortByLevels[i], tempB);
        }
        valA = tempA;
        valB = tempB;
      } else {
        valA = getValue(this.sortBy as string, a);
        valB = getValue(this.sortBy as string, b);
      }

      if (!!valA && !!valB) {
        if (valA === valB) return 0;
        if (valA > valB) return 1;
        return -1;
      } else if (!valA) {
        return 1;
      } else if (!valB) {
        return -1;
      }
      return 0;
    },
  },
});
