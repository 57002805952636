























import Vue, { PropType } from "vue";
import SmallArrow from "@/lib/assets/smallicon/arrow.svg";

interface DropdownItem {
  key: any;
  label: string;
}

export default Vue.extend({
  components: { SmallArrow },
  props: {
    filterOptions: { type: Array as PropType<DropdownItem[]>, required: true },
    selected: { type: String, default: "" },
    classes: { type: String, default: "" },
    variant: { type: String, default: "outline-light-opacity d-print-none" },
  },
  methods: {
    selectOption(option: DropdownItem) {
      this.$emit("select", option);
    },
  },
});
