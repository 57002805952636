










import Vue from "vue";
import { setSkiStyle } from "@/lib/utilities";
import Auth from "@/lib/components/auth/Auth.vue";

export default Vue.extend({
  components: { Auth },
  mounted() {
    setSkiStyle();
    setTimeout(setSkiStyle, 1);
  },
});
