
















import Vue from "vue";
import InfoBox from "@/lib/components/InfoBox.vue";
import {
  alertMessage,
  qsParams,
  setHighestPriorityCustomerApplication,
} from "@/lib/utilities";
import { isEmpty } from "../../utilities";

interface Data {
  id: string;
  documentId: string;
  item: {} | null;
  infoDescription: string;
  agreementType: string;
}

export default Vue.extend({
  components: { InfoBox },
  data(): Data {
    return {
      id: "",
      documentId: "",
      item: null,
      infoDescription: "",
      agreementType: "",
    };
  },
  created() {
    this.id = qsParams().id;
    this.documentId = qsParams().documentId;
    this.fetchAndSetAgreementAPIData();
  },
  methods: {
    async fetchAndSetAgreementAPIData() {
      try {
        var tender = await this.$api.AgreementsRepository.get(this.id);
        var agreementDescriptions =
          await this.$api.AgreementsRepository.getDescription(this.id);
        this.$root.$data.itemDescriptions = agreementDescriptions;
        if (this.redirectToAgreementPage(tender)) return;
        var ite = tender;
        if (ite) {
          if (this.$store.getters.isLoggedIn) {
            setHighestPriorityCustomerApplication(this, ite);
          } else {
            ite[this.$config.agreement.application.activeApplication] = null;
          }
        }

        var infoDescriptionRaw = agreementDescriptions.find(
          (x: { agreementDescriptionName: string }) =>
            x.agreementDescriptionName === "Særlig information om aftalen"
        );
        this.infoDescription =
          infoDescriptionRaw !== undefined
            ? infoDescriptionRaw.agreementDescriptionText
            : "";

        this.item = ite;
        this.$root.$data.item = this.item;
        this.setAgreementType(this.item);
      } catch (err) {
        alertMessage(this);
      }
    },
    setAgreementType(item: any) {
      if (item === null) return;
      // TODO: FIX This should be handled by backend API
      if (item.mainAgreementName) {
        this.$root.$data.agreementType = this.agreementType = "subAgreement";
      } else if (!isEmpty(item.subAgreement)) {
        this.$root.$data.agreementType = this.agreementType =
          "mainAgreementWithSubAgreements";
      } else {
        this.$root.$data.agreementType = this.agreementType = "mainAgreement";
      }
    },
    redirectToAgreementPage(response: any): boolean {
      var { statusField, tenderStatus, agreementPage } = {
        statusField: "overallStatusName",
        tenderStatus: "Kommende",
        agreementPage: "/aftaler/se-aftale/",
      };

      if (response[statusField] !== tenderStatus) {
        window.location.href = `${agreementPage}?id=${this.id}${
          this.documentId !== undefined ? "&documentId=" + this.documentId : ""
        }`;
        return true;
      }
      return false;
    },
  },
});
