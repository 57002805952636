




import Vue from "vue";
import dompurify from "dompurify";

export default Vue.extend({
  props: {
    html: { type: String, required: true },
  },
  computed: {
    sanitizeHtml(): string {
      return dompurify.sanitize(this.html, {
        ADD_TAGS: ["iframe"],
      });
    },
  },
});
