












import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import MySkiRow from "@/lib/partials/my-ski/MySkiRow.vue";
import Vue from "vue";

export default Vue.extend({
  components: { SkiDefaultButton, MySkiRow },
  data() {
    return {
      failsafeTimeout: 0,
      downloadCompleted: false,
    };
  },
  async mounted() {
    var documentId = new URLSearchParams(window.location.search).get(
      "documentId"
    );
    if (documentId) {
      const response = await this.$api.DocumentRepository.getDocument(
        documentId
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        response.fileName || "ski-dokument.pdf"
      );
      document.body.appendChild(link);
      link.click();
      this.downloadCompleted = true;
    }
  },
});
