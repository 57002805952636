



































import Download from "@/lib/assets/icon/download.svg";
import Lock from "@/lib/assets/icon/lock.svg";
import Vue from "vue";
import { AgreementDocumentsListViewDto } from "../api/APIService";

export default Vue.extend({
  components: { Download, Lock },
  props: {
    doc: { type: AgreementDocumentsListViewDto || null, default: null },
    accordionName: { type: String, default: null },
    fileType: { type: String, default: null },
    fileName: { type: String, default: null },
    label: { type: String, default: null },
    monsidoComponent: { type: Boolean, default: false },
    umbracoDocument: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    documentLink(): string {
      if (this.umbracoDocument) {
        return this.doc?._url;
      }
      return `${window.location.origin}/download-dokument?documentId=${this.doc?.documentId}`;
    }
  },
  methods: {
    async handleClickEvent(doc: AgreementDocumentsListViewDto): Promise<void> {
      this.loading = true;
      if (doc === null || doc?.documentId === undefined) {
        this.loading = false;
      } else if (doc.requiresLogin && !this.$store.getters.isLoggedIn) {
        this.$store.dispatch(
          "signIn",
          `${window.location.href}&documentId=${doc?.documentId}`
        );
      } else {
        window.monsido_functions.trackEvent('Download', 'Download af aftale-dokument', `Download af dokument: ${doc?.fileName} - ${doc?.documentId} for aftale: ${doc.agreementId}`, 1);
        this.loading = false;
      }
    },
  },
});
