





























































import Vue from "vue";
import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";
import SkiImage from "@/lib/components/SkiImage.vue";
import PrintButton from "@/lib/components/PrintButton.vue";
import SkiLink from "@/lib/components/SkiLink.vue";
import { formatDate } from "@/lib/dateUtilites";
import { isEmpty } from "@/lib/utilities";
import dayjs from "dayjs";

export default Vue.extend({
  components: { ArrowLeft, SkiImage, PrintButton, SkiLink },
  props: {
    title: { type: String, required: true },
    imageUrl: { type: String, required: true },
    imageAltText: { type: String, required: true },
    returnUrls: { type: Array, required: true },
    returnLabel: { type: String, required: true },
    type: { type: String, required: true },
    date: { type: String, required: true },
    showDate: { type: Boolean, required: true },
    teaser: { type: String, required: true },
    runTimeReturnUrl: { type: Boolean, default: false }
  },
  computed: {
    returnUrl(): string {
      const returnUrl: any = this.returnUrls.find(
        (url: any) => url.key === this.type
      );

      return returnUrl ? returnUrl.url : null;
    }
  },
  mounted() {
    let url = new URL(location.href);
    if (
      this.type === "Nyhed" &&
      !url.searchParams.has("isOldNews") &&
      dayjs(this.date).isBefore(dayjs().subtract(2, "year"))
    ) {
      url.searchParams.append("isOldNews", "true");
      window.history.replaceState({}, document.title, url.toString());
    }
  },
  methods: {
    formatDate,
    isEmpty,
    goBack() {
      window.history.length > 1 ? window.history.back() : window.location.href = "/";
    }
  }
});
