





























































































import Vue from "vue";
import RichText from "@/lib/components/shared/RichText.vue";
import { formatDate } from "@/lib/dateUtilites";
import { qsParams, isEmpty, alertMessage } from "@/lib/utilities";
import Arrow from "@/lib/assets/icon/arrow.svg";

interface Data {
  id: string;
  statusUpdate: {
    name: "";
    category: "";
    startDateUtc: "";
    description: "";
    agreements: [
      {
        agrementId: "";
        name: "";
        displayNameShort: "";
        displayNumbers: "";
        expirationDateUtc: "";
        overallStatusName: "";
      }
    ];
  } | null;
  aftaler: [{}];
  udbud: [{}];
  pageURL: string;
  udbudURL: string;
  aftaleURL: string;
}

export default Vue.extend({
  components: { RichText, Arrow },
  props: {
    apiItemUrl: { type: String, required: true },
  },
  data(): Data {
    return {
      id: "",
      statusUpdate: null,
      aftaler: [{}],
      udbud: [{}],
      pageURL: window.location.origin,
      udbudURL: "/udbud/se-udbud/?id=",
      aftaleURL: "/aftaler/se-aftale/?id=",
    };
  },
  created() {
    const { id } = qsParams();
    this.id = id;

    this.fetchAPIData();
  },
  methods: {
    async fetchAPIData() {
      try {
        const statusUpdate =
          await this.$api.StatusUpdateRepository.getStatusUpdate(this.id);
        if (statusUpdate) {
          this.statusUpdate = statusUpdate;
          this.$root.$data.item = this.statusUpdate;

          if (this.statusUpdate) {
            if (this.statusUpdate.agreements) {
              var i;

              for (i in this.statusUpdate.agreements) {
                if (
                  this.statusUpdate.agreements[
                    i
                  ].overallStatusName.toString() === "Aktiv"
                ) {
                  this.aftaler.push(this.statusUpdate.agreements[i]);
                }
                if (
                  this.statusUpdate.agreements[
                    i
                  ].overallStatusName.toString() === "Kommende"
                ) {
                  this.udbud.push(this.statusUpdate.agreements[i]);
                }
              }
              if (!isEmpty(this.udbud)) {
                this.udbud.shift();
              }
              if (!isEmpty(this.aftaler)) {
                this.aftaler.shift();
              }
            }
          }
        }
      } catch (err) {
        if (err.status === 401) {
          window.location.href = this.loginUrl;
        } else {
          alertMessage(this);
        }
      }
    },
    modalCallbackUrl() {
      let url = window.location.pathname;
      url += window.location.search;
      return url;
    },
    formatDate,
    isEmpty,
  },
  computed: {
    console: () => console,
    loginUrl(): string {
      let url = this.$root.$data.loginUrl;
      url += `?redirect=${encodeURIComponent(this.modalCallbackUrl())}`;
      return url;
    },
  },
});
