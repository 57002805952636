import { render, staticRenderFns } from "./OidcSignoutCallback.vue?vue&type=template&id=18f43b67"
import script from "./OidcSignoutCallback.vue?vue&type=script&lang=ts"
export * from "./OidcSignoutCallback.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports