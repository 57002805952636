




















import Vue from "vue";

import SkiTable from "@/lib/components/SkiTable.vue";
import { AgreementItem } from "@/lib/models/agreementItem.model";
import { alertMessage, generateId, setHighestPriorityCustomerApplication } from "@/lib/utilities";

interface EmitEvent {
  filteredAgreements: AgreementItem[]
}

export default Vue.extend({
  components: { SkiTable },
  props: {
    status: {type: String, required:true},
    apiKey: { type: String, required: true },
    itemId: { type: String, required: true },
    tableVariant: { type: String, required: true },
    filterVariant: { type: String, required: true },
    fields: { type: Array, required: true },
    sortOptions: { type: Array, required: true },
    filterOptions: { type: Array, required: true },
    linkItemUrl: { type: String, required: true },
    prioritizeCustomerApplications: { type: Boolean, default: false },
  },
  data() {
    return {
      data: {},
      items: [] as AgreementItem[],
      csvFilename: "example",
      tableIndex: generateId(),
      excelAgreements: [] as AgreementItem[]
    };
  },
  created() {
    this.fetchAPIData();
  },
  methods: {
    async fetchAPIData() {
      try{
        var agreements = await this.$api.AgreementsRepository.getAllAgreements(this.status)
        this.data = agreements;
        var its = agreements[this.apiKey];
        if(this.prioritizeCustomerApplications) {
          setHighestPriorityCustomerApplication(this, its);
        }
        this.items = its;
      } catch(err){
        alertMessage(this);
      }
    },
    getItems(): any[] {
      return this.items;
    },
    emitFilteredAgreements(e: EmitEvent) {
      this.excelAgreements = e.filteredAgreements;
      this.$emit("emit-filtered-agreements", {filteredAgreements: e.filteredAgreements, fields: this.fields});
    }
  }
});
