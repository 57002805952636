

























































































import Vue from "vue";
import Arrow from "@/lib/assets/icon/arrow.svg";
import Notification from "@/lib/assets/icon/notification.svg";
import SkiTable from "@/lib/components/SkiTable.vue";
import MySkiRowHeader from "@/lib/partials/my-ski/MySkiRowHeader.vue";
import RichText from "@/lib/components/shared/RichText.vue";
import SkiRoundButton from "@/lib/components/SkiRoundButton.vue";
import { alertMessage, generateId } from "@/lib/utilities";
import MySkiSubscriptionModal from "@/lib/partials/my-ski/modules/MySkiStatusUpdate/MySkiSubscriptionModal.vue";
import {
  AgreementListViewDto,
  GroupListViewDto,
  SourcingprogramListViewDto,
} from "@/lib/api/APIService";
import { StatusUpdateService } from "@/lib/businessLogic/services/StatusUpdateService";
import {
  STATUS_UPDATE_FILTER_OPTIONS,
  STATUS_UPDATE_SORT_OPTIONS,
  AGREEMENT_TYPES,
} from "@/lib/constants/statusUpdateConstants";
import {
  PollingEvents,
  StatusUpdatePollingService,
} from "@/lib/businessLogic/services/StatusUpdatePollingService";

const TABLE_FIELDS = [
  {
    label: "Aftale",
    key: "displayNameLong",
    class: "w-lg-75 overview-td",
    thStyle: { display: "none" },
  },
  {
    label: "Type",
    key: "type",
    class: "text-right overview-td",
    thStyle: { display: "none" },
  },
  {
    label: "Icon",
    key: "icon",
    class: "text-right overview-td",
    thStyle: { display: "none" },
  },
];

interface Data {
  subscribedAgreements: AgreementListViewDto[];
  userNotSubscribed: boolean;
  userInActive: boolean;
  tableIndex: string;
  tableFilterData: {
    agreementTypes: typeof AGREEMENT_TYPES;
    skiSourcingprograms?: SourcingprogramListViewDto[];
    skiGroups?: GroupListViewDto[];
  };
  isLoading: boolean;
  tableFields: typeof TABLE_FIELDS;
  statusUpdateFilterOptions: typeof STATUS_UPDATE_FILTER_OPTIONS;
  statusUpdateSortOptions: typeof STATUS_UPDATE_SORT_OPTIONS;
  pollingService: StatusUpdatePollingService;
  statusUpdateService: StatusUpdateService;
}

export default Vue.extend({
  components: {
    SkiTable,
    Arrow,
    MySkiRowHeader,
    MySkiSubscriptionModal,
    Notification,
    RichText,
    SkiRoundButton,
  },
  props: {
    overviewTitle: { type: String, required: true },
    overviewText: { type: String, default: "" },
    overviewOpenModalText: { type: String, required: true },
    modalTitle: { type: String, required: true },
    modalText: { type: String, default: "" },
    modalTitleChanged: { type: String, required: true },
    modalTextChanged: { type: String, default: "" },
    modalSubmitButtonText: { type: String, required: true },
    modalRemoveAllSubsText: { type: String, required: true },
  },
  data(): Data {
    return {
      subscribedAgreements: [],
      userNotSubscribed: false,
      userInActive: false,
      tableIndex: generateId(),
      tableFilterData: {
        agreementTypes: [],
        skiSourcingprograms: [],
        skiGroups: [],
      },
      isLoading: false,
      tableFields: TABLE_FIELDS,
      statusUpdateFilterOptions: STATUS_UPDATE_FILTER_OPTIONS,
      statusUpdateSortOptions: STATUS_UPDATE_SORT_OPTIONS,
      pollingService: new StatusUpdatePollingService(this.$api, this.$root),
      statusUpdateService: new StatusUpdateService(this.$api, this.$root)
    };
  },
  computed: {
    subscribedAgreementIds(): string[] {
      return this.subscribedAgreements.map(
        (agreement) => agreement.agreementId as string
      );
    },
  },
  created() {
    this.checkForPendingUpdate();
    // Whenever we save statusupdates, this event is triggered and we wait for it to finish so we can update the table.
    this.$root.$on(PollingEvents.Started, () => {
      this.isLoading = true;
    });
    this.$root.$on(PollingEvents.Complete, this.fetchAPIData);

    this.$root.$on(PollingEvents.Error, () => {
      this.isLoading = false;
      alertMessage(this, "Noget gik galt. Prøv igen senere. Eller kontakt venligst SKI's kundeservice på kundeservice@ski.dk, hvis problemet fortsætter.");
    });
  },
  watch: {
    "$store.state.profile"() {
      this.fetchAPIData();
    },
  },
  methods: {
    async checkForPendingUpdate() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      const hasPendingUpdate = await this.pollingService.waitForPendingUpdate();

      if (!hasPendingUpdate) {
        await this.fetchAPIData();
      }
    },
    async fetchAPIData() {
      if (this.userInActive) {
        return null;
      }

      const user = this.$store.state.profile;
      if (!user) {
        return null;
      }

      try {
        const statusUpdates = await this.statusUpdateService.fetchStatusUpdates();

        if (statusUpdates !== undefined || statusUpdates !== null) {
          this.subscribedAgreements = statusUpdates.subscribedAgreementList;
          this.userNotSubscribed = statusUpdates.userNotSubscribed;
          this.tableFilterData.skiSourcingprograms =
            statusUpdates.filterData.skiSourcingprograms;
          this.tableFilterData.skiGroups = statusUpdates.filterData.skiGroups;
          this.tableFilterData.agreementTypes =
            statusUpdates.filterData.agreementTypes;
        } else {
          this.userNotSubscribed = true;
        }
      } catch (err: any) {
        this.isLoading = false;
        if (err.status === 403) {
          this.userInActive = true;
          alertMessage(
            this,
            "Vi kan se, at din e-mail er deaktiveret i vores system. For at tilmelde dig statusopdateringer skal du derfor tage fat i SKI's kundeservice på kundeservice@ski.dk, så vi kan hjælpe dig med at aktivere din e-mail igen.",
            "Brugerprofil inaktiv"
          );
        } else {
          alertMessage(this);
        }
      } finally {
        this.isLoading = false;
      }
    },
    openModalWindow(event: Event) {
      event.preventDefault();
      this.$bvModal.show("mySkiModal");
    },
  },
});
