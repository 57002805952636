import Axios from "axios";
import axiosRetry from "axios-retry";
import { tokenProvider } from "../TokenProvider";

function createAxiosInstance() {
  const axiosInstance = Axios.create();

  axiosInstance.interceptors.request.use((config) => {
    const token = tokenProvider.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete config.headers["Authorization"];
    }

    // Adds a different query parameter depending on whether a user is logged in or not. This creates separate caches for loggen users and public users
    config.params = config.params || {};
    config.params.v = token ? "1" : "0";
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => error.response
    // NSwag generated process code use the response object, not the error object returned by Axios
  );

  axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: axiosRetry.isRetryableError,
  });

  return axiosInstance;
}

export { createAxiosInstance };
