


import { configurationProvider } from "@/lib/ConfigurationProvider";
import Vue from "vue";

/**
 * Auth component
 * This component is responsible for handling the authentication flow
 * no other component should be responsible for redirecting to login.
 * Currently only pages under /mit-ski are protected and require authentication
 * This component should only be shown after the authStore has been initialized otherwise we will redirect to login even if the user is logged in.
 */
export default Vue.extend({
  components: {},
  watch: {
    "$route.path": {
      // we assume that the auth store has been initialized here.
      // otherwise we might incorrectly redirect to login
      immediate: true,
      async handler(path) {
        this.handleRedirect();
      },
    },
  },
  methods: {
    async handleRedirect() {
      const options = configurationProvider.auth;
      // handle complete login redirect
      if (
        window.location.origin + window.location.pathname ===
        options.redirect_uri
      ) {
        const returnUrl = await this.$store.dispatch("completeSignin");
        window.location.href = returnUrl || "/";
        return;
      }

      // handle complete logout redirect
      if (
        window.location.origin + window.location.pathname ===
        options.post_logout_redirect_uri
      ) {
        const returnUrl = await this.$store.dispatch("completeSignOut");
        if (returnUrl && returnUrl.includes("/mit-ski")) {
          window.location.href = "/";
        } else {
          window.location.href = returnUrl || "/";
        }
        return;
      }

      const { action } = await this.$store.dispatch("getPageRedirectAction", {
        meta: {
          protected: window.location.pathname.startsWith("/mit-ski"),
        },
      });

      switch (action) {
        case "login":
          return this.$store.dispatch("signIn", window.location.href);
        case "no-access":
          break;
        case "none":
          break;
      }
    },
  },
});
