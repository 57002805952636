



















import Vue from "vue";
import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";
import SkiLink from "@/lib/components/SkiLink.vue";
export default Vue.extend({
  components: { ArrowLeft, SkiLink },
  props: {
    title: { type: String, required: true },
    teaser: { type: String, required: true },
    returnUrl: { type: String, default: null },
    returnLabel: { type: String }
  }
});
