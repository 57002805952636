




















































import Vue from "vue";

import Accordion from "@/lib/components/Accordion.vue";
import AgreementDocuments from "@/lib/partials/agreement/AgreementDocuments.vue";
import ExpertGroup from "@/lib/partials/agreement/ExpertGroup.vue";
import Suppliers from "@/lib/partials/agreement/Suppliers.vue";
import Customers from "@/lib/partials/agreement/Customers.vue";
import { isEmpty } from "@/lib/utilities";
import { AgreementDocumentsListViewDto } from "@/lib/api/APIService";
import { configurationProvider } from "@/lib/ConfigurationProvider";

export default Vue.extend({
  components: {
    Accordion,
    AgreementDocuments,
    ExpertGroup,
    Suppliers,
    Customers
  },
  props: {
    descriptionsTitle: { type: String, required: true },
    descriptionsDescription: { type: String, required: true },
    secondaryDescriptions: { type: Array, required: false, default: null }
  },
  data() {
    return {
      apiOrigin: configurationProvider.api.documentBaseUrl,
      item: this.$root.$data.item,
      hashFromWindow: window.location.hash,
    };
  },
  computed: {
    _primaryDescriptions(): any[] {
      const {
        title,
        excludes,
        application
      } = this.$config.agreement.descriptions;
      const primaryDescriptions = this.$root.$data.itemDescriptions;
      if (!primaryDescriptions) return [];

      // Expert Group is excluded since that description is used in the ExpertGroup.vue
      // Only filter out "Avendelse" accordion if not logged in
      if (!this.$store.state.profile) excludes.push(application.key);

      return primaryDescriptions.filter(
        (item: any) => !excludes.includes(item[title])
      );
    },
    _secondaryDescriptions(): any[] {
      const {
        key,
        phase,
        phaseName
      } = this.$config.agreement.descriptions.tenderImplementationPhase;

      // If agreement or tender with phase "Implementering" return all secondary descriptions
      if (this.$root.$data.agreementType || this.item[phase] === phaseName) {
        return this.secondaryDescriptions;
      }

      // Otherwise return only expert group for the tender
      return this.secondaryDescriptions.filter(
        (item: any) => item.title === key
      );
    }
  },
  methods: {
    isEmpty,
    displayDocumentAccordionIfNeeded(title: string, key: string, docs: AgreementDocumentsListViewDto[]) {
      // Accordion is not of document-type, does not need to be evaluated further. 
      if (key !== "document") {
        return true;
      }

      if (!docs || docs.length === 0) {
        return false;
      }

      const matchingDoc = docs.find(doc => 
        (doc.accordionName && doc.accordionName.toLowerCase() === title) || 
        (!doc.accordionName && title == "aftaledokumenter"));

      return !! matchingDoc;
    },
    showAccordion(key: string): boolean {
      // The accordion for customers should be shown on all Agreements with the description
      if (key === "customers"){
        return true;
      }
      if (key === "supplierList"){
        if(this.item.hideSupplierAccordion === true){ 
          return false; 
        }
        return true;
      }
      else {
        return (!isEmpty(this.item[key]));
      }
    },
    getUmbracoDocUrl(documentId: string) {
      return `${configurationProvider.api.documentBaseUrl}/document/${documentId}`
    }
  }
});
