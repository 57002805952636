



































import { hotScrollToItemTop } from "@/lib/utilities";
import Vue from "vue";

interface Data {
  showWidget: boolean;
  hash: string;
  showCommitment: boolean;
}

export default Vue.extend({
  components: {},
  props: {
    title: { type: String, required: true },
    bodyText: { type: String, required: true },
    active: { type: Boolean, required: true },
    allowedUsers: { type: String, required: true },
    stylesheetLink: { type: String, required: true },
    widgetLink: { type: String, required: true },
    commitmentUsers: { type: String, required: true },
    apiBaseUrlHot: { type: String, required: true },
    apiBaseUrlStamdata: { type: String, required: true },
  },
  data(): Data {
    return {
      showWidget: false,
      hash: "",
      showCommitment: false,
    };
  },
  async created() {
    this.hash = window.location.hash.split("#")[1];
    window.history.scrollRestoration = "manual";
    const orgList = this.allowedUsers.replace(/\s/g, "").split(",");
    const commitmentOrgList = this.commitmentUsers
      .replace(/\s/g, "")
      .split(",");

    const isLoggedIn = this.$store.getters.isLoggedIn;

    if (isLoggedIn) {
      const usr = this.$store.state.profile;
      if (!usr) return;

      this.showWidget =
        this.active === true &&
        !!(orgList.includes(usr.organizationId) || this.allowedUsers === "");
      this.$parent.$emit("show-hearing-widget", this.showWidget);

      this.showCommitment =
        this.active === true && commitmentOrgList.includes(usr.organizationId);
      this.$parent.$emit("show-commitment-widget", this.showCommitment);
    }
  },
  destroyed() {
    window.history.scrollRestoration = "auto";
  },
  methods: {
    scrollToHotElement(e: any) {
      if (this.hash !== "" && e.detail) {
        const element = document.getElementById(`id-${this.hash}`);
        if (element) {
          hotScrollToItemTop(element);
        }
      }
    },
    getClientId(): string {
      return this.$authOptions.client_id;
    },
    getAuthority(): string {
      return this.$authOptions.authority;
    },
    getRedirectUrl(): string {
      return this.$authOptions.redirect_uri;
    },
    getRedirectLogoutUrl(): string {
      return this.$authOptions.post_logout_redirect_uri;
    }
  },
});
