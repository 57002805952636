




























































import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";
import Closed from "@/lib/assets/icon/minus.svg";
import Open from "@/lib/assets/icon/open.svg";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import SkiLink from "@/lib/components/SkiLink.vue";
import StatusUpdateAccordion from "@/lib/partials/status-update/StatusUpdateAccordion.vue";

import {
  alertMessage,  
  getValue,
  isEmpty,
  qsParams,
} from "@/lib/utilities";
import { isUserCustomer } from "@/lib/utilities/authUtilities";
import dayjs from "dayjs";
import Vue from "vue";

interface Data {
  id: string;
  statusUpdates: [] | null;
  agreement: any | null;
  agreementLink: string | null;
  linkLabel: string | null;
  listToggled: boolean;
}

export default Vue.extend({
  components: {
    ArrowLeft,
    SkiLink,
    SkiDefaultButton,
    StatusUpdateAccordion,
    Open,
    Closed,
  },
  props: {
    agreementUrl: { type: String, required: true },
    tenderUrl: { type: String, required: true },
    toggledMaxElements: { type: Number, default: 0 },
  },
  data(): Data {
    return {
      id: "",
      statusUpdates: null,
      agreement: null,
      agreementLink: null,
      linkLabel: null,
      listToggled: false,
    };
  },
  async created() {
    const { id } = qsParams();
    this.id = id;
    this.getAgreement();
    this.getStatusUpdates();
  },
  computed: {
    backUrl() {
      return isUserCustomer(this.$store.state.profile)
        ? "/mit-ski/kunde/mine-statusopdateringer/"
        : "/mit-ski/leverandor/mine-statusopdateringer/";
    },
  },
  methods: {
    async getStatusUpdates() {
      try {
        const statusUpdates =
          await this.$api.StatusUpdateRepository.getStatusUpdateList(
            this.id
          );
        const { key } = this.$config.agreement.statusUpdate;
        this.statusUpdates = statusUpdates[key];
      } catch (err) {
        if (err.statusText.includes("TypeError")) {
          return;
        }
        alertMessage(this);
      }
    },
    async getAgreement() {
      try {
        const agreement = await this.$api.AgreementsRepository.get(this.id);
        var item = agreement;
        var skiOverallStatus = getValue("overallStatusName", item);
        if (skiOverallStatus === "Aktiv" || skiOverallStatus === "Udløbet") {
          if (dayjs(item.expirationDateUtc).isBefore(dayjs().add(-1, "day"))) {
            item.type = "Tidligere aftale";
            item.variant = "danger";
          } else {
            item.type = "Aftale";
            item.variant = "aftale";
          }
          this.agreementLink = this.agreementUrl + item.agreementId;
          this.linkLabel = "Gå til aftalen";
        } else if (skiOverallStatus === "Kommende") {
          item.type = "Udbud";
          item.variant = "udbud";
          this.agreementLink = this.tenderUrl + item.agreementId;
          this.linkLabel = "Gå til udbuddet";
        }
        this.agreement = item;
      } catch (err) {
        alertMessage(this);
      }
    },
    toggleExpand(expanded: string, index: number, id: string) {
      if (expanded) {
        this.fetchStatusUpdateItem(index, id);
      }
    },
    toggleList(event: Event) {
      event.preventDefault();
      this.listToggled = !this.listToggled;
    },
    limitedList(): any {
      if (this.toggledMaxElements > 0 && !this.listToggled) {
        if (this.statusUpdates) {
          return this.statusUpdates.slice(0, this.toggledMaxElements) as [];
        }
      } else {
        return this.statusUpdates;
      }
    },
    async fetchStatusUpdateItem(index: number, id: string) {
      try {
        const statusUpdate =
          await this.$api.StatusUpdateRepository.getStatusUpdate(id);
        if (Array.isArray(this.statusUpdates)) {
          this.$set(
            this.statusUpdates[index],
            "content",
            statusUpdate.description
          );
        }
      } catch (err) {
        if (err.status === 403) {
          alertMessage(
            this,
            "Vi kan se, at din e-mail er deaktiveret i vores system. For at tilmelde dig statusopdateringer skal du derfor tage fat i SKI’s kundeservice på kundeservice@ski.dk, så vi kan hjælpe dig med at aktivere din e-mail igen.",
            "Brugerprofil inaktiv"
          );
        } else {
          alertMessage(this);
        }
      }
    },
    showSeeMoreButtons(): Boolean {
      if (!!this.statusUpdates && this.toggledMaxElements > 0) {
        return this.statusUpdates.length > this.toggledMaxElements;
      }
      return false;
    },
    qsParams,
    isEmpty,
    getValue,
  },
});
