


























import Vue from "vue";
import VideoModule from "@/lib/components/VideoModule.vue";
import { replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem } from "@/lib/utilities";
import { resetHashtagValue } from "../urlUtilities";

interface Data {
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagvideoId: string;
}
export default Vue.extend({
  components: { VideoModule },
  props: {
    title: { type: String, default: null },
    videoUrl: { type: String, required: true },
    videoDesc: { type: String, default: null },
    videoOnly: { type: Boolean, default: false },
    isTextRightAligned: { type: Boolean, default: false }
  },
  data(): Data {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagvideoId: "#" + this.title.toLowerCase()
    };
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagvideoId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    } 
  },
  methods: {
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var videoId = this.hashtagvideoId.split("#")[1];
      scrollToAnchorItem(videoId);
    }
  }
});
