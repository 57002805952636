





































































































import Vue from "vue";
import SkiLink from "@/lib/components/SkiLink.vue";
import LinkedIn from "@/lib/assets/icon/linkedin.svg";
import ArrowUp from "@/lib/assets/icon/arrow-up.svg";

export default Vue.extend({
  components: { SkiLink, LinkedIn, ArrowUp },
  props: {
    phone: { type: String, required: true },
    mail: { type: String, required: true },
    address: { type: String, required: true },
    registrationNumber: { type: String, required: true },
    linkedInUrl: { type: String, required: true },
    linkedInTarget: { type: String, default: null },
    linkPressUrl: { type: String, required: true },
    linkPressTarget: { type: String, default: null },
    linkPressLabel: { type: String, required: true },
    linkAccessibilityUrl: { type: String, required: true },
    linkAccessibilityTarget: { type: String, default: null },
    linkAccessibilityLabel: { type: String, required: true },
    linkCookiePrivacyPolicyUrl: { type: String, required: true },
    linkCookiePrivacyPolicyTarget: { type: String, default: null },
    linkCookiePrivacyPolicyLabel: { type: String, required: true },
    linkEnglishUrl: { type: String, required: true },
    linkEnglishTarget: { type: String, default: null },
    linkEnglishLabel: { type: String, required: true },
    linkToTop: { type: String, required: true },
    linkCookieRenew: { type: String, default: "javascript:window.monsidoConsentManager.showBanner()" },
    linkCookieRenewLabel: {type: String, required: true }
  },
  computed: {
    isWhiteHeader() {
      return this.$root.$data.IsWhiteHeader;
    }
  },
  methods: {
    scrollToTop(pxFromTop: number): void {
      window.scrollTo({ top: pxFromTop, behavior: 'smooth' });
    }
  }
});
