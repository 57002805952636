




























import Vue from "vue";
import MySkiStatusUpdates from "@/lib/partials/my-ski/modules/MySkiStatusUpdate/MySkiStatusUpdates.vue";
import MySkiOverview from "@/lib/partials/my-ski/modules/MySkiStatusUpdate/MySkiOverview.vue";
import { isEmpty, qsParams } from "@/lib/utilities";

interface Data {
  hasId: Boolean;
}

export default Vue.extend({
  components: { MySkiStatusUpdates, MySkiOverview },
  props: {
    fields: { type: Array, required: true },
    sortOptions: { type: Array, required: true },
    filterOptions: { type: Array, required: true },
    statusMap: { type: Array, required: true },
    agreementUrl: { type: String, required: true },
    tenderUrl: { type: String, required: true },
    overviewTitle: { type: String, required: true },
    overviewText: { type: String },
    overviewOpenModalText: { type: String, required: true },
    modalTitle: { type: String, required: true },
    modalText: { type: String },
    modalTitleChanged: { type: String, required: true },
    modalTextChanged: { type: String },
    modalSubmitButtonText: { type: String, required: true },
    modalRemoveAllSubsText: { type: String, required: true },
    skiStatuses: { type: Array, required: true },
  },
  data(): Data {
    return {
      hasId: false,
    };
  },
  created() {
    if (!isEmpty(qsParams())) {
      const { id } = qsParams();
      this.hasId = Boolean(id);
    }
  },
  methods: {
    isEmpty,
    qsParams,
  },
});
