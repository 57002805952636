















































import Vue from "vue";
import Close from "@/lib/assets/icon/close.svg";
import SkiButton from "@/lib/components/shared/SkiButton.vue";
import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";

export default Vue.extend({
  components: { Close, SkiButton, ArrowLeft },
  props: {
    id: { type: String, default: "" },
    title: { type: String, default: "" },
    acceptButtonLabel: { type: String, default: "Bekræft" },
    rejectButtonLabel: { type: String, default: "Afvis" },
    hideAcceptButton: { type: Boolean, default: false },
    show: { type: Boolean, default: false }
  },
  watch: {
    show: {
      handler(newVal: boolean) {
        if (newVal) {
          this.$bvModal.show(this.id);
        } else {
          this.$bvModal.hide(this.id);
        }
      },
    },
  },
});
