



























import Vue from "vue";
import SkiRoundButton from "@/lib/components/SkiRoundButton.vue";
import Excel from "@/lib/assets/icon/excel.svg";
import Print from "@/lib/assets/icon/print.svg";

export default Vue.extend({
  components: {
    SkiRoundButton,
    Excel,
    Print,
  },
  props: {
    contentClass: { type: String, default: "" },
    variant: { type: String, default: "light" },
    printText: { type: String, default: "light" },
  },
});
