























































import Vue from "vue";
import SkiLink from "@/lib/components/SkiLink.vue";
import NumberCard from "@/lib/components/NumberCard.vue";
import Arrow from "@/lib/assets/icon/arrow.svg";
import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";
import { replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem } from "@/lib/utilities";
import { resetHashtagValue } from "@/lib/urlUtilities";

export default Vue.extend({
  components: { SkiLink, NumberCard, Arrow, ArrowLeft },
  props: {
    title: { type: String, required: true },
    linkLabel: { type: String, required: true },
    linkUrl: { type: String, required: true },
    linkTarget: { type: String, default: null },
    cards: { type: Array, required: true }
  },
  data() {
    return {
      offset: 0,
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagskiNumberId: "#" + this.title.toLowerCase()
    };
  },
  computed: {
    hasPrevious(): boolean {
      return this.offset > 0;
    },
    hasNext(): boolean {
      return this.offset < this.cards.length - 1;
    },
    scrollOffset(): {} {
      const margin = `-${3 * this.offset}rem`;
      const padding = `-${1 * 2 * this.offset}rem`;
      const width = `-${300 * this.offset}px`;
      const transform = [margin, padding, width]
        .map(i => `translateX(${i})`)
        .join(" ");

      return { transform, transition: "transform 1s" };
    },
    renderControls(): boolean {
      return this.cards.length > 3;
    }
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagskiNumberId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    } 
  },
  methods: {
    previous() {
      this.offset -= 1;
    },
    next() {
      this.offset += 1;
    },
    reset({ target }: Event) {
      this.offset = 0;
      this.scrollToLeft((target as HTMLElement).closest(".ski-numbers"), 500);
    },
    scrollToLeft(element: Element | null, durationMs: number, fps = 15) {
      if (!element) return;
      var scrollStep = -element.scrollLeft / (durationMs / fps);
      var scrollInterval = setInterval(() => {
        if (element.scrollLeft !== 0) {
          element.scrollBy(scrollStep, 0);
        } else {
          clearInterval(scrollInterval);
        }
      }, fps);
    },
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var skiNumberId = this.hashtagskiNumberId.split("#")[1];
      scrollToAnchorItem(skiNumberId);
    }
  }
});
