


















import Vue from "vue";
import RichText from "@/lib/components/shared/RichText.vue";
import Status from "@/lib/assets/icon/status.svg";

export default Vue.extend({
  name: "InfoBox",
  components: { Status, RichText },
  props: {
    infoText: { type: String, default: "" },
    catalogBtnShown: { type: Boolean, default: false },
  },
});
