













import Vue from "vue";
export default Vue.extend({
  props: {
    variant: { type: String, default: "secondary" },
    text: { type: String, default: null },
    to: { type: String, default: null },
    flexStyle: { type: Boolean, default: false} 
  }
});
