








































import Vue from "vue";

interface Data {
  variant: any;
}

export default Vue.extend({
  props: {
    type: { type: String, default: null },
    isWhiteBackground: { type: Boolean, default: true }
  },
  data(): Data {
    return {
      variant: {}
    };
  },
  computed: {
    styleClass() {
      const { variant } = this;
      const { isWhiteBackground } = this;
      var styleArray = [];


      if(variant.id === 0 || variant.id === 2){
        styleArray[0] = "is-good"
      }
      else if(variant.id === 1 || variant.id === 7 || variant.id === 8 ){
        styleArray[0] = "is-good-transparent"
      }else if(variant.id === 3 || variant.id === 5 ){
       styleArray[0] = "is-warning"
      } else if(variant.id === 9 || variant.id === 10 || variant.id === 12 ){
        styleArray[0] = "is-warning-transparent"
      } else if(variant.id === 6){
        styleArray[0] = "is-danger"
      }
      else{
        if(isWhiteBackground){
        styleArray[0] = "is-special"
        }else{
        styleArray[0] = "is-special-green"
        }
      }
      
      if(isWhiteBackground){
        styleArray[1] = "outercircel-white"
      } else{
        styleArray[1] = "outercircel-other-color"
      }
      return styleArray ;
    },
    textColorWhite(): boolean {
      const {variant} = this ;
      return variant.c === "fill" ;
    },
    textColorGreen(): boolean {
      const {variant} = this ;
      return variant.c === "outline" && variant.bg === "#82bd7d" ;
    },
    textColorYellow(): boolean {
      const {variant} = this ;
      return variant.c === "outline" && variant.bg === "#EDC01A" ;
    },
    holeCircelWhite(): boolean {
      const { variant } = this;
      const { isWhiteBackground } = this;

      return variant.c === "outline" && isWhiteBackground; 
    },
    holeCircelGreen(): boolean {
      const { variant } = this;
      const { isWhiteBackground } = this;
      
      return variant.c === "outline" && !isWhiteBackground; 
    },
    borderColor(): string {
      const { isWhiteBackground } = this;

      return isWhiteBackground ? "traffic-circle-border-green" : "traffic-circle-border-white";
    },
    text(): string {
      return this.variant.txt || "";
    }
  },
  created() {
    if (this.type) {
      const { types } = this.$config.agreement.application;
      this.variant = types[this.type.toLowerCase()] || {};
    }
  }
});
