




















import Vue from "vue";
import { AgreementItem } from "@/lib/models/agreementItem.model";
import AgreementTableButtons from "@/lib/components/AgreementTableButtons.vue";
import { downloadExcelSheet } from "@/lib/utilities";

interface EmitEvent {
  fields: any[];
  filteredAgreements: AgreementItem[];
}

export default Vue.extend({
  components: {
    AgreementTableButtons,
  },
  props: {
    title: { type: String, required: true },
    variant: { type: String, default: "light" },
    bgVariant: { type: String, required: true }
  },
  data() {
    return {
      excelAgreements: [] as AgreementItem[],
      fields: [] as any[],
      printing: false,
    };
  },
  updated() {
    if (this.printing) {
      this.$nextTick(() => {
        window.print();
        this.printing = false;
      });
    }
  },
  methods: {
    downloadExcel() {
      downloadExcelSheet(this, this.excelAgreements, this.fields, this.title);
    },
    printTable() {
      this.printing = true;
    },
    setFilteredAgreements(e: EmitEvent) {
      this.fields = e.fields;
      this.excelAgreements = e.filteredAgreements;
    },
  },
});
