
export const lazyLoading = {

  browserSupportsLazyLoading(): boolean {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      return true;
    }
    else {
      return false;
    }
  },
  setupDefaultIntersectionObserver(callback: IntersectionObserverCallback): IntersectionObserver {
    return new IntersectionObserver(callback);
  },
  setupIntersectionObserver(callback: IntersectionObserverCallback, configuration: IntersectionObserverInit): IntersectionObserver {
    return new IntersectionObserver(callback, configuration);
  }
}
