






























import Vue from "vue";
import qs from "qs";
import range from "lodash-es/range";

import Arrow from "@/lib/assets/icon/arrow.svg";
import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";
import { qsParams } from "@/lib/utilities";

export default Vue.extend({
  components: { Arrow, ArrowLeft },
  props: {
    totalPages: { type: Number, required: true },
    currentPage: { type: Number, required: true }
  },
  data() {
    return {
      // pps = Pages Per Section
      pps: 4,
      urlWithFilter: ""
    };
  },
  computed: {
    totalSections(): number {
      return Math.ceil(this.totalPages / this.pps);
    },
    currentSection(): number {
      return Math.ceil(this.currentPage / this.pps);
    },
    hasNextSection(): boolean {
      return this.currentSection < this.totalSections;
    },
    hasPreviousSection(): boolean {
      return this.currentSection > 1;
    },
    hasPreviousPage(): boolean {
      return this.currentPage > 1 && this.currentPage <= this.totalPages;
    },
    hasNextPage(): boolean {
      return this.currentPage >= 1 && this.currentPage < this.totalPages;
    },
    pagesInCurrentSection(): number[] {
      var startPage = (this.currentSection - 1) * this.pps + 1;

      var residualPages = this.currentSection * this.pps > this.totalPages;
      var pagesInSection = residualPages
        ? this.totalPages - startPage
        : this.pps - 1;
      var endPage = startPage + pagesInSection;

      return range(startPage, endPage + 1);
    }
  },
  methods: {
    getPageUrl(page: number) {
      const query = qsParams();
      this.urlWithFilter = qs.stringify({ ...query, page}, { addQueryPrefix: true });
      window.location.href = this.urlWithFilter;
    },
    convertNumberToText(num: number): string {
      return num < 10 ? `0${num.toString()}` : num.toString();
    }
  },
});
