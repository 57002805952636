


























import Vue from "vue";

import Search from "@/lib/assets/icon/search.svg";

interface Data {
  searchQuery: string;
}

export default Vue.extend({
    components: { Search },
    props: {
        variant: { type: String, required: true },
        searchLabel: { type: String, required: true }
    },
    data(): Data {
        return {
            searchQuery: ""
        };
    },
    computed: {
        isWhite() {
            return this.variant === "light";
        }
    },
    watch: {
        searchQuery() {
            this.$emit('onSearch', this.searchQuery);
        }
    },
    methods: {
        onSearch(evt: Event) {
            evt.preventDefault();
            this.$emit('onSearch', this.searchQuery);
        }
    }
});
