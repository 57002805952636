

















































import Vue from "vue";
import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";
import SkiImage from "@/lib/components/SkiImage.vue";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import SkiLink from "@/lib/components/SkiLink.vue";

export default Vue.extend({
  components: { ArrowLeft, SkiImage, SkiDefaultButton, SkiLink },
  props: {
    title: { type: String, required: true },
    imageUrl: { type: String, required: true },
    imageAltText: { type: String, required: true },
    returnUrl: { type: String, required: true },
    returnLabel: { type: String, required: true },
    teaser: { type: String, required: true },
    signUpUrl: { type: String, required: true },
    signUpLabel: { type: String, required: true },
    signUpTarget: { type: String, default: null },
    showSignUpButton: { type: Boolean, default: false }
  }
});
