import { containsAny } from "./stringUtilities";

export function addMonsidoTrackingToElement(
  element: HTMLAnchorElement,
  category: string,
  action: string,
  name: string
): void {
  if (element !== undefined && element.dataset.hasClickEvent !== "true") {
    element.addEventListener("click", (event) => {
      window.monsido_functions.trackEvent(category, action, name, 1);
    });
    element.dataset.hasClickEvent = "true";
  }
}

export function getDocumentLinksFromRef(div: HTMLAnchorElement): HTMLAnchorElement[] {
  return [...div.querySelectorAll("a")].filter((link) => {
    return containsAny(link.href.toLowerCase(), [
      ".pdf",
      ".docx",
      ".xlsx",
      ".doc",
      ".xls",
      ".ppt"
    ]);
  });
}
