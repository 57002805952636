import { 
  StatusUpdatesRequest, 
  AgreementListViewDto, 
  AgreementListViewDtoWrapper,
  SourcingprogramListViewDto,
  GroupListViewDto,
} from '@/lib/api/APIService';
import { SKIRepository } from '@/lib/api/SKIRepository';
import { convertCrmStatusToType, isEmpty } from '@/lib/utilities';
import { AGREEMENT_TYPES } from '@/lib/constants/statusUpdateConstants';
import { StatusUpdatePollingService } from './StatusUpdatePollingService';

interface StatusUpdateData {
  subscribedAgreementList: AgreementListViewDto[];
  userNotSubscribed: boolean;
  filterData: {
    skiSourcingprograms: SourcingprogramListViewDto[];
    skiGroups: GroupListViewDto[];
    agreementTypes: typeof AGREEMENT_TYPES;
  };
}

export class StatusUpdateService {
  private api: SKIRepository;
  private pollingService: StatusUpdatePollingService;

  constructor(api: SKIRepository, vueRoot: Vue) {
    this.api = api;
    this.pollingService = new StatusUpdatePollingService(api, vueRoot);
  }

  async fetchStatusUpdates(contactGuid: string | undefined = undefined): Promise<StatusUpdateData> {
    const statusUpdates = await this.api.StatusUpdateRepository.getStatusUpdatesForTheUser(contactGuid);
    return this.convertApiData(statusUpdates);
  }

  public convertApiData(data: AgreementListViewDtoWrapper): StatusUpdateData {
    const agreements = data.skiAgreementlist ?? [];
    
    const subscribedAgreementList = agreements.map(agreement => {
      convertCrmStatusToType(agreement);
      agreement.icon = "svg";
      return agreement;
    });
    var result = {
      subscribedAgreementList,
      userNotSubscribed: isEmpty(agreements),
      filterData: {
        skiSourcingprograms: data.skiSourcingprograms ?? [],
        skiGroups: data.skiGroups ?? [],
        agreementTypes: AGREEMENT_TYPES,
      }
    } as StatusUpdateData;
    
    return result; 
  }

  async updateStatusSubscriptionsByGuid(contactGuid: string, selectedAgreements: string[]) {
    try {
      const requestBody = { aftids: selectedAgreements } as StatusUpdatesRequest;
      
      const response = await this.api.StatusUpdateRepository.putStatus(
        contactGuid,
        requestBody
      );
      this.pollingService.pollUpdateStatus(contactGuid);   
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateStatusSubscriptionsByEmail(email: string, selectedAgreements: string[]) {
    try {
      const requestBody = { aftids: selectedAgreements } as StatusUpdatesRequest;
    
      const response = await this.api.StatusUpdateRepository.putStatus(
        undefined,
        requestBody
      );
      this.pollingService.pollUpdateStatus(email);   
      return response;
    } catch (error) {
      throw error;
    }
  }
}
