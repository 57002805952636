export enum CookieTypesEnum {
  Essential = 'essential',
  Analytics = 'analytics',
  Basic = 'basic',
  Marketing = 'marketing',
  Personalisation = 'personalisation',
}

export type CookieTypes = keyof typeof CookieTypesEnum;

export function setCookie(name: string, value?: string | null, path?: string) {
  if (value) {
    document.cookie = `${name}=${value};path=${path}`;
  } else {
    removeCookie(name, path);
  }
}

function removeCookie(name: string, sPath = ""): void {
  document.cookie =
    name +
    "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
    (sPath ? "; path=" + sPath : "");
}

export const CookieNames = {
  jwtToken: "jwtToken",
};
