






























import Vue from "vue";

import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import { getValue } from "@/lib/utilities";
import { IProfile } from "@/lib/utilities/OidcAuth";
import { OrgType } from "@/lib/utilities/orgType";

export default Vue.extend({
  components: { SkiDefaultButton },
  props: {
    fields: { type: Array, required: true },
    editLabel: { type: String, required: true },
    editUrl: { type: String, required: true },
    editTarget: { type: String, default: null },
  },
  data() {
    return {
      usr: {} as IProfile,
      org: this.$root.$data.org,
    };
  },
  methods: {
    //TODO SSO - tjek at getValue stadig virker nu når bruger objektet er ændret
    getValue,
    skipSKICustomerNumber(key: string): boolean {
      if (this.org !== null) {
        return !(key === "SKI kundenummer" && this.org.role !== OrgType.Kunde);
      } else {
        return true;
      }
    },
  },
});
