








































import Vue from "vue";
import Play from "@/lib/assets/icon/play.svg";
import { monsidoUtilities } from "@/lib/utilities/monsidoUtilities";
import { CookieTypesEnum } from "../utilities/cookieUtilities";

export default Vue.extend({
  components: { Play },
  props: {
    videoUrl: { type: String, required: true }
  },
  data() {
    return {
      videoId: this.videoUrl.replace(/.*vimeo.com\//, ""),
      videoParams: "?title=0&dnt=true&byline=0&portrait=0&controls=0&autoplay=0",
      controls: false,
      showVideo: false
    };
  },
  created() {
    window.addEventListener('MonsidoCookieOnHide', this.updateShowVideo);
  },
  mounted(){
    this.updateShowVideo();
  },
  beforeDestroy() {
    window.removeEventListener('MonsidoCookieOnHide', this.updateShowVideo);
  },
  methods: {
    play() {
      this.videoParams = "?title=0&dnt=true&byline=0&portrait=0&controls=1&autoplay=1";
      this.controls = true;
    },
    updateShowVideo() {
      this.showVideo = monsidoUtilities.isConsented(CookieTypesEnum.Analytics);    
    }
  }
});
