




import Vue from "vue";
import dompurify from "dompurify";
import { monsidoUtilities } from "@/lib/utilities/monsidoUtilities";

export default Vue.extend({
  props: {
    html: { type: String, required: true },
  },
  computed: {
    sanitizeHtml(): string {
      return dompurify.sanitize(this.html, {
        ADD_TAGS: ["iframe"],
      });
    },
  },
  created() {
    window.addEventListener("MonsidoCookieOnHide", this.trackDownloadEvents);
  },
  mounted() {
    this.trackDownloadEvents();
  },
  activated() {
    this.trackDownloadEvents();
  },
  beforeDestroy() {
    window.removeEventListener("MonsidoCookieOnHide", this.trackDownloadEvents);
  },
  methods: {
    trackDownloadEvents() {
      const div = this.$refs.htmlContainer as HTMLAnchorElement;
      var docLinks = monsidoUtilities.getDocumentLinksFromRef(div);
      docLinks.forEach((link) => {
        monsidoUtilities.updateMonsidoEventTracker(
          link,
          link.href,
          link.title ?? link.text
        );
      });
    },
  },
});
