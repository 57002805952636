



















































































































































import Vue, { PropType } from "vue";

import Burger from "@/lib/assets/icon/burger.svg";
import Close from "@/lib/assets/icon/close.svg";
import Profile from "@/lib/assets/icon/profile.svg";
import Search from "@/lib/assets/icon/search.svg";
import SkiSearchButton from "@/lib/components/SkiSearchButton.vue";

import { qsParams } from "@/lib/utilities";

interface Data {
  searchQuery: string;
  showCollapse: boolean;
  currentRoute: string;
}

interface MenuItem {
  name: string;
  url: string;
  target: string;
  visible: boolean;
  whiteHeader: boolean;
  items: MenuItem[] | null;
}

interface SidebarItem {
  url: string;
  name: string;
  items?: SidebarItem[] | null;
}

interface SidebarArray {
  role: string;
  sidebars: SidebarItem[];
}

export default Vue.extend({
  components: {
    SkiSearchButton,
    Burger,
    Close,
    Profile,
    Search,
  },
  props: {
    variant: { type: String, required: true },
    type: { type: String, required: true },
    menu: { type: Array as PropType<MenuItem[]>, required: true },
    mySkiLabel: { type: String, required: false },
    mySkiUrl: { type: String, required: false },
    loginLabel: { type: String, required: false },
    logoutLabel: { type: String, required: false },
    logoutUrl: { type: String, required: false },
    menuLabel: { type: String, required: false },
    searchLabel: { type: String, required: false },
    searchUrl: { type: String, required: false },
    logo: { type: String, required: true },
    logoUrl: { type: String, required: false },
    logoAltText: { type: String, required: true },
    sidebarArray: { type: Array as PropType<SidebarArray[]>, default: null },
  },
  data(): Data {
    return {
      searchQuery: qsParams().query || "",
      showCollapse: false,
      currentRoute: "",
    };
  },
  mounted() {
    this.currentRoute = window.location.href;
  },
  computed: {
    username() {
      return this.$store.state.profile.name;
    },
    isUserLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isWhite() {
      return this.variant === "light";
    },
    logoutRoute(): string {
      if (this.currentRoute.includes(this.mySkiUrl)) {
        return window.location.origin + "/";
      } else if (this.currentRoute.includes("documentId")) {
        const url = new URL(this.currentRoute);
        url.searchParams.delete("documentId");
        return url.toString();
      } else {
        return this.currentRoute;
      }
    },
  },
  methods: {
    login(redirectPath?: string): void {
      this.$store.dispatch("signIn", redirectPath || window.location.href);
    },
    logout(): void {
      this.$store.dispatch("signOut", window.location.href);
    },
    onSearch(evt: Event) {
      evt.preventDefault();
      window.location.href = this.searchUrl + "?query=" + this.searchQuery;
    },
  },
});
