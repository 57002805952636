import { CookieTypes, CookieTypesEnum } from "./cookieUtilities";
import { containsAny } from "./stringUtilities";

export class MonsidoUtilities {
  static trackingFunctions: { [key: string]: () => void } = {};

  isConsented(type: CookieTypesEnum): boolean {
    const consentedCookieTypes = window.monsidoConsentManager.cookiesConsent
      .cookies as CookieTypes;
    return consentedCookieTypes.includes(type);
  }

  updateMonsidoEventTracker(
    el: HTMLAnchorElement,
    key: string,
    title: string
  ): void {
    if (!this.isConsented(CookieTypesEnum.Analytics)) {
      this.removeMonsidoEventTracker(el, key);
      return;
    }

    if (MonsidoUtilities.trackingFunctions[key]) {
      return;
    }

    const trackingFunction = () => {
      window.monsido_functions.trackEvent(
        "Download",
        "Download af Umbraco-dokument",
        `Download af dokument: ${title}`,
        1
      );
    };

    MonsidoUtilities.trackingFunctions[key] = trackingFunction;

    el.addEventListener("click", trackingFunction);
  }

  removeMonsidoEventTracker(el: HTMLAnchorElement, key: string): void {
    if (!MonsidoUtilities.trackingFunctions[key]) {
      return;
    }

    MonsidoUtilities.trackingFunctions[key];

    el.removeEventListener("click", MonsidoUtilities.trackingFunctions[key]);

    delete MonsidoUtilities.trackingFunctions[key];
  }

  getDocumentLinksFromRef(div: HTMLAnchorElement): HTMLAnchorElement[] {
    return [...div.querySelectorAll("a")].filter((link) => {
      return containsAny(link.href.toLowerCase(), [
        ".pdf",
        ".docx",
        ".xlsx",
        ".doc",
        ".xls",
        ".ppt",
      ]);
    });
  }
}

export const monsidoUtilities = new MonsidoUtilities();
