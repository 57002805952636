



























































import Vue from "vue";
import { formatDate } from "@/lib/dateUtilites";
import PrintButton from "@/lib/components/PrintButton.vue";

export default Vue.extend({
  components: { PrintButton },
  props: {
    imageUrl: { type: String, required: true },
    date: { type: String, required: true },
    startTime: { type: String, required: true },
    endTime: { type: String, required: true },
    online: { type: Boolean, default: false },
    deadlineDate: { type: String, required: true },
    deadlineTime: { type: String, required: true },
    addressLine1: { type: String, default: "" },
    addressLine2: { type: String, default: "" },
    postalCode: { type: String, default: "" },
    city: { type: String, default: "" },
    eventType: { type: String, required: true },
    locations: { type: Array, required: true},
    maalgroups: { type: Array, required: true},
    targetGroups: { type: Array, required: true },
    price: { type: String, required: true }
  },
  methods: {
    formatDate
  }
});
