/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-console, no-prototype-builtins */
import {
  AgreementsContainsTrafficlight,
  isAgreement,
  isExpired,
  isTender,
} from "@/lib/agreementUtilities";
import { formatDate } from "@/lib/dateUtilites";
import { AgreementItem } from "@/lib/models/agreementItem.model";
import dayjs from "dayjs";
import "dayjs/locale/da";
import utc from "dayjs/plugin/utc";
import qs from "qs";
import { Dictionary } from "vue-router/types/router";
import { CalendarDbDto } from "./api/APIService";
import { Field } from "./models/field.model";

dayjs.extend(utc);
dayjs.locale("da");

export const logger = {
  log: function (msg?: any, ...optionalParams: any[]): void {
    console.log("LOGGER ::", msg, ...optionalParams);
  },
  error: function (msg?: any, ...optionalParams: any[]): void {
    console.error("LOGGER ::", msg, ...optionalParams);
  },
};

export const isEmpty = (list: any): boolean => {
  if (!list) return true;
  if (Array.isArray(list)) {
    return list.length === 0;
  }
  return Object.keys(list).length === 0;
};

function timeout(promise: Promise<Response>, ms: number): Promise<Response> {
  return new Promise(function (resolve, reject): void {
    setTimeout(function (): void {
      reject(new Error("Timeout expired"));
    }, ms);
    promise.then(resolve, reject);
  });
}

export function generateId(): string {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return ("1e7" + -"1e3" + -"4e3" + -"8e3" + -"1e11").replace(
    /[018]/g,
    (c: any) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
  );
}

export function replaceHexWithNordicLettersAndWhitespace(text: string): string {
  return text
    .replace(new RegExp("%C3%A6", "g"), "æ")
    .replace(new RegExp("%C3%B8", "g"), "ø")
    .replace(new RegExp("%C3%A5", "g"), "å")
    .replace(new RegExp("%C3%86", "g"), "Æ")
    .replace(new RegExp("%C3%98", "g"), "Ø")
    .replace(new RegExp("%C3%85", "g"), "Å")
    .replace(new RegExp("%20", "g"), " ");
}

export function scrollToItemTop(
  element: any,
  delay = 0,
  freetextOffset = 0
): void {
  setTimeout(function (): void {
    var y = element.getBoundingClientRect().top;
    // Add a small offset if an infogram is present
    var infograms = document.getElementsByName("infogram");
    let infoOffset = 0;
    for (let i = 0; i < infograms.length; i++) {
      if (infograms[i].getBoundingClientRect().top < y) {
        infoOffset = infograms.length > 0 ? window.outerHeight * 0.1 : 0;
      }
    }
    window.scrollTo({
      top: y + infoOffset - freetextOffset,
      behavior: "smooth",
    });
  }, delay);
}

export function scrollToElement(element: any) {
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }
}

export function scrollToAnchorItem(
  elementId: string,
  delay = 0,
  freetextOffset = 0
): void {
  const element = document.getElementById(elementId);
  if (element) {
    scrollToItemTop(element, delay, freetextOffset);
  }
}

export function hotScrollToItemTop(element: any) {
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });

    const navbar = document.getElementById("ski-header");
    if (navbar != null) {
      const navOffset = navbar.offsetHeight;
      const y =
        element.getBoundingClientRect().top +
        window.pageYOffset -
        (navOffset - 25);
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }
}

export const qsParams = (params = window.location.search): any =>
  qs.parse(params, {
    ignoreQueryPrefix: true,
  });

export function isJson(str: string | null): boolean {
  if (!str) return false;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function atobUtf(str: string): string {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map((c): string => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );
}

export function btoaUtf(str: string): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1): string =>
      String.fromCharCode(Number("0x" + p1))
    )
  );
}

export function alertMessage(
  self: Vue,
  msg: string = "Kontakt SKI's kundeservice, hvis problemet fortsætter. \n\n",
  titel: string = "Fejl!"
): void {
  self.$bvModal
    .msgBoxOk(msg, {
      title: titel,
      headerTextVariant: "primary",
      bodyTextVariant: "dark",
      footerTextVariant: "dark",
      headerClass: "p-3 border-bottom-0",
      bodyClass: "text-pre",
      footerClass: "p-3 border-top-0",
      centered: true,
    })
    .then((): void => {})
    .catch((): void => {});
}

function hasFirstChildLink(elem: Element | null): boolean {
  if (!elem) return false;
  if (elem && elem.tagName === "A") return true;

  return hasFirstChildLink(elem.firstChild as HTMLElement | null);
}

export function setSkiStyle(): void {
  // [ski-style] Adds "ski-style-link" to li a-tag elements
  document.querySelectorAll(".ski-style ul li").forEach((li: Element): void => {
    if (hasFirstChildLink(li)) {
      li.classList.add("ski-style-link");
    }
  });
}

export async function fetchFromUmbracoAPI(
  self: Vue,
  url: string,
  id = ""
): Promise<{ ok: boolean; json: any; statusCode: number }> {
  let reqUrl;
  try {
    reqUrl = window.location.origin + "/umbraco/api";
    reqUrl += url.replace(":id", id);

    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    const response = await timeout(
      fetch(reqUrl, { headers }),
      60 * 1000 // 60 seconds
    );

    if (!response.ok) throw response;

    setTimeout(setSkiStyle, 1);

    try {
      // Handle empty body or comment
      const json = await response.json();
      return { ok: true, json, statusCode: response.status };
    } catch (error) {
      return { ok: true, json: null, statusCode: response.status };
    }
  } catch (err) {
    logger.error("Fetch API - Failed", err);
    alertMessage(self);

    return { ok: false, json: null, statusCode: err.status };
  }
}

export function animateCSS(
  element: string,
  animationName: string,
  additional: string[] = [],
  callback: Function | null = null
): void {
  document.querySelectorAll(element).forEach((node): void => {
    node.classList.add("animated", animationName, ...additional);

    const handleAnimationEnd = (): void => {
      node.classList.remove("animated", animationName, ...additional);
      node.removeEventListener("animationend", handleAnimationEnd);

      if (typeof callback === "function") callback();
    };

    node.addEventListener("animationend", handleAnimationEnd);
  });
}

export function isDarkColor(theme: string): boolean {
  return [
    "primary",
    "light",
    "dark",
    "darkGreen",
    "cherry",
    "plum",
    "darkPurple",
    "lightPurple",
    "skiGreen",
  ].includes(theme);
}

export function compareValues(key: any, order = "asc"): any {
  return function innerSort(a: any, b: any): number {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export function groupBy(xs: any[], key: string): any {
  return xs.reduce(function (rv, x): {} {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function getFileTypeFromExtension(filename: string): string {
  if (!filename) return "";

  // TODO: Reaserch package that can do this better
  const fileTypeDictionary: Dictionary<string> = {
    docx: "Word",
    pdf: "PDF",
    xls: "Excel",
    xlsx: "Excel",
    zip: "ZIP",
    ppt: "Powerpoint",
    pptx: "Powerpoint",
  };

  const filenameSplitted = filename.split(".");

  // Returns empty string if no "." in filename
  if (filenameSplitted.length < 2) return "";

  // Returns empty string if no match with extension and fileTypeDictionary
  const extension = filenameSplitted[filenameSplitted.length - 1].toLowerCase();
  if (fileTypeDictionary[extension] === undefined) return "";

  return fileTypeDictionary[extension];
}

export function getDescription(self: Vue, titleKey: string): string {
  const { title, content } = self.$config.agreement.descriptions;

  const descriptions = self.$root.$data.itemDescriptions;
  if (!descriptions) return "";

  const descriptionItem = descriptions.find(
    (item: any): boolean => item[title] === titleKey
  );

  if (descriptionItem) return descriptionItem[content];

  return "";
}

interface Item {
  url: string;
  whiteHeader?: boolean;
  visible?: boolean;
  items?: Item[];
}

// Find recursive url path match and set whiteheader thereby
function pathWhiteHeaderHelper(
  path: string,
  item: Item
): boolean | undefined | null {
  let whiteHeader;
  const { items = [] } = item;

  items.forEach((i: Item): void => {
    const wh = pathWhiteHeaderHelper(path, i);

    if (typeof wh !== "undefined") {
      whiteHeader = wh;
    }
  });

  if (path === item.url || whiteHeader === null) {
    if (typeof item.whiteHeader === "undefined") return null;
    return item.whiteHeader;
  }

  return whiteHeader;
}

export function pathWhiteHeader(
  path: string,
  item: Item
): boolean | undefined | null {
  if (path.slice(-1) !== "/") path += "/";

  const urls = (path.match(/\//g) || []).map((_, idx): string =>
    path.replace(new RegExp(`([^/]*/?){${idx}}$`, "g"), "")
  );

  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];
    const wh = pathWhiteHeaderHelper(url, item);
    if (typeof wh !== "undefined") {
      return wh;
    }
  }
  return undefined;
}

export function getValue(keys: string | string[], obj: any): any {
  function mapper(v: any, k: string): any {
    if (!v) return null;
    if (typeof v === "string") return v;
    if (Array.isArray(v)) return v.map((i): any => mapper(i, k));

    return v[k];
  }

  if (Array.isArray(keys)) {
    const result = keys.reduce(mapper, obj);
    return Array.isArray(result) ? result.join(", ") : result;
  } else {
    return obj[keys];
  }
}

export function someMatch(str: string, items: string[]): boolean {
  return items.some((item): boolean =>
    new RegExp("^" + str.replace(/\*/g, ".*") + "$").test(item)
  );
}

export function downloadTableCSV(tableId: string, tableName: string): void {
  // Select rows from tableId
  const rows = document.querySelectorAll("table#" + tableId + " tr");
  // Construct csv
  const csv = [];
  for (let i = 0; i < rows.length; i++) {
    const row = [];
    const cols = rows[i].querySelectorAll<HTMLElement>("td, th");
    for (let j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      let data = cols[j].innerText
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/(\s\s)/gm, " ");
      // Escape double-quote with double-double-quote
      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push('"' + data + '"');
    }
    csv.push(row.join(";"));
  }
  const csvString = csv.join("\n");
  // Download it
  const filename = tableName + " " + new Date().toLocaleDateString() + ".csv";
  const link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csvString)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function convertCrmStatusToType(agreement: any): void {
  if (
    isAgreement(agreement.overallStatusName) ||
    isExpired(agreement.overallStatusName)
  ) {
    if (
      dayjs(agreement.expirationDateUtc).isBefore(dayjs().add(-1, "day").utc())
    ) {
      agreement.type = "Tidligere aftale";
      agreement.variant = "danger";
    } else {
      agreement.type = "Aftale";
      agreement.variant = "aftale";
    }
  } else if (isTender(agreement.overallStatusName)) {
    agreement.type = "Udbud";
    agreement.variant = "udbud";
  }
}

export function saveValueToLocalStorage(name: string, val: object): void {
  if (!!name && !!val) {
    try {
      var jsonVal = JSON.stringify(val);
      localStorage.setItem(name, jsonVal);
    } catch (err) {
      console.log(err);
    }
  }
}

export function toJSON(data: any): any {
  data = typeof data === "object" ? data : {};
  if (Array.isArray(data.skiAgreementlist)) {
    data.skiAgreementlist = [];
    for (const item of data.skiAgreementlist)
      data.skiAgreementlist.push(item.toJSON());
  }
  if (Array.isArray(data.skiGroups)) {
    data.skiGroups = [];
    for (const item of data.skiGroups) data.skiGroups.push(item.toJSON());
  }
  if (Array.isArray(data.skiSourcingprograms)) {
    data.skiSourcingprograms = [];
    for (const item of data.skiSourcingprograms)
      data.skiSourcingprograms.push(item.toJSON());
  }
  if (Array.isArray(data.skiOverallStatuses)) {
    data.skiOverallStatuses = [];
    for (const item of data.skiOverallStatuses)
      data.skiOverallStatuses.push(item.toJSON());
  }
  return data;
}

export function loadValueFromLocalStorage(name: string): any {
  if (name) {
    const encodedVal = localStorage.getItem(name);
    if (!encodedVal) {
      return null;
    }
    if (isJson(encodedVal)) {
      const val = JSON.parse(encodedVal as string);
      return val;
    }
  }
  return null;
}

export function convertSubAgreement(subAgreement: any, agreement: any): any {
  return {
    agreementId: subAgreement.subAgreementId,
    overallStatusName: subAgreement.overallStatusName,
    displayNameLong: subAgreement.displayNameLong,
    sourcingPrograms: agreement.sourcingPrograms,
    group: agreement.group,
  };
}

function GetCalendarFields(agreements: any[]) {
  const calendars = agreements
    .flatMap((agreement) => agreement.calendars || ([] as CalendarDbDto[]))
    .filter((calendar) => calendar.includeInExcel);

  // remove duplicates
  return calendars.reduce((list, calendar) => {
    if (!list.includes(calendar.skiName || "")) {
      list.push(calendar.skiName || "");
    }
    return list;
  }, [] as string[]);
}

export async function downloadCompleteSkiTable(
  self: Vue,
  tableName: string,
  fields: Field[],
  agreements: any[]
): Promise<void> {
  const csv = [];
  const headerRow = [] as string[];

  const agreementsContainsTrafficlight = AgreementsContainsTrafficlight(agreements);

  const columns = [...fields];
  let printableFields = [] as Field[];

  if (!agreementsContainsTrafficlight) {
    printableFields = columns.filter(
      (column: Field) => column.key !== "highestPriorityCustomerApplication"
    );
  } else {
    printableFields = [...columns];
  }

  const extraFields = GetCalendarFields(agreements);

  // Adding two extra filter criterias manually since they not exist in the original fields
  printableFields.push({ label: "Aftaleområde", key: "group" });
  printableFields.push({ label: "Indkøbsprogram", key: "sourcingPrograms" });

  printableFields.forEach((field): void => {
    if (field.extraFields) {
      extraFields.forEach((f: string): void => {
        headerRow.push(f);
      });
    } else {
      headerRow.push(field.label);
    }
  });
  csv.push(headerRow.join(";"));

  agreements.forEach((agreement): void => {
    const row = [] as any[];
    printableFields.forEach((field): void => {
      if (field.extraFields) {
        for (const calendarField of extraFields) {
          const calendar = agreement.calendars
            .sort(
              (a: CalendarDbDto, b: CalendarDbDto) =>
                new Date(b.skiStartdate || 0).getTime() -
                new Date(a.skiStartdate || 0).getTime()
            )
            .find((x: CalendarDbDto) => x.skiName === calendarField);
          if (calendar && calendar.skiAlternativeenddate) {
            row.push(`"${calendar.skiAlternativeenddate}"`);
          } else {
            row.push(" ");
          }
        }
      } else if (
        field.key === self.$config.agreement.application.activeApplication &&
        agreementsContainsTrafficlight
      ) {
        const applicationField =
          agreement[self.$config.agreement.application.activeApplication];
        const applicationText =
          applicationField !== null
            ? applicationField[self.$config.agreement.application.label]
            : "-";
        row.push(`"${applicationText}"`);
      } else if (
        field.key.toLowerCase().includes("expectedintoforcestartdateutc")
      ) {
        if (agreement.expectedIntoForceAlternateDisplayDate) {
          row.push(`"${agreement.expectedIntoForceAlternateDisplayDate}"`);
        } else {
          row.push(`"${formatDate(agreement[field.key])}"`);
        }
      } else if (
        field.key.toLowerCase().includes("date") &&
        !field.key.toLowerCase().includes("highestprioritycustomerapplication")
      ) {
        row.push(`"${formatDate(agreement[field.key])}"`);
      } else if (
        field.key
          .toLowerCase()
          .includes("highestprioritycustomerapplication") &&
        agreementsContainsTrafficlight
      ) {
        row.push(
          `"${formatDate(
            agreement.highestPriorityCustomerApplication[
              field.key.substring(field.key.indexOf(".") + 1)
            ]
          )}"`
        );
      } else if (field.key.toLowerCase().includes("skinextmilestone")) {
        if (agreement.skiNextMilestone) {
          if (agreement.skiNextMilestone.skiAlternavtivenenddate) {
            row.push(
              `"${agreement.skiNextMilestone.name} ${agreement.skiNextMilestone.skiAlternavtivenenddate}"`
            );
          } else {
            row.push(
              `"${agreement.skiNextMilestone.name} ${formatDate(
                agreement.skiNextMilestone.skiStartdateUtc
              )}"`
            );
          }
        } else {
          row.push(" ");
        }
      } else if (field.key.toLowerCase().includes("group")) {
        row.push(
          `"${agreement.group.charAt(0).toUpperCase()}${agreement.group
            .slice(1)
            .toLowerCase()}"`
        );
      } else if (field.key.toLowerCase().includes("sourcingprograms")) {
        const sourcingProgramList = (agreement.sourcingPrograms || []).map(
          (x: any) => x.name || x
        );
        row.push(`"${sourcingProgramList.join(", ")}"`);
      } else {
        row.push(`"${agreement[field.key] ? agreement[field.key] : ""}"`);
      }
    });

    csv.push(row.join(";"));
  });

  const csvString = csv.join("\n");
  // Download it
  const filename = tableName + " " + new Date().toLocaleDateString() + ".csv";
  const link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csvString)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function saveUpdateId(statusId: string): void {
  if (statusId) {
    saveValueToLocalStorage("statusUpdateId", { id: statusId });
  }
}

export function loadStatusUpdateId(): any {
  return loadValueFromLocalStorage("statusUpdateId");
}

export function deleteUpdateId(): void {
  localStorage.removeItem("statusUpdateId");
}

export function getCustomerApplication(
  self: Vue,
  customerApplication: any,
  customerApplicationPriorities: any
): any {
  var priorityObj = customerApplicationPriorities.find(
    (ele: any): any =>
      ele.type ===
      customerApplication[self.$config.agreement.application.type].toLowerCase()
  );
  customerApplication[self.$config.agreement.application.anvendelse] =
    priorityObj.priority;
  return customerApplication;
}

export function highestCustomerApplication(
  self: Vue,
  customerApplicationList: any,
  priorities: any | null
): any {
  var customerApplicationPriorities =
    priorities || self.$config.agreement.application.priorities;
  if (!customerApplicationList || !self) {
    return null;
  }
  if (!Array.isArray(customerApplicationList)) {
    return getCustomerApplication(
      self,
      customerApplicationList,
      customerApplicationPriorities
    );
  }
  if (customerApplicationList.length === 1) {
    return getCustomerApplication(
      self,
      customerApplicationList[0],
      customerApplicationPriorities
    );
  }
  var returnCustomerApplication;
  var priority = 50;
  for (var i = 0; i < customerApplicationList.length; i++) {
    var temp = customerApplicationList[i];
    var priorityObj = customerApplicationPriorities.find(
      (ele: any): any =>
        ele.type === temp[self.$config.agreement.application.type].toLowerCase()
    );
    if (priorityObj && priorityObj.priority < priority) {
      priority = priorityObj.priority;
      returnCustomerApplication = temp;
      returnCustomerApplication[self.$config.agreement.application.anvendelse] =
        priority;
    }
  }
  return returnCustomerApplication;
}

export function setHighestPriorityCustomerApplication(
  self: Vue,
  agreements: any,
  applicationKeys: any = null
): void {
  var priorities = null;
  if (applicationKeys) {
    priorities = self.$config.agreement.application.priorities.filter(
      (priority: any): any =>
        applicationKeys.some(
          (key: any): any => priority.type === key.toLowerCase()
        )
    );
  }
  if (Array.isArray(agreements)) {
    for (var i = 0; i < agreements.length; i++) {
      var agreement = agreements[i];
      agreement[self.$config.agreement.application.activeApplication] =
        highestCustomerApplication(
          self,
          agreement.customerApplication,
          priorities
        );
    }
  } else {
    agreements[self.$config.agreement.application.activeApplication] =
      highestCustomerApplication(
        self,
        agreements.customerApplication,
        priorities
      );
  }
}

export function downloadExcelSheet(
  self: Vue,
  filteredAgreements: AgreementItem[],
  fields: Field[],
  csvFilename: string
): void {
  setHighestPriorityCustomerApplication(self, filteredAgreements);
  if (filteredAgreements !== null && fields != null) {
    const filteredFields = [...fields].filter(
      (field) => field.toString() !== "index"
    );
    downloadCompleteSkiTable(
      self,
      csvFilename,
      filteredFields,
      filteredAgreements
    );
  }
}

export function printCurrentPage() {
  window.print();
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function replaceAll(str: string, find: string, replace: string): string {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}
