




























import Vue from "vue";
import { formatDate } from "@/lib/dateUtilites";
import SkiImage from "@/lib/components/SkiImage.vue";

export default Vue.extend({
  components: { SkiImage },
  props: {
    headline: { type: String, required: true },
    date: { type: String, required: true },
    type: { type: String, required: true },
    image: { type: String, default: null },
    imageAltText: { type: String, default: null },
    focusImage: { type: Boolean, default: false }
  },
  methods: {
    formatDate
  }
});
