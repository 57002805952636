























import { resetHashtagValue } from "@/lib/urlUtilities";
import {
  replaceHexWithNordicLettersAndWhitespace,
  scrollToAnchorItem,
} from "@/lib/utilities";
import { monsidoUtilities } from "@/lib/utilities/monsidoUtilities";
import Vue from "vue";

export default Vue.extend({
  props: {
    headline: { type: String, default: null },
    removeBottomMargin: { type: Boolean, default: false },
    factboxHeadline: { type: String, default: null },
    showFactbox: { type: Boolean, default: false },
  },
  data() {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(
        window.location.hash
      ),
      hashtagItem: "",
      hashtagFreeTextId: "#" + this.headline.toLowerCase(),
    };
  },
  created() {
    window.addEventListener("MonsidoCookieOnHide", this.trackDownloadEvents);
  },
  mounted() {
    this.trackDownloadEvents();

    var h4List = document.getElementsByTagName("h4");
    for (let element of h4List) {
      element.id = element.innerText.toLowerCase();
    }

    var h5list = document.getElementsByTagName("h5");
    for (let element of h5list) {
      element.id = element.innerText.toLowerCase();
    }

    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(
      this.hashtagFromWindow
    );
    if (this.hashtagItem === this.hashtagFreeTextId) {
      resetHashtagValue();
      this.scrollToAnchorItem(this.hashtagFreeTextId);
    } else {
      for (let element of h4List) {
        if (element.id === this.hashtagItem.split("#")[1]) {
          resetHashtagValue();
          this.scrollToAnchorItem(this.hashtagItem);
        }
      }

      for (let element of h5list) {
        if (element.id === this.hashtagItem.split("#")[1]) {
          resetHashtagValue();
          this.scrollToAnchorItem(this.hashtagItem);
        }
      }
    }
  },
  activated() {
    this.trackDownloadEvents();
  },
  beforeDestroy() {
    window.removeEventListener("MonsidoCookieOnHide", this.trackDownloadEvents);
  },
  methods: {
    scrollToAnchorItem(freetextId: string) {
      // Remove hashtag from prop to get the id of the element
      freetextId = freetextId.split("#")[1];
      scrollToAnchorItem(freetextId, 0, 24);
    },
    trackDownloadEvents() {
      const div = this.$refs.htmlContainer as HTMLAnchorElement;
      var docLinks = monsidoUtilities.getDocumentLinksFromRef(div);
      docLinks.forEach((link) => {
        monsidoUtilities.updateMonsidoEventTracker(
          link,
          link.href,
          link.title ?? link.text
        );
      });
    },
  },
});
