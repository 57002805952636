
































































































































































import Vue, { PropType } from "vue";
import SkiRoundButton from "@/lib/components/SkiRoundButton.vue";
import SkiFilter from "@/lib/components/SkiFilter.vue";
import RichText from "@/lib/components/shared/RichText.vue";
import Arrow from "@/lib/assets/icon/arrow.svg";
import Search from "@/lib/assets/icon/search.svg";
import SmallClose from "@/lib/assets/smallicon/close.svg";
import Closed from "@/lib/assets/smallicon/closed.svg";
import {
  isEmpty,
  getValue,
  convertCrmStatusToType,
  alertMessage,
  generateId,
} from "@/lib/utilities";
import { isTender } from "@/lib/agreementUtilities";
import {
  AgreementListViewDto,
  SubagreementListViewDto,
} from "@/lib/api/APIService";
import { StatusUpdateService } from "@/lib/businessLogic/services/StatusUpdateService";
import { AGREEMENT_TYPES } from "@/lib/constants/statusUpdateConstants";

interface Data {
  selectedAgreements: string[];
  isExpandedArray: any[];
  filters: { key: string; value: string }[];
  sortBy?: string;
  filteredAgreements: AgreementListViewDto[];
  allAgreementsList: AgreementListViewDto[] | null;
  searchQuery: string;
  filterOptions: any[] | null;
  sortOption: any[] | null;
  subscribedAgreementIds: string[] | null;
  filterIndex: string;
  tableFilterData: {
    skiSourcingprograms?: any[];
    skiGroups?: any[];
    agreementTypes: any[];
  };
  dataLoadingFinished: boolean;
  isLoading: boolean;
  statusUpdateService: StatusUpdateService;
}

export default Vue.extend({
  components: {
    SkiFilter,
    SkiRoundButton,
    RichText,
    Arrow,
    Search,
    SmallClose,
    Closed,
  },
  props: {
    title: { type: String, required: true },
    text: { type: String, required: true },
    submitButtonText: { type: String, required: true },
    removeAllSubsText: { type: String, required: true },
    subscribedAgreementIdsFromProp: {
      type: Array as PropType<string[]>,
      default: null,
    },
    filterOptionsFromProp: { type: Array, default: null },
    sortOptions: { type: Array, default: null },
    clickSendFunction: { type: Function, required: true },
    contactGuid: { type: String, default: null },
  },
  data(): Data {
    return {
      selectedAgreements: [],
      isExpandedArray: [],
      filters: [],
      sortBy: "agreementId",
      filteredAgreements: [],
      allAgreementsList: null,
      searchQuery: "",
      filterOptions: this.filterOptionsFromProp,
      sortOption: [],
      subscribedAgreementIds: this.subscribedAgreementIdsFromProp,
      filterIndex: generateId(),
      tableFilterData: {
        agreementTypes: AGREEMENT_TYPES,
      },
      dataLoadingFinished: false,
      isLoading: false,
      statusUpdateService: new StatusUpdateService(this.$api, this.$root),
    };
  },
  created() {
    this.setSelectedArray();
    this.fetchAgreements();
  },
  methods: {
    isEmpty,
    shouldSubagreementShow(subAgreement: SubagreementListViewDto): boolean {
      const { statusFilter } = this.$config.agreement.subAgreement;
      return statusFilter.includes(subAgreement.overallStatusName)
        ? true
        : subAgreement.overallStatusName !== undefined &&
            isTender(subAgreement.overallStatusName) &&
            subAgreement.showOnAgreement === true;
    },
    isAnySubagreementShown(agreement: AgreementListViewDto): boolean {
      if (!agreement.subagreement) {
        return false;
      }
      return agreement.subagreement.some(this.shouldSubagreementShow);
    },
    toggleExpand(accordionId: any, event?: Event) {
      if (event) event.preventDefault();
      var ex = this.isExpandedArray.find(({ id }) => id === accordionId) as any;
      if (ex) {
        ex.isExpanded = !ex.isExpanded;
      }
    },
    isAccordionExpanded(accordionId: any) {
      var ex = this.isExpandedArray.find(({ id }) => id === accordionId) as any;
      if (ex) {
        return ex.isExpanded;
      }
      this.isExpandedArray.push({ id: accordionId, isExpanded: false });
      return false;
    },
    clearChosen(event?: Event) {
      if (event) event.preventDefault();
      this.selectedAgreements = [];
    },
    save(event?: Event) {
      if (event) event.preventDefault();
      this.sendUpdatedList();
    },
    setSelectedArray() {
      if (this.subscribedAgreementIds) {
        this.selectedAgreements = this.subscribedAgreementIds as string[];
      }
    },
    setFilter(filters: Data["filters"]) {
      this.filters = filters;
      this.filterAgreements();
    },
    setSorting(sortBy: string) {
      this.sortBy = sortBy;
      this.sortAgreements();
    },
    filterAgreements() {
      if (!this.allAgreementsList) return;
      if (!this.filters || this.filters.length === 0) {
        this.filteredAgreements = this.filterByQuery(this.allAgreementsList);
        return;
      }
      var filterItems = [];
      var keys = [...new Set(this.filters.map((item) => item.key))];
      var filterLists = {} as any;
      var normFilters = this.filters;
      keys.forEach(function (key) {
        normFilters.forEach(function (obj) {
          if (obj.key === key) {
            if (!filterLists[key]) {
              filterLists[key] = [];
            }
            filterLists[key].push(obj.value);
          }
        });
      });
      filterItems = this.allAgreementsList.filter((item: any) => {
        for (var id in keys) {
          var key = getValue(id, keys);
          if (!this.filterAgreementItem(key, filterLists[key], item)) {
            return false;
          }
        }
        return true;
      });
      this.filteredAgreements = this.filterByQuery(filterItems);
    },
    filterAgreementItem(key: string, filters: [], agreement: any) {
      if (agreement[key] == null) {
        return this.checkSubAgreements(key, filters, agreement.subagreement);
      } else {
        return this.checkFilterValue(key, filters, agreement);
      }
    },
    checkSubAgreements(key: string, filters: [], agreements: any[]) {
      return !!agreements.find((item) =>
        this.checkFilterValue(key, filters, item)
      );
    },
    checkFilterValue(key: string, filters: [], agreement: any) {
      if (Array.isArray(agreement[key])) {
        return agreement[key].some((val: any) =>
          filters.some((filter: any) => filter === val.name)
        );
      }
      return filters.some((filter: any) => filter === agreement[key]);
    },
    filterByQuery(filteredItems: any[]) {
      if (!this.searchQuery) return filteredItems;
      return filteredItems.filter((item: any) => {
        return (
          this.itemQueryFiltering(item) || this.itemDisplayNumberCheck(item)
        );
      });
    },
    itemQueryFiltering(item: any): boolean {
      var sub = getValue("subAgreementName", item);
      if (sub) {
        return sub.toLowerCase().includes(this.searchQuery.toLowerCase());
      } else {
        return item.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      }
    },
    itemDisplayNumberCheck(item: any): boolean {
      var number = item.displayNumbers;
      if (number === undefined || number === null) return false;
      // Handles if search for displayNumber is done with space instead of a dot '.'.
      if (!this.searchQuery.includes(".")) {
        number = number.replace(".", " ");
      }
      return number.includes(this.searchQuery);
    },
    async fetchAgreements() {
      this.isLoading = true;
      try {
        const agreements =
          await this.$api.AgreementsRepository.getAllAgreements(
            undefined,
            false
          );

        const data = agreements;
        const preItems = data.skiAgreementlist ?? [];

        for (let i = 0; i < preItems.length; i++) {
          preItems[i] = this.setTypeBasedOnStatus(preItems[i]);
        }

        this.allAgreementsList = preItems ?? null;
        this.tableFilterData.skiSourcingprograms = data.skiSourcingprograms;
        this.tableFilterData.skiGroups = data.skiGroups;

        this.filterSortOptions();
        this.filterAgreements();
        this.sortAgreements();
        this.dataLoadingFinished = true;
      } catch (err) {
        alertMessage(this);
      } finally {
        this.isLoading = false;
      }
    },
    setTypeBasedOnStatus(item: any) {
      if (!item.overallStatusName) {
        for (var id in item.subagreement) {
          var sub = getValue(id, item.subagreement);
          item.subagreement[id] = this.setTypeBasedOnStatus(sub);
        }
      }
      convertCrmStatusToType(item);
      return item;
    },
    sortAgreements() {
      if (this.filteredAgreements && !!this.sortBy) {
        var sortBy = this.sortBy as string;
        this.filteredAgreements.sort(function (item1, item2) {
          if (item1[sortBy] > item2[sortBy]) {
            return 1;
          }
          if (item1[sortBy] < item2[sortBy]) {
            return -1;
          }
          return 0;
        });
      }
    },
    async sendUpdatedList() {
      const userData = this.$store.state.profile;
      
      try {
        let response;
        if (this.contactGuid) {
          response = await this.statusUpdateService.updateStatusSubscriptionsByGuid(
            this.contactGuid,
            this.selectedAgreements
          );
        } else if (userData?.email) {
          response = await this.statusUpdateService.updateStatusSubscriptionsByEmail(
            userData.email,
            this.selectedAgreements
          );
        } else {
          throw new Error("No user identifier available");
        }

        this.clickSendFunction(response);
      } catch (err) {
        alertMessage(this);
      }
    },
    searchSubmit(event: Event) {
      event.preventDefault();
      this.filterAgreements();
    },
    clearSearch(event: Event) {
      event.preventDefault();
      this.searchQuery = "";
      this.filterAgreements();
    },
    filterSortOptions() {
      var sortOptions = this.sortOptions as any[];
      this.sortOption = sortOptions.filter(
        (option) => option.key !== "latestStatusUpdateUtc"
      );
    },
    getFilteredSubagreements(
      agreement: AgreementListViewDto
    ): SubagreementListViewDto[] {
      if (!agreement.subagreement || isEmpty(agreement.subagreement)) {
        return [];
      }
      return agreement.subagreement.filter((sub) =>
        this.shouldSubagreementShow(sub)
      );
    },
  },
});
