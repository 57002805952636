


























import Vue from "vue";
import EventCardBeam from "@/lib/components/EventCardBeam.vue";
import SkiLink from "@/lib/components/SkiLink.vue";
import { isEmpty, replaceHexWithNordicLettersAndWhitespace, scrollToItemTop } from "@/lib/utilities";
import { resetHashtagValue } from "@/lib/urlUtilities";

interface Data {
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagEvent: string;
}

export default Vue.extend({
  components: { SkiLink, EventCardBeam },
  props: {
    title: { type: String, required: true },
    goToLabel: { type: String, required: true },
    goToUrl: { type: String, required: true },
    goToTarget: { type: String, default: null },
    events: { type: Array, required: true }
  },
  data(): Data {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagEvent: "#" + this.title.toLowerCase()
    };
  },
  async mounted() {
    this.hashtagItem = this.hashtagFromWindow;
    if (this.hashtagItem === this.hashtagEvent) {
      resetHashtagValue();
      await this.scrollToAnchorItem();
    }
  },
  methods: {
    isEmpty,
    async scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var eventId = this.hashtagEvent.split("#")[1];
      var element = document.getElementById(eventId);
      if (element) {
        scrollToItemTop(element, 200);
      }
    }
  }
});
