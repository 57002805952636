




















































import Vue, { PropType } from "vue";
import Close from "@/lib/assets/icon/close.svg";
import MySkiSubscriptionModalAgreements from "@/lib/partials/my-ski/modules/MySkiStatusUpdate/MySkiSubscriptionModalAgreements.vue";
import MySkiSubscriptionModalSubscribed from "@/lib/partials/my-ski/modules/MySkiStatusUpdate/MySkiSubscriptionModalSubscribed.vue";
interface Data {
  clickedSave: boolean;
  subscribedAgreementIds: string[];
}

export default Vue.extend({
  components: {
    Close,
    MySkiSubscriptionModalAgreements,
    MySkiSubscriptionModalSubscribed,
  },
  props: {
    id: { type: String, default: "mySkiModal" },
    title: { type: String, required: true },
    text: { type: String },
    submitButtonText: { type: String, required: true },
    titleChanged: { type: String, required: true },
    textChanged: { type: String, required: true },
    removeAllSubsText: { type: String, required: true },
    subscribedAgreementIdsProps: {
      type: Array as PropType<string[]>,
      default: null,
    },
    filterOptions: { type: Array, default: null },
    sortOptions: { type: Array, default: null },
    contactGuid: { type: String, required: false },
    closeOnClickOutside: {
      type: Boolean,
      default: true
    }
  },
  data(): Data {
    return {
      clickedSave: false,
      subscribedAgreementIds: this.subscribedAgreementIdsProps || [],
    };
  },
  watch: {
    subscribedAgreementIdsProps: {
      immediate: true,
      handler(newIds) {
        this.subscribedAgreementIds = [...(newIds || [])];
      },
    },
  },
  methods: {
    changeStateOnClick(json: any) {
      this.clickedSave = true;
    },
    // This is needed because the modal is not destroyed after saved and hidden on mit ski.
    resetModalState() {
      this.clickedSave = false;
    }
  },
});
