






















































import Vue from "vue";

import EmployeeFilter from "./EmployeeFilter.vue";
import EmployeeList from "./EmployeeList.vue";

import {
  EmployeeListViewDto,
  EmployeeListViewDtoWrapper,
} from "@/lib/api/APIService";

import { compareValues, isDarkColor } from "@/lib/utilities";

interface EmployeeOverviewItem {
  skiDescription: string;
  skiName: string;
  skiOrderNummer: string;
  employees: EmployeeItem[];
}

interface EmployeeItem extends EmployeeListViewDto {
  [key: string]: any;
}

interface DepartmentItem {
  key: any;
  label: string;
}

interface Data {
  item: EmployeeListViewDtoWrapper;
  departments: DepartmentItem[];
  selectedDepartment: DepartmentItem;
  employeeOverview: EmployeeOverviewItem[];
  filteredEmployeeOverview: EmployeeOverviewItem[];
  searchQuery: string;
  employeesLoaded: boolean;
}

export default Vue.extend({
  components: {
    EmployeeFilter,
    EmployeeList,
  },
  provide() {
    return {
      lazyLoadImages: this.lazyloadImages
    };
  },
  props: {
    title: { type: String, required: true },
    linkLabel: { type: String, default: "" },
    linkUrl: { type: String, default: "" },
    linkTarget: { type: String, default: "" },
    bgVariant: { type: String, required: true },
    printVariant: { type: String, required: true },
    filterVariant: { type: String, required: true },
    lazyloadImages: { type: Boolean, default: true },
  },
  data(): Data {
    return {
      item: new EmployeeListViewDtoWrapper(),
      departments: [{ key: "Afdeling", label: "Vælg alle" }],
      selectedDepartment: { key: "Afdeling", label: "Vælg alle" },
      employeeOverview: [],
      filteredEmployeeOverview: [],
      searchQuery: "",
      employeesLoaded: false,
    };
  },
  watch: {
    searchQuery() {
      // Filter employees based on search query
      this.departmentEmployeeArray(this.item, this.searchQuery);

      // Filter employees based on selected department
      this.selectDepartment(this.selectedDepartment);
    },
  },
  mounted() {
    this.fetchAPIData();
  },
  methods: {
    isDarkColor,
    async fetchAPIData() {
      const employees =
        (await this.$api.EmployeeRepository.getAllEmployees()) as EmployeeListViewDtoWrapper;
      if (employees != null) {
        this.item = employees;
      }
      this.departmentEmployeeArray(this.item, "");
    },
    departmentEmployeeArray(
      employeeData: EmployeeListViewDtoWrapper,
      query: string
    ) {
      const employeeApiData =
        employeeData.skiEmployeesList !== undefined
          ? employeeData.skiEmployeesList
          : [];
      const apiEmployees: EmployeeItem[] = this.searchEmployees(
        employeeApiData,
        query.toLowerCase().trim()
      );
      const apiDepartments: any[] =
        employeeData.skiTeamlist !== undefined ? employeeData.skiTeamlist : [];

      // Defining and sorting complete employee overview list
      const employeeByDepartmentSorted: EmployeeOverviewItem[] =
        this.sortEmployees(apiDepartments, apiEmployees);

      // Filtering out departments without employees
      this.employeeOverview = employeeByDepartmentSorted.filter(
        (dep) => dep.employees.length
      );

      this.departments = this.createDepartmentList(employeeByDepartmentSorted);

      // Setting initial filter (All)
      this.filteredEmployeeOverview = this.employeeOverview;

      this.employeesLoaded = true;
    },
    createDepartmentList(
      employeeByDepartmentSorted: EmployeeOverviewItem[]
    ): DepartmentItem[] {
      const filteredDepartments = employeeByDepartmentSorted.map((dep) => {
        return {
          key: dep.skiName,
          label: dep.skiName,
        };
      });

      return this.departments.length === 1
        ? this.departments.concat(filteredDepartments)
        : this.departments;
    },
    sortEmployees(
      departmentData: any[],
      employeeData: EmployeeItem[]
    ): EmployeeOverviewItem[] {
      return departmentData.map((dep) => {
        const filteredEmployees = employeeData.filter(
          (emp) => emp.team === dep.skiName
        );

        const primarySortKey: string = this.$config.employee.sortKeys[0];
        const secondarySortKey: string = this.$config.employee.sortKeys[1];

        var fiteredByPrimarySortKey = filteredEmployees
          .filter((emp) => emp[primarySortKey])
          .concat()
          .sort(compareValues(primarySortKey));
        var fiteredBySecondarySortKey = filteredEmployees
          .filter((emp) => !emp[primarySortKey])
          .concat()
          .sort(compareValues(secondarySortKey));

        dep.employees = fiteredByPrimarySortKey.concat(
          fiteredBySecondarySortKey
        );

        return dep;
      });
    },
    searchEmployees(
      apiEmployees: EmployeeListViewDto[],
      query: string
    ): EmployeeListViewDto[] {
      // Searching complete employee overview list
      let filteredEmployees = apiEmployees;

      if (query !== "") {
        filteredEmployees = apiEmployees.filter((emp) => {
          return (
            this.filterByName(emp, query) ||
            this.filterByParameter(emp, query, "jobtitle") ||
            this.filterByParameter(emp, query, "team") ||
            this.filterByParameter(emp, query, "mail") ||
            this.filterByPhone(emp, query)
          );
        });
      }
      return filteredEmployees;
    },
    getFullName(emp: any): string {
      return emp.firstname !== null && emp.lastname !== null
        ? emp.firstname.toLowerCase() + " " + emp.lastname.toLowerCase()
        : "";
    },
    filterByName(emp: EmployeeListViewDto, query: string): boolean {
      const fullName = this.getFullName(emp);
      return (
        (fullName !== "" && this.matchedName(query, [fullName])) ||
        this.matchedName(
          query,
          emp.firstname !== undefined && emp.firstname !== null
            ? emp.firstname.toLowerCase().split(" ")
            : []
        ) ||
        this.matchedName(
          query,
          emp.lastname !== undefined && emp.lastname !== null
            ? emp.lastname.toLowerCase().split(" ")
            : []
        )
      );
    },
    filterByParameter(
      emp: EmployeeItem,
      query: string,
      parameter: string
    ): boolean {
      return (
        emp[parameter] !== undefined &&
        emp[parameter] !== null &&
        this.matchedName(query, emp[parameter].toLowerCase().split(" "))
      );
    },
    filterByPhone(emp: EmployeeListViewDto, query: string): boolean {
      return (
        emp.telephone !== undefined &&
        emp.telephone !== null &&
        emp.telephone
          .split(" ")
          .join("")
          .toLowerCase()
          .includes(query.split(" ").join(""))
      );
    },
    matchedName(str: string, items: string[]): boolean {
      return items.some((item): boolean =>
        new RegExp("^" + str.replace(/\*/g, ".*")).test(item)
      );
    },
    selectDepartment(selectedDepartment: DepartmentItem) {
      this.selectedDepartment = selectedDepartment;
      if (selectedDepartment.key === this.$config.employee.departmentLabel) {
        this.filteredEmployeeOverview = this.employeeOverview;
      } else {
        this.filteredEmployeeOverview = this.employeeOverview.filter(
          (dep) => dep.skiName === selectedDepartment.key
        );
      }
    },
    searchTest(query: string) {
      this.searchQuery = query;
      this.selectDepartment(this.selectedDepartment);
    },
  },
});
