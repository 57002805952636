/**
 * This method find the value denoted by propertyPath from an arbitrary object
 * @param obj // An object of any type
 * @param propertyPath  // Path to the property in the object tree (e.g. 'highestPriorityCustomerApplication.enddate' for type OrgAgreementItem)
 * @returns // Returns the value corresponding from the object to the proprty for propertyPath or undefined of no propertyPath is provided
 */

export function GetValueByPropertyPath(obj: any, propertyPath: string): any {
  const properties = propertyPath.split(".");

  if(properties.length === 0 || propertyPath === "") {
    return undefined;
  }

  let nestedObj = obj;
  for (const property of properties) {
    nestedObj = nestedObj[property];
    if (!nestedObj) {
      return null;
    }
  }

  return nestedObj;
}
