import { StatusUpdateProgressStatus } from "@/lib/api/APIService";
import { SKIRepository } from "@/lib/api/SKIRepository";
import { loadStatusUpdateId, deleteUpdateId, saveUpdateId } from "@/lib/utilities";
import Vue from 'vue';

export enum PollingEvents {
  Started = 'polling-started',
  Complete = 'polling-complete',
  Error = 'polling-error'
}

export class StatusUpdatePollingService {
  private readonly POLLING_INTERVAL_MS = 2000;
  private api: SKIRepository;
  private vueRoot: Vue;

  constructor(api: SKIRepository, vueRoot: Vue) {
    this.api = api;
    this.vueRoot = vueRoot;
  }

  async waitForPendingUpdate(): Promise<boolean> {
    const pendingUpdate = loadStatusUpdateId();
    if (!pendingUpdate) {
      return false;
    }

    return new Promise((resolve) => {
      this.pollUpdateStatus(pendingUpdate.id);
      this.vueRoot.$once(PollingEvents.Complete, () => resolve(true));
      this.vueRoot.$once(PollingEvents.Error, () => resolve(false));
    });
  }

  pollUpdateStatus(statusId: string): void {
    saveUpdateId(statusId);
    this.vueRoot.$emit(PollingEvents.Started);

    const interval = setInterval(async () => {
      try {
        const response = await this.api.StatusUpdateRepository.getStatus(statusId);
        
        if (response.status === StatusUpdateProgressStatus._1) {
          this.stopPolling(interval, PollingEvents.Complete);
        }
      } catch {
        this.stopPolling(interval, PollingEvents.Error);
      }
    }, this.POLLING_INTERVAL_MS);
  }

  private stopPolling(interval: NodeJS.Timer, pollingEvent: PollingEvents): void {
    clearInterval(interval);
    deleteUpdateId();
    this.vueRoot.$emit(pollingEvent);
  }
} 