

















































import Vue from "vue";
import SkiAspect from "@/lib/components/SkiAspect.vue";
import SkiLink from "@/lib/components/SkiLink.vue";
import ItemCard from "@/lib/components/ItemCard.vue";
import { fetchFromUmbracoAPI, isEmpty } from "@/lib/utilities";

interface Data {
  articles: any[] | null;
}

export default Vue.extend({
  components: { SkiLink, SkiAspect, ItemCard },
  props: {
    latestNews: { type: Array, required: false, default: null },
    focused: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: null },
    pageTags: { type: Array, required: false, default: null },
    pageId: { type: Number, required: false, default: 0 },
    pages: { type: Array, required: false, default: null },
    showMoreLabel: { type: String, required: false, default: null },
    showMoreLink: { type: String, required: false, default: null },
    showMoreTarget: { type: String, required: false, default: "false" },
  },
  data(): Data {
    return {
      articles: null,
    };
  },
  computed: {
    showLink(): boolean {
      if (!this.showMoreLabel || !this.showMoreLink) return false;

      return true;
    },
  },
  created() {
    var apiTagObject = this.$root.$data.item
      ? this.$root.$data.item.agreementId
      : null;

    if (this.latestNews) {
      this.articles = this.latestNews;
    } else if (this.pages) {
      this.articles = this.pages;
    } else if (this.pageTags && this.pageTags.length) {
      this.fetchAPIData(this.pageTags, false);
    } else if (apiTagObject != null && this.pageTags == null) {
      this.fetchAPIData(apiTagObject, true);
    }
  },
  methods: {
    async fetchAPIData(tags: any[], agreementOnly: boolean) {
      if (!tags) return;

      let url = "/tags/getcontentbytags/?tags=:tags&excludeid=:id";

      if (!agreementOnly) {
        var queryTags = tags.join(",");
        url = url.replace(":tags", queryTags);
      } else {
        url = url.replace(":tags", String(tags));
      }

      const response = await fetchFromUmbracoAPI(this, url, this.pageId.toString());
      if (response.ok) {
        this.articles = response.json;
      }
    },
    numberOfColumns(index: number): string {
      if (!this.articles) return "";
      var noArticles = !isEmpty(this.articles) ? this.articles.length : 0;
      if (noArticles === 1) return "10";
      if (noArticles === 3) return "4";
      if (noArticles === 2 && index === 0) return "7";
      if (noArticles === 2 && index === 1) return "5";
      return "";
    },
    isEmpty,
  },
});
