
























































import Vue from "vue";
import Calendar from "@/lib/assets/icon/calendar.svg";

export default Vue.extend({
  components: { Calendar },
  props: {
    location: { type: String, required: true },
    longdate: { type: String, required: true },
    shortdate: { type: String, required: true },
    title: { type: String, required: true },
    targetGroups: { type: Array, required: true },
    link: { type: String, required: true },
    lastEvent: {type: Boolean, default: false}
  }
});
