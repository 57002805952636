





















import MySkiSidebar from "@/lib/partials/my-ski/MySkiSidebar.vue";

import Vue from "vue";

export default Vue.extend({
  components: { MySkiSidebar },
  props: {
    sidebarContent: { type: Array, required: true },
  },
  data() {
    return {
      org: null,
      callbackUrl: "",
      showHearingWidget: false,
      showCommitmentWidget: false,
      modalMySKINotLoggedInKeys: {
        modalId: "my-ski-login-modal",
        modalTitle: "Log ind for at se Mit SKI",
        modalText: "Mit SKI er ikke tilgængeligt medmindre du logger ind.",
        modalButtonLabel: "Log ind på Mit SKI",
        modalCancelLabel: "Gå tilbage",
      },
    };
  },
  watch: {
    "$store.state.profile": {
      immediate: true,
      handler(profile) {
        if (!profile) return;
        this.fetchOrganization(profile.organizationId);
      },
    },
  },
  async mounted() {
    this.$on("show-hearing-widget", (e: boolean) => {
      this.showHearingWidget = e;
    });
    this.$on("show-commitment-widget", (e: boolean) => {
      this.showCommitmentWidget = e;
    });
  },
  methods: {
    async fetchOrganization(orgId: string) {
      var organization = await this.$api.organizationID.get(orgId);
      if (organization) {
        this.org = organization;
      }
      this.$root.$data.org = this.org;
    },
  },
});
