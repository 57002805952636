



























import Vue from "vue";

import { groupBy } from "@/lib/utilities";

interface Data {
  groups: { agreement: string; projectGroup: string }[] | null;
}

export default Vue.extend({
  data(): Data {
    return {
      groups: null
    };
  },
  created() {
    const {
      key,
      agreement,
      name,
      title,
      company,
      phone,
      mail
    } = this.$config.organization.projectGroup;
    const _projectGroup = this.$root.$data.org[key];
    if (!_projectGroup) return;

    const projectGroup = _projectGroup.map((pg: any) => ({
      agreement: pg[agreement],
      name: pg[name],
      title: pg[title],
      company: pg[company],
      phone: pg[phone],
      mail: pg[mail]
    }));

    const agreements = groupBy(projectGroup, "agreement");
    this.groups = Object.keys(agreements)
      .sort()
      .map(k => ({
        agreement: k,
        projectGroup: agreements[k]
      }));
  }
});
