<template>
  <div :class="removeBottomMargin ? 'mt-5 mb-n5' : 'my-5'">
    <b-container>
      <b-row class="ski-style">
        <b-col offset-lg="3" lg="6">
          <h4 v-if="headline">
            {{ headline }}
          </h4>
          <slot />
        </b-col>
        <b-col offset-lg="1" lg="2" class="mt-3 mt-lg-0">
          <small v-if="showFactbox">
            <span class="dot-icon"/><strong v-if="factboxHeadline">{{ factboxHeadline }}</strong>
            <slot name="factbox" />
          </small>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import { replaceHexWithNordicLettersAndWhitespace, scrollToAnchorItem } from "@/lib/utilities";
import { resetHashtagValue } from "@/lib/urlUtilities";

export default Vue.extend({
  props: {
    headline: { type: String, default: null },
    removeBottomMargin: { type: Boolean, default: false },
    factboxHeadline: { type: String, default: null },
    showFactbox: { type: Boolean, default: false }
  },
  data() {
    return {
      hashtagFromWindow: replaceHexWithNordicLettersAndWhitespace(window.location.hash),
      hashtagItem: "",
      hashtagFreeTextId: "#" + this.headline.toLowerCase()
    };
  },
  mounted() {
    var h4 = document.getElementsByTagName("h4");
    h4.forEach(element => {
      element.id = element.innerText.toLowerCase();
    });
    
    var h5 = document.getElementsByTagName("h5");
    h5.forEach(element => {
      element.id = element.innerText.toLowerCase();
    });
    
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(this.hashtagFromWindow)
    if (this.hashtagItem === this.hashtagFreeTextId) {
      resetHashtagValue();
      this.scrollToAnchorItem(this.hashtagFreeTextId);
    } else {
      h4.forEach(element => {
        if (element.id === this.hashtagItem.split("#")[1]) {
          resetHashtagValue();
          this.scrollToAnchorItem(this.hashtagItem);
        }
      });
      
      h5.forEach(element => {
        if (element.id === this.hashtagItem.split("#")[1]) {
          resetHashtagValue();
          this.scrollToAnchorItem(this.hashtagItem);
        }
      });
    }
  },
  methods: {
    scrollToAnchorItem(freetextId) {
      // Remove hashtag from prop to get the id of the element
      freetextId = freetextId.split("#")[1];
      scrollToAnchorItem(freetextId, 0, 24);
    }
  }
});
</script>

<style lang="scss">
</style>
