






























































































import Vue from "vue";
import Search from "@/lib/assets/icon/search.svg";
import SkiImage from "@/lib/components/SkiImage.vue";
import ItemCard from "@/lib/components/ItemCard.vue";
import Pagination from "@/lib/partials/shared/Pagination.vue";
import { qsParams, isEmpty, groupBy, generateId } from "@/lib/utilities";
import SkiFilter from "@/lib/components/SkiFilter.vue";
import RichText from "@/lib/components/shared/RichText.vue";
import qs from "qs";

interface QueryData {
  filters: { key: string; value: string }[];
  page: number;
}
interface Data extends QueryData {
  searchQuery: string;
  initialSearchQuery: string;
  pagedItems: any[] | null;
  itemsPerPage: number;
  calculatedTotalPages: number;
  filteredItems: any[];
  calculatedTotalResults: number;
  filterIndex: string;
}
export default Vue.extend({
  components: { SkiImage, ItemCard, Pagination, Search, SkiFilter, RichText },
  props: {
    searchTitle: { type: String, required: true },
    resultsTitle: { type: String, required: true },
    searchItems: { type: Array, default: null },
    currentPage: { type: Number, required: true },
    totalPages: { type: Number, required: true },
    totalResults: { type: Number, required: true },
    filterVariant: { type: String, default: "light" },
    tableVariant: { type: String, default: "white" },
    hasSearchInput: { type: Boolean, default: false },
    filterOptions: { type: Array, default: null },
    searchFilters: { type: Array, default: null },
    maxResultsPerPage: { type: Number, default: null },
  },
  data(): Data {
    const { query } = qsParams();
    return {
      initialSearchQuery: query,
      searchQuery: query || "",
      filters: [],
      pagedItems: [],
      itemsPerPage: this.maxResultsPerPage,
      calculatedTotalPages: this.totalPages,
      filteredItems: this.searchItems,
      page: this.currentPage,
      calculatedTotalResults: this.totalResults,
      filterIndex: generateId(),
    };
  },
  created() {
    if (!isEmpty(qsParams())) {
      const { filters } = qsParams();

      if (filters && Array.isArray(filters.split(","))) {
        this.filters = filters
          .split(",")
          .filter((i: any) => !!i)
          .map((i: any) => {
            const idx = i.indexOf(".");
            return {
              key: i.substring(0, idx) || "",
              value: i.substring(idx + 1) || "",
            };
          });
        this.setFilter(this.filters);
      }
      this.paginate();
    }
  },
  methods: {
    isEmpty,
    onSearch(evt: Event) {
      evt.preventDefault();
      window.location.href = "?query=" + this.searchQuery;
    },
    changeFilter(filters: Data["filters"]) {
      if (this.page !== 1) {
        this.updateQueryParams({ page: 1, filters: filters });
        window.location.href = window.location.search;
      } else {
        this.setFilter(filters);
        this.updateQueryParams({ page: this.page, filters: filters });
      }
    },
    setFilter(filters: Data["filters"]) {
      this.filters = filters;
      var filteredItemsData = this.searchItems.filter((item) =>
        this.onFilter(item, filters)
      );
      this.filteredItems = filteredItemsData;
      this.calculatedTotalResults = this.filteredItems.length;
      this.paginate();
    },
    onFilter(item: any, filters: Data["filters"]): boolean {
      const filterGroup = groupBy(filters, "key");
      return Object.keys(filterGroup).every((key) => {
        const groupFilters = filterGroup[key];

        return groupFilters.some((filter: any) => {
          let content = item[filter.key];
          if (content == null) return false;

          if (Array.isArray(content)) {
            if (content.length > 0 && typeof content[0] === "object") {
              content = content.map((i) => i.name || i.value);
            }
            return content.includes(filter.value);
          } else {
            return content === filter.value;
          }
        });
      });
    },
    paginate() {
      if (this.filteredItems !== null) {
        this.calculatedTotalPages = Math.ceil(
          this.filteredItems.length / this.itemsPerPage
        );
        this.pagedItems = this.filteredItems.slice(
          (this.page - 1) * this.itemsPerPage,
          this.itemsPerPage * (this.page - 1) + this.itemsPerPage
        );
      }
    },
    updateQueryParams({ page, filters: _filters }: QueryData) {
      let filters;
      if (!isEmpty(_filters)) {
        filters = Object.keys(_filters).map(
          (i: any) => _filters[i].key + "." + _filters[i].value
        );
      }
      const qsString = qs.stringify(
        { ...qsParams(), filters, page },
        { addQueryPrefix: true, arrayFormat: "comma" }
      );
      window.history.pushState(null, "", qsString);
    },
    sanitizeSearchBody(searchResult: any): string {
      if (searchResult.type === "Organisation") {
        const searchresults = searchResult.body.split("SKI-kundenr. / EAN:")[0]
          ? searchResult.body.split("SKI-kundenr. / EAN:")[0].trim()
          : searchResult.body;
        return searchresults.replace("CVR:", "CVR-/VAT-nummer:");
      } else {
        return searchResult.body.replace(
          "SKI-kundenr. / EAN:",
          "SKI-kundenr./EAN:"
        );
      }
    },
  },
});
