












































































































































































































import dayjs from "dayjs";
import Vue from "vue";

import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";
import Bulb from "@/lib/assets/icon/bulb.svg";
import PrintButton from "@/lib/components/PrintButton.vue";
import SkiLink from "@/lib/components/SkiLink.vue";
import TrafficLight from "@/lib/components/TrafficLight.vue";
import RichText from "@/lib/components/shared/RichText.vue";

import {
  AgreementContainsTrafficlight,
  isTender,
} from "@/lib/agreementUtilities";
import { AgreementDescriptionShortcutsDto } from "@/lib/api/APIService";
import Info from "@/lib/assets/mediumicon/info.svg";
import DescriptionShortcut from "@/lib/components/DescriptionShortcut.vue";
import { formatDate } from "@/lib/dateUtilites";

interface ShortcutType {
  descriptionName?: string;
  showShortcut?: boolean;
}

export default Vue.extend({
  components: {
    ArrowLeft,
    Bulb,
    SkiLink,
    TrafficLight,
    RichText,
    PrintButton,
    Info,
    DescriptionShortcut,
  },
  props: {
    badgeTitle: { type: String, required: true },
    badgeTenderTitle: { type: String, required: true },
    badgeExpiredTitle: { type: String, required: true },
    expireDateLabel: { type: String, required: true },
    expireDateLabelExpired: { type: String, required: true },
    trafficlightExplainerText: { type: String, default: "" },
    trafficlightExplainerUrl: { type: String, default: "" },
    trafficlightExplainerTarget: { type: String, default: "" },
    sustinabilityShortcutText: { type: String, default: "" },
    greenadviceShortcutText: { type: String, default: "" },
  },
  data() {
    return {
      id: "",
      item: this.$root.$data.item,
      agreementType: this.$root.$data.agreementType,
      application: {},
      isExpired: false,
      popoverShow: false,
      renderTrafficLight: false,
      sideMenuId:
        this.$root.$data.item.mainAgreementId === null
          ? "margin-left-menu"
          : "margin-left-menu-sub",
    };
  },
  computed: {
    descriptionShortcuts(): AgreementDescriptionShortcutsDto[] {
      return this.item.descriptionShortcuts;
    },
    sustainabilityShortcut(): ShortcutType {
      var shortcutsFromApi = this.descriptionShortcuts.find(
        (x) => x.descriptionName === "bæredygtighed"
      );

      var shortcut: ShortcutType = {};
      if (shortcutsFromApi !== undefined) {
        shortcut.descriptionName = shortcutsFromApi.descriptionName;
        shortcut.showShortcut = shortcutsFromApi.showShortcut;
      } else {
        shortcut.descriptionName = "bæredygtighed";
        shortcut.showShortcut = false;
      }
      return shortcut;
    },
    greenadviceShortcut(): ShortcutType {
      var shortcutsFromApi = this.descriptionShortcuts.find(
        (x) => x.descriptionName === "gode råd om grønne indkøb"
      );

      var shortcut: ShortcutType = {};
      if (shortcutsFromApi !== undefined) {
        shortcut.descriptionName = shortcutsFromApi.descriptionName;
        shortcut.showShortcut = shortcutsFromApi.showShortcut;
      } else {
        shortcut.descriptionName = "gode råd om grønne indkøb";
        shortcut.showShortcut = false;
      }
      return shortcut;
    },
    teaserText() {
      const { descriptions } = this.$config.agreement;
      const result = this.$root.$data.itemDescriptions;
      if (!result) return null;

      const teaser = result.find(
        (item: any) => item[descriptions.title] === descriptions.teaser.key
      );
      if (teaser) return teaser[descriptions.content];

      return null;
    },
  },
  async created() {
    const { expirationDateCest, application } = this.$config.agreement;

    this.application = this.item[application.activeApplication];
    const date = this.item[expirationDateCest];
    this.isExpired = dayjs(date).isBefore(dayjs());

    this.renderTrafficLight = AgreementContainsTrafficlight(this.item);
  },
  methods: {
    formatDate,
    getMainAgreementUrl(id: string) {
      return `${window.location.pathname}?id=${id}`;
    },
    onClose() {
      this.popoverShow = false;
    },
    setBadgeTitle(): string {
      switch (this.item.overallStatusName) {
        case "Aktiv":
          return this.badgeTitle;
        case "Kommende":
          return this.badgeTenderTitle;
        case "Udløbet":
          return this.badgeExpiredTitle;
        default:
          return "";
      }
    },
    setBadgeVariant(): string {
      switch (this.item.overallStatusName) {
        case "Aktiv":
          return "aftale";
        case "Kommende":
          return "udbud";
        case "Udløbet":
          return "danger";
        default:
          return "";
      }
    },
    intoForceDate(): string {
      const { date, altDate } = this.$config.agreement.intoForceDate;
      return this.item[altDate] || formatDate(this.item[date]);
    },
    isTender,
  },
});
