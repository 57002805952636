import { render, staticRenderFns } from "./TrafficLight.vue?vue&type=template&id=7d07da77"
import script from "./TrafficLight.vue?vue&type=script&lang=ts"
export * from "./TrafficLight.vue?vue&type=script&lang=ts"
import style0 from "./TrafficLight.vue?vue&type=style&index=0&id=7d07da77&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports