




import Vue from "vue";
import dompurify from "dompurify";
import { addMonsidoTrackingToElement, getDocumentLinksFromRef } from "@/lib/utilities/monsidoUtilities";

export default Vue.extend({
  props: {
    html: { type: String, required: true },
  },
  computed: {
    sanitizeHtml(): string {
      return dompurify.sanitize(this.html, {
        ADD_TAGS: ["iframe"],
      });
    },
  },
  mounted() {
    const div = this.$refs.htmlContainer as HTMLAnchorElement;
    const docLinks = getDocumentLinksFromRef(div);

    // Add the Monsido tracking event on clicks for each unique link to Umbraco documents, if a link has not been added
    docLinks.forEach((link) => {
      if (link !== undefined) {
        addMonsidoTrackingToElement(
          link,
          "Download",
          "Download af Umbraco-dokument",
          `Download af dokument: ${link.title !== null ? link.title : link.text}`
        );
      }
    });
  },
});
