




































import Vue from "vue";
import SkiAspect from "@/lib/components/SkiAspect.vue";
import ItemCard from "@/lib/components/ItemCard.vue";
import Pagination from "@/lib/partials/shared/Pagination.vue";
import { isEmpty } from "@/lib/utilities";

export default Vue.extend({
  components: { SkiAspect, ItemCard, Pagination },
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    newsItems: { type: Array, required: true },
    currentPage: { type: Number, required: true },
    totalPages: { type: Number, required: true }
  },
  methods: { isEmpty }
});
