











import Vue from "vue";

export default Vue.extend({
  components: {
    MySkiRowHeader: () => import("@/lib/partials/my-ski/MySkiRowHeader.vue"),
  },
});
