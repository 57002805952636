








































import Vue from "vue";
import SkiAspect from "@/lib/components/SkiAspect.vue";
import { formatDate } from "@/lib/dateUtilites";

export default Vue.extend({
  components: { SkiAspect },
  props: {
    title: { type: String, required: true },
    date: { type: String, required: true },
    linkUrl: { type: String, required: true },
    linkLabel: { type: String, required: true }
  },
  methods: {
    formatDate
  }
});
