


















import Vue from "vue";
import {
  getDescription,
  replaceHexWithNordicLettersAndWhitespace,
  scrollToItemTop,
} from "@/lib/utilities";
import RichText from "@/lib/components/shared/RichText.vue";
import { resetHashtagValue } from "@/lib/urlUtilities";

interface Data {
  hashtagFromWindow: string;
  hashtagItem: string;
  hashtagApplicationId: string;
  isLoggedIn: boolean;
}
export default Vue.extend({
  components: { RichText },
  props: {
    applicationTitle: { type: String, required: true },
    applicationDescription: { type: String, required: true },
  },
  data(): Data {
    return {
      hashtagFromWindow: window.location.hash,
      hashtagItem: "",
      hashtagApplicationId: "#application",
      isLoggedIn: false,
    };
  },
  computed: {
    applicationHtmlContent(): string {
      const { application } = this.$config.agreement.descriptions;
      return getDescription(this, application.key);
    },
  },
  async created() {
    this.isLoggedIn = this.$store.getters.isLoggedIn;
  },
  mounted() {
    this.hashtagItem = replaceHexWithNordicLettersAndWhitespace(
      this.hashtagFromWindow
    );
    if (this.hashtagItem === this.hashtagApplicationId) {
      resetHashtagValue();
      this.scrollToAnchorItem();
    }
  },
  methods: {
    scrollToAnchorItem() {
      // Remove hashtag from prop to get the id of the element
      var applicationId = this.hashtagApplicationId.split("#")[1];
      var element = document.getElementById(applicationId);
      if (element) {
        scrollToItemTop(element);
      }
    },
  },
});
