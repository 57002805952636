














































import Profile from "@/lib/assets/icon/profile.svg";
import SkiDefaultButton from "@/lib/components/SkiDefaultButton.vue";
import Vue from "vue";

export default Vue.extend({
  name: "Topbar",
  components: {
    Profile,
    SkiDefaultButton,
  },
  props: {
    variant: { type: String, required: true },
    mySkiLabel: { type: String, required: true },
    mySkiUrl: { type: String, required: true },
  },
  data: function () {
    return {
      currentRoute: window.location.href,
    };
  },
  computed: {
    username(): string {
      return this.$store.state.profile.name;
    },
    logoutRoute(): string {
      if (this.currentRoute.includes(this.mySkiUrl)) {
        return window.location.origin + "/";
      } else if (this.currentRoute.includes("documentId")) {
        const url = new URL(this.currentRoute);
        url.searchParams.delete("documentId");
        return url.toString();
      } else {
        return this.currentRoute;
      }
    },
  },
  mounted: function () {
    this.underLineMySkiWhileVisited();
  },
  methods: {
    login(redirectPath?: string): void {
      this.$store.dispatch("signIn", redirectPath || window.location.href);
    },
    logout(): void {
      this.$store.dispatch("signOut", window.location.href);
    },
    underLineMySkiWhileVisited: function () {
      const mySkiLink = document.getElementsByClassName("my-ski-link");
      if (mySkiLink != null) {
        if (this.currentRoute.includes(this.mySkiUrl)) {
          for (let i = 0; i < mySkiLink.length; i++) {
            mySkiLink[i]
              .getElementsByTagName("span")[0]
              .classList.add("active-link");
          }
        } else {
          for (let i = 0; i < mySkiLink.length; i++) {
            mySkiLink[i]
              .getElementsByTagName("span")[0]
              .classList.remove("active-link");
          }
        }
      }
    },
  },
});
