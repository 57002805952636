





























import Download from "@/lib/assets/icon/download.svg";
import Lock from "@/lib/assets/icon/lock.svg";
import { alertMessage } from "@/lib/utilities";
import Vue from "vue";
import { AgreementDocumentsListViewDto } from "../api/APIService";

export default Vue.extend({
  components: { Download, Lock },
  props: {
    doc: { type: AgreementDocumentsListViewDto || null, default: null },
    accordionName: { type: String, default: null },
    fileType: { type: String, default: null },
    fileName: { type: String, default: null },
    label: { type: String, default: null },
    monsidoComponent: { type: Boolean, default: false },
    umbracoDocument: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    documentAuthorized(
      doc: AgreementDocumentsListViewDto | null
    ): boolean | undefined {
      return doc?.userAccess?.authorized;
    },
    handleClickEvent(
      monsidoComponent: boolean,
      fileName: string,
      doc: AgreementDocumentsListViewDto | null = null
    ): void {
      this.loading = true;
      if (doc === null || doc === undefined) {
        this.loading = false;
        return;
      } else if (doc.requiresLogin && !this.$store.getters.isLoggedIn) {
        this.$store.dispatch(
          "signIn",
          `${window.location.href}&documentId=${doc?.documentId}`
        );
      } else if (monsidoComponent && !doc.requiresLogin) {
        this.monsidoClick(doc, fileName);
      } else {
        this.docClick(doc);
      }
    },
    monsidoClick(doc: AgreementDocumentsListViewDto, fileName: string) {
      const monsidoDocumentComponent = document.getElementById(
        `monsidoDocumentId-${doc ? doc.documentId : fileName}`
      );
      if (monsidoDocumentComponent) {
        monsidoDocumentComponent.click();
        this.loading = false;
      }
    },
    docClick(document: AgreementDocumentsListViewDto) {
      if (document.documentId && !this.umbracoDocument) {
        const fileName =
          document.fileName !== undefined ? document.fileName : "file";
        this.downloadDocument(document.documentId, fileName);
      }
    },
    async downloadDocument(
      documentId: string,
      fileName: string
    ): Promise<void> {
      this.loading = true;

      try {
        const response = await this.$api.DocumentRepository.getDocument(
          documentId
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      } catch (e: any) {
        alertMessage(this, "Der skete en fejl ved hentning af dokumentet.");
      } finally {
        this.loading = false;
      }
    },
  },
});
