import { isDateAfterToday } from "@/lib/dateUtilites";
import { OrgAgreementItem } from "@/lib/models/agreementItem.model";
import { GetValueByPropertyPath } from "@/lib/objectUtilities";

export function GetAgreementsAfterDate (agreements: OrgAgreementItem[], dateFilter: string): OrgAgreementItem[] {
  return agreements.filter((agreement) => {
    const dateValue = new Date(GetValueByPropertyPath(agreement, dateFilter));
    return dateValue instanceof Date && !isNaN(Date.parse(dateValue.toDateString())) ? isDateAfterToday(dateValue) : agreements;
  });
}
