







































































import Vue, { PropType } from "vue";
import Logout from "@/lib/assets/icon/logout.svg";
import { hotScrollToItemTop, isEmpty } from "@/lib/utilities";

interface Item {
  url: string;
  name: string;
  target: string;
  allowedRoles: string[];
  items?: Item[] | null;
}

interface Data {
  activeUrl: string;
  anchorNames: string[];
  divElems: ElementPair[];
  roles: string | string[];
}

interface ElementPair {
  anchorElement: Element | null;
  divElement: Element | null | undefined;
  overlap: number;
  headlineOverlap: number;
}

function getUrls({ items }: Item): string[] {
  if (!items) return [];

  return items.reduce((res: string[], item) => {
    res.push(item.url); // Push head item

    return res.concat(getUrls(item)); // Concat child items
  }, []);
}

export default Vue.extend({
  components: { Logout },
  props: {
    trigger: { type: Boolean, required: true },
    sidebar: { type: Array as PropType<Item[]>, default: null },
    showHearingWidget: { type: Boolean, default: false },
    showCommitmentWidget: { type: Boolean, default: false },
  },
  data(): Data {
    return {
      activeUrl: "",
      anchorNames: [],
      divElems: [],
      roles: [],
    };
  },
  watch: {
    // VueJS watch on vue-router path (only needed for Vue development)
    "$route.path"(newPath) {
      this.setActivePath(newPath);
    },
    trigger() {
      this.scrollToHash();
      this.getSubItems();
    },
  },
  async created() {
    this.setActivePath(window.location.pathname);
    this.getAnchorNames();

    this.roles = this.$store.getters.roles;
  },
  mounted() {
    document.addEventListener("scroll", this.highlightSubItem);

    this.highlightSubItem();
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.highlightSubItem);
  },
  methods: {
    renameCommitmentTable(name: string) {
      if (name === "Høringer og tilslutninger") {
        return "Tilslut din organisation";
      }
      return name;
    },
    logout(): void {
      this.$store.dispatch("signOut", window.location.href);
    },
    setActivePath(path: string) {
      const urls = getUrls({
        name: "",
        allowedRoles: [],
        url: "",
        target: "",
        items: this.sidebar,
      });
      var url = urls.find((url) => url.includes(path));

      var currentPath = window.location.pathname.toLowerCase();
      if (this.isHotPage(currentPath)) {
        url = "/mit-ski/kunde/horinger-og-tilslutninger/";
        // Update page title
        document.title = "Høringer og tilslutninger - SKI";
      }

      if (url) {
        this.activeUrl = url;
      }
    },
    hotSubPageRedirect(url?: string) {
      var currentUrl = window.location.href.toLowerCase();
      if (url && this.isHotPage(currentUrl)) {
        var hearingUrl = currentUrl.split("/horinger-og-tilslutninger/")[0];
        var targetUrl = hearingUrl + url;
        window.location.assign(targetUrl);
      }
    },
    scrollToHash(url?: string) {
      // Check if we need to redirect away from hearingpage or commitmentPage
      this.hotSubPageRedirect(url);

      const loc = url ? new URL(window.location.origin + url) : window.location;

      if (loc.hash) {
        const el = document.querySelector(`#id-${loc.hash.slice(1)}`);
        hotScrollToItemTop(el);
      }
    },
    hasRole(item: Item): boolean {
      if (isEmpty(item.allowedRoles) || !item.allowedRoles) return true;

      if (this.roles && Array.isArray(this.roles)) {
        return (
          this.roles.filter((role) => item.allowedRoles.includes(role)).length >
          0
        );
      } else if (this.roles && typeof this.roles === "string") {
        return item.allowedRoles.includes(this.roles); // If a user only has a single role, then it is saved as a string in it's id token
      }
      return false;
    },
    isHotElement(dropdownItemName: string) {
      const hotElementNames = [
        "Deltag i høringer",
        "Høringsarkiv",
        "Tilslutningsarkiv",
      ];
      return hotElementNames.includes(dropdownItemName);
    },
    isHotPage(currentPath: string): boolean {
      const pathsToCheck = [
        "hearingpage",
        "commitmentpage",
        "underskriv-tilslutningsaftale",
        "valg-underskriver",
        "underskrevet-tilslutningsaftale",
      ];
      return pathsToCheck.some((path) => currentPath.includes(path));
    },
    getAnchorNames() {
      this.sidebar.forEach((navItem) => {
        if (navItem.items) {
          navItem.items.forEach((subItem) => {
            const anchorName = subItem.url.split("#")[1];
            this.anchorNames.push(anchorName);
          });
        }
      });
    },
    intersection(r1: any, r2: any) {
      // r1:  Placement (x,y) and Dimensions (w, h) of element relative to viewport
      // r2:  Placement (x,y) and Dimensions (w, h) of viewbox (origin in top left corner)

      // This calculates the length of the intersection between the element r1 and the viewbox r2 per coordinate. It returns zero, if there is no overlap
      // r1.x + r1.w: Total distance from left side of viewport to right side of element
      // r2.x + r2.w: Total distance from left side of viewport to right side of viewport
      // Minimum of these two provides left side of intersection
      // max(r1.x, r2.x) gives coordinate of rightmost element
      // The difference gives the length of the intersection along the x-axis
      const xOverlap = Math.max(
        0,
        Math.min(r1.x + r1.w, r2.x + r2.w) - Math.max(r1.x, r2.x)
      );
      const yOverlap = Math.max(
        0,
        Math.min(r1.y + r1.h, r2.y + r2.h) - Math.max(r1.y, r2.y)
      );

      // Calculate the area of the intersection between the element and the viewbox
      const overlapArea = xOverlap * yOverlap;

      return overlapArea;
    },
    isSubItemVisible(element: Element) {
      const rect = element.getBoundingClientRect() as DOMRect; // Get the dimensions and placement of the element relative to the viewbox

      const dimension = { x: rect.x, y: rect.y, w: rect.width, h: rect.height }; // Save relevant parmeters for placemnt and dimensions from the element
      const viewport = {
        x: 0,
        y: 0,
        w: window.innerWidth,
        h: window.innerHeight,
      }; // Save relevant parmeters for placemnt and dimensions from the viewbox
      const divsize = dimension.w * dimension.h; // Area of the element
      const overlap = this.intersection(dimension, viewport); // Area of the intersection between the element and the viewbox

      return overlap / divsize;
    },
    highlightSubItem() {
      this.getSubItems();

      this.divElems.forEach((elem) => {
        if (elem.divElement && elem.anchorElement) {
          const elemHeadline = elem.divElement.querySelector(".text-uppercase");
          elem.headlineOverlap =
            elemHeadline !== null ? this.isSubItemVisible(elemHeadline) : 0;
          elem.overlap = this.isSubItemVisible(elem.divElement);
        }

        const mainElem = this.divElems.reduce((max, elem) =>
          max.headlineOverlap >= elem.headlineOverlap &&
          max.overlap >= elem.overlap
            ? max
            : elem
        );
        if (
          mainElem.anchorElement &&
          (mainElem.overlap > 0 || mainElem.headlineOverlap > 0)
        ) {
          mainElem.anchorElement.classList.add("hover");
        }
        this.divElems.forEach((elem) => {
          if (
            elem.divElement &&
            elem.anchorElement &&
            elem.overlap !== mainElem.overlap
          ) {
            elem.anchorElement.classList.remove("hover");
          }
        });
      });
    },
    getSubItems() {
      const divElems: ElementPair[] = [];
      this.anchorNames.forEach((name) => {
        const anchorElement = document.getElementById(name);
        const divElement = document.querySelector("#id-" + name);
        const elements: ElementPair = {
          anchorElement: anchorElement,
          divElement: divElement !== null ? divElement.parentElement : null,
          overlap: 0,
          headlineOverlap: 0,
        };
        if (elements.anchorElement !== null && elements.divElement != null) {
          divElems.push(elements);
        }
      });

      this.divElems = divElems;
    },
  },
});
