







































































































































import Vue from "vue";
import { animateCSS, isEmpty } from "@/lib/utilities";
import RichText from "@/lib/components/shared/RichText.vue";
import Arrow from "@/lib/assets/icon/arrow.svg";
import ArrowLeft from "@/lib/assets/icon/arrow-left.svg";
import Open from "@/lib/assets/icon/open.svg";
import Closed from "@/lib/assets/icon/minus.svg";
import { MilestoneStatus } from "@/lib/models/enums/milestoneStatus.enum";

interface Milestone {
  id: string;
  title: string;
  content: string;
  date: string;
  status: string;
}

export default Vue.extend({
  components: { RichText, Arrow, ArrowLeft, Open, Closed },
  props: {
    milestones: { type: Array as () => Milestone[], required: true },
    agreementType: { type: String, required: true },
  },
  data() {
    return {
      currentIndex: -1,
      collapsed: true,
      tlHeight: 1000,
    };
  },
  computed: {
    hasPrevious(): boolean {
      return this.currentIndex > 0;
    },
    hasNext(): boolean {
      return this.currentIndex < this.milestones.length - 1;
    },
    scrollSm(): {} {
      return this.scroll(this.currentIndex, 0, 100);
    },
    scrollLg(): {} {
      return this.scroll(this.currentIndex, 1, 25);
    },
    scrollXl(): {} {
      return this.scroll(this.currentIndex, 2, 12.5);
    },
    content(): string {
      const item = this.milestones[this.currentIndex] as Milestone;
      if (!item) return "";
      return item.content;
    },
    tlMinHeight(): string {
      return this.agreementType === "agreement"
        ? "min-height: 8rem;"
        : "min-height: 10rem;";
    },
    timelineClass(): string {
      return this.agreementType === "agreement" ? "py-4" : "py-5";
    },
    nextInLineIndex(): number {
      // If currentIdx is -1 (not found) or it's the last element, there's no next in line.
      if (
        this.currentIndex === -1 ||
        this.currentIndex === this.milestones.length - 1
      ) {
        return -1;
      }
      return this.currentIndex + 1;
    },
  },
  watch: {
    content() {
      this.collapsed = true;
      this.$nextTick(() => {
        const outerElement = this.$el.querySelector(".tl-current");
        const innerElement = this.$el.querySelector(".tl-current-inner");
        const expandElement = this.$el.querySelector(".tl-expand");
        if (!outerElement || !innerElement || !expandElement) return;

        const { clientHeight: outerHeight } = outerElement;
        const { clientHeight: innerHeight } = innerElement;
        this.tlHeight = innerHeight + 200;

        if (innerHeight > outerHeight) {
          expandElement.classList.remove("d-none");
        } else {
          expandElement.classList.add("d-none");
        }
      });
    },
  },
  mounted() {
    // Set currentIndex
    for (let i = 0; i < this.milestones.length + 1; i++) {
      const prev = this.milestones[i - 1] || {
        status: MilestoneStatus.complete,
      };
      const next = this.milestones[i] || { status: MilestoneStatus.upcoming };

      if ((prev as Milestone).status !== (next as Milestone).status) {
        this.currentIndex = i - 1;
      }
    }

    if (!isEmpty(this.milestones)) {
      if (this.currentIndex === -1) {
        this.currentIndex = 0;
      }

      (this.milestones[this.currentIndex] as Milestone).status =
        MilestoneStatus.active;
    }
    this.adjustAllowedTextLines();
  },
  methods: {
    isEmpty,
    previous() {
      this.currentIndex -= 1;
      animateCSS(".tl-animation", "fadeInLeft");

      this.adjustAllowedTextLines();
    },
    next() {
      this.currentIndex += 1;
      animateCSS(".tl-animation", "fadeInRight");

      this.adjustAllowedTextLines();
    },
    getDisplay(index: number) {
      const padding = " pl-0 pr-4 py-3";
      if (index === this.currentIndex) {
        return "d-flex col-lg-6 tl-current" + padding;
      } else {
        return "d-flex d-lg-flex col-lg-tl col-xl-tl" + padding;
      }
    },
    scroll(index: number, currentIndex: number, size: number) {
      const marginLeft = `-${100 + (index - currentIndex) * size}%`;
      return { marginLeft, transition: "margin-left 1s" };
    },
    getClassForMilestone(milestone: Milestone, index: number) {
      let classes = `${this.getDisplay(index)} tl-head tl-${milestone.status}`;
      if (index === this.nextInLineIndex) {
        classes += " tl-next"; // Do not remove the space
      }
      return classes;
    },
    shouldDisplaySquare(index: number): boolean {
      return this.currentIndex === index;
    },
    adjustAllowedTextLines() {
      this.$nextTick(() => {
        this.$el
          .querySelectorAll(".tl-head.tl-current, .tl-head.tl-next")
          .forEach((headElement) => {
            const headingElement = headElement.querySelector("h5");
            const smallElement = headElement.querySelector("small");

            if (headingElement && smallElement) {
              const computedStyle = window.getComputedStyle(headingElement);
              const lineHeight = parseInt(computedStyle.lineHeight as string);

              const textHeight = headingElement.offsetHeight;

              smallElement.classList.remove("one-line", "two-lines");
              const lineClampValue =
                textHeight >= 2 * lineHeight ? "one-line" : "two-lines";
              smallElement.classList.add(lineClampValue);
            }
          });
      });
    },
  },
});
