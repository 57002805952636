


























import Vue from "vue";
import MySkiSubscriptionModal from "@/lib/partials/my-ski/modules/MySkiStatusUpdate/MySkiSubscriptionModal.vue";
import { alertMessage } from "@/lib/utilities";
import { StatusUpdateService } from "@/lib/businessLogic/services/StatusUpdateService";
import {
  STATUS_UPDATE_FILTER_OPTIONS,
  STATUS_UPDATE_SORT_OPTIONS,
  AGREEMENT_TYPES,
} from "@/lib/constants/statusUpdateConstants";
import {
  SourcingprogramListViewDto,
  GroupListViewDto,
} from "@/lib/api/APIService";
import {
  PollingEvents,
  StatusUpdatePollingService,
} from "@/lib/businessLogic/services/StatusUpdatePollingService";

enum status {
  LOADING = "loading",
  ERROR = "error",
  READY = "ready",
}

const WRONG_LINK_ERROR =
  "Der er noget galt med dit link. Kontakt venligst SKI's kundeservice på kundeservice@ski.dk.";
const GENERAL_ERROR_MESSAGE =
  "Noget gik galt. Prøv igen senere. Eller kontakt venligst SKI's kundeservice på kundeservice@ski.dk, hvis problemet fortsætter.";
const ENTITY_NOT_FOUND_ERROR =
  "Dine kontaktoplysninger blev ikke fundet. Det kan skyldes, at din e-mail er blevet deaktiveret i vores system. Kontakt SKI's kundeservice på kundeservice@ski.dk for at få hjælp.";
const UPDATE_ERROR =
  "Noget gik galt. Prøv igen senere. Eller kontakt venligst SKI's kundeservice på kundeservice@ski.dk, hvis problemet fortsætter.";

interface Data {
  contactGuid: string;
  subscribedAgreementIds: string[];
  status: status;
  filterData: {
    agreementTypes: typeof AGREEMENT_TYPES;
    skiSourcingprograms?: SourcingprogramListViewDto[];
    skiGroups?: GroupListViewDto[];
  };
  pollingService: StatusUpdatePollingService;
  statusUpdateService: StatusUpdateService;
  closeOnClickOutside: boolean;
}

export default Vue.extend({
  components: { MySkiSubscriptionModal },
  props: {
    modalTitle: { type: String, required: true },
    modalText: { type: String },
    modalTitleChanged: { type: String, required: true },
    modalTextChanged: { type: String },
    modalSubmitButtonText: { type: String, required: true },
    modalRemoveAllSubsText: { type: String, required: true },
  },
  data(): Data {
    return {
      contactGuid: "",
      subscribedAgreementIds: [],
      status: status.LOADING,
      filterData: {
        agreementTypes: AGREEMENT_TYPES,
        skiSourcingprograms: [],
        skiGroups: [],
      },
      pollingService: new StatusUpdatePollingService(this.$api, this.$root),
      statusUpdateService: new StatusUpdateService(this.$api, this.$root),
      // This page is only opened from email links and should redirect to home page when closed.
      // Thus this is set to false to prevent accidental closing of the modal.
      closeOnClickOutside: false,
    };
  },
  computed: {
    filterOptions() {
      return STATUS_UPDATE_FILTER_OPTIONS;
    },
    sortOptions() {
      return STATUS_UPDATE_SORT_OPTIONS;
    },
    isLoading(): boolean {
      return this.status === status.LOADING;
    },
    isReady(): boolean {
      return this.status === status.READY;
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const validatedGuid = this.validateAndExtractContactGuid(urlParams);

    if (validatedGuid) {
      this.contactGuid = validatedGuid;
      this.checkForExistingUpdate();

      this.$root.$on(PollingEvents.Complete, this.handlePollingComplete);
      this.$root.$on(PollingEvents.Error, () => {
        alertMessage(this, UPDATE_ERROR);
      });
    } else {
      alertMessage(this, WRONG_LINK_ERROR);
    }

    this.$root.$on("bv::modal::hide", this.redirectToHome);
  },
  beforeDestroy() {
    this.$root.$off("bv::modal::hide", this.redirectToHome);
    this.$root.$off(PollingEvents.Complete, this.handlePollingComplete);
    this.$root.$off(PollingEvents.Error, () => {
      alertMessage(this, UPDATE_ERROR);
    });
  },
  watch: {
    status(newStatus) {
      if (newStatus === status.READY) {
        this.$nextTick(() => {
          this.$bvModal.show("mySkiModal");
        });
      }
    },
  },
  methods: {
    async fetchAPIData() {
      this.status = status.LOADING;
      try {
        const result = await this.statusUpdateService.fetchStatusUpdates(
          this.contactGuid
        );

        if (!result) {
          throw new Error(GENERAL_ERROR_MESSAGE);
        }

        this.filterData.skiGroups = result.filterData.skiGroups;
        this.filterData.skiSourcingprograms =
          result.filterData.skiSourcingprograms;
        this.subscribedAgreementIds = result.subscribedAgreementList.map(
          (agreement) => agreement.agreementId
        ) as string[];
        this.status = status.READY;
      } catch (error: any) {
        // If a user has been disabled in Stamdata CRM, but then clicks a old email, then we will get a 400 error.
        if (error?.status === 400) {
          alertMessage(this, ENTITY_NOT_FOUND_ERROR, "Brugerprofil inaktiv");
        } else {
          alertMessage(this, GENERAL_ERROR_MESSAGE);
        }
      }
    },
    // Validates and extracts contact GUID from URL.
    // Note: URL validation is needed as users can modify the template in CRM and break the URL format if they dont reference the contact correctly.
    // Note: Guids from Dynamics CRM are sent without hyphens and with a & at the end of the guid.
    validateAndExtractContactGuid(urlParams: URLSearchParams): string {
      const id = urlParams.get("id");
      if (!id) {
        return "";
      }

      const guidPart = id.split("&")[0];
      
      // Check for 32-character hexadecimal format
      const nonHyphenatedRegex = /^[0-9a-f]{32}$/i;
      if (nonHyphenatedRegex.test(guidPart)) {
        // Insert hyphens at correct positions
        return `${guidPart.slice(0,8)}-${guidPart.slice(8,12)}-${guidPart.slice(12,16)}-${guidPart.slice(16,20)}-${guidPart.slice(20)}`;
      }

      return "";
    },
    async checkForExistingUpdate() {
      this.status = status.LOADING;
      const hasPendingUpdate = await this.pollingService.waitForPendingUpdate();
      if (!hasPendingUpdate) {
        await this.fetchAPIData();
      }
    },
    redirectToHome() {
      window.location.href = "/";
    },
    // Ensures data is only fetched during initial load while waiting for Dynamics CRM updates.
    // Two scenarios where this is important:
    // 1. User updates their subscriptions but forgets one, then quickly reopens the modal.
    //    We need to wait for the previous update to finish in Dynamics CRM before showing new data.
    // 2. User saves changes but stays on the screen without closing the modal.
    //    We don't want to trigger a second update as it would reset their state and reopen the modal.
    async handlePollingComplete() {
      if (this.status === status.LOADING) {
        await this.fetchAPIData();
      }
    },
  },
});
